var _ = require('underscore/underscore');
var SubSection = require('front/pages/Section/SubSection');
var Backbone = require('backbone');

var Urls = require('django_js_front/reverse.js');

require('./JoinPopup.less');


module.exports = SubSection.extend({
    el: '.JoinPopup',

    template: require('./JoinPopup.jinja'),

    events: {
        'click .JoinPopup-close': 'close',
        'click .JoinPopup-bg': 'close',
        'mouseenter .JoinPopup-headerImagesImg': 'hoverCardStat',
        'click #shareLink': 'activateShareDropdown',
        'click .JoinPopup-shareLink#share-twitter': 'shareTwitter',
        'click .JoinPopup-shareLink#share-slack': 'shareSlack',
        'click .JoinPopup-shareLink#share-facebook': 'shareFacebook',
        'click .JoinPopup-shareLink#share-mail': 'shareMail',
        'click .JoinPopup-shareLink#share-linkedin': 'shareLinkedIn'
    },

    apiUrl: function () {
        return Urls['front:api:join-popup'](this.slug);
    },

    initialize: function (options) {
        this.options = options || {};

        SubSection.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic', 'triggerPopupShown', 'triggerPopupHidded', 'activateShareDropdown',
            'deactivateShareDropdown', 'shareTwitter', 'shareSlack', 'shareFacebook', 'shareMail', 'shareLinkedIn');

        this.slug = this.options.slug;

        if (this.options.server) {
            this.activateLogic(true);

            this.data = {}; //prevent api call for data when opens from direct url
        }

        this.popupTitle = window.app.data.joinPopupTitle;

        $(document)
            .on('click', this.deactivateShareDropdown);
    },

    renderData: function () {
        this.popupTitle = this.data.title;
        this.setTitle(': ' + this.popupTitle, true);

        return {
            popup: this.data || {}
        };
    },

    activateLogic: function (isServer) {
        this.$el.hide();
        this.$el.addClass('JoinPopup--visible');
        this.$el.show();
        this.$el.outerHeight();
        this.$('.JoinPopup-content')
            .scrollTop(0);
        this.$('.JoinPopup-content')
            .focus(); //for pgdn pgup shortcuts
        var self = this;
        if (isServer) {
            setTimeout(function () {
                self.$el.find('.JoinPopup-wrapper')
                    .removeClass('overflowLock');
                self.triggerPopupShown();
            }.bind(this), this.TIME_ANIMATION);
        } else {
            this.$el.find('.JoinPopup-wrapper')
                .removeClass('overflowLock');
            this.triggerPopupShown();
        }
        window.app.views.extraMenu.initExtraMenu(this.$el);
    },

    hoverCardStat: function (e) {
        var $item = $(e.currentTarget);
        this.$el.find('.JoinPopup-headerImagesImg.isActive')
            .removeClass('isActive');
        $item.addClass('isActive');
    },

    triggerPopupShown: function () {
        window.app.views.extraMenu.fixMenu();
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger('PopupShown');
        // window.app.views.nav.toggleClose(this.rootUrl);
        $(window)
            .scrollTop(0);
    },

    triggerPopupHidded: function () {
        window.app.views.extraMenu.unFixMenu();
        app.vent.trigger('PopupHidded');
        $(window)
            .scrollTop(this.savedScroll);
    },

    close: function () {
        // var backUrl = this.backUrl || this.rootUrl;
        // Backbone.history.navigate(backUrl, {trigger: 'true'});

        this.setTitle(this.titleBefore);

        app.router.navigateBack();
    },

    // render: function () {
    //     SubSection.prototype.render.call(this);
    //
    //     if (this.options.server) this.activateLogic();
    // },

    activate: function (params) {

        return SubSection.prototype.activate.call(this, params)
            .then(function () {
                this.$content = this.$('.JoinPopup-content');
                this.$content.off('scroll', this.onScroll);
                this.$content.on('scroll', this.onScroll);
            }.bind(this));
    },

    playIn: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            self.$el.hide();
            self.$el.show();
            self.$el.addClass('JoinPopup--visible');
            self.$el.outerHeight();
            // setTimeout(function () {
            // self.$el.find('.JoinPopup-wrapper').removeClass('overflowLock');
            self.triggerPopupShown();
            // }, self.TIME_ANIMATION);
            resolve();
        });
    },

    playOut: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            // self.$el.find('.JoinPopup-wrapper').addClass('overflowLock');
            self.$el.removeClass('JoinPopup--visible');
            // window.app.views.nav.toggleClose(this.rootUrl);
            setTimeout(function () {
                self.triggerPopupHidded();
                resolve();
            }, self.TIME_ANIMATION);
        });
    },

    destroy: function () {
        return SubSection.prototype.destroy.call(this);
    },

    deactivate: function (params) {

        this.triggerPopupHidded();

        return SubSection.prototype.deactivate.call(this, params);
    },

    activateShareDropdown: function (e) {
        e.stopPropagation();
        if (this.$('.JoinPopup-shareDropdown')
            .hasClass('active')) {
            this.$('.JoinPopup-shareDropdown')
                .removeClass('active');
        } else {
            this.$('.JoinPopup-shareDropdown')
                .addClass('active');
        }
    },

    deactivateShareDropdown: function (e) {
        setTimeout(function () {
            var $dropdown = this.$('.JoinPopup-shareDropdown');
            if ($dropdown.hasClass('active') && $(e.target)
                .closest($dropdown)) {
                this.$('.JoinPopup-shareDropdown')
                    .removeClass('active');
            }

        });
    },

    shareTwitter: function () {
        var link = window.location.href;

        app.shareTwitter({link: link, title: this.popupTitle});
    },

    shareFacebook: function () {
        var link = window.location.href;

        app.shareFacebook({link: link, title: this.popupTitle});
    },

    shareMail: function () {
        var link = window.location.href;

        app.shareMail({link: link, title: this.popupTitle});
    },

    shareSlack: function () {
        window.location = '/slack/select_channel/?state=' + encodeURIComponent(window.location.href);
    },

    shareLinkedIn: function () {
        var link = window.location.href;

        app.shareLinked({link: link, title: this.popupTitle});
    }

});
