var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./OurServices.less');

module.exports = Base.extend({
    template: require('./OurServices.jinja'),

    el: '.OurServices',

    initialize: function (options) {

        Base.prototype.initialize.call(this, options);
    }
});
