var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./NewsItem.less');


module.exports = Base.extend({
    template: require('./NewsItem.jinja'),

    el: '.NewsItem',

    initialize: function (options) {

        Base.prototype.initialize.call(this, options);

        _.bindAll(this, 'setLengthMonth');

        this.setLengthMonth();
    },

    setLengthMonth: function () {
        if (!window.app.settings.isMobile) return;

        this.$('.NewsItem-dateMonth').each(function (id, item) {
            var text = $(item).text();

            $(item).text(text.slice(0,3));
        });
    }
});
