var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var Section = require('front/pages/Section/Section');

var JoinUs = require('front/components/JoinUs/JoinUs');
var TeamMembers = require('front/components/TeamMembers/TeamMembers');

require('./TeamSection.less');


module.exports = Section.extend({
    template: require('./TeamSection.jinja'),

    el: '.TeamSection',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'JoinUs': {selector: '.JoinUs', viewConstructor: JoinUs},
        'TeamMembers': {selector: '.TeamMembers', viewConstructor: TeamMembers}
    },

    // apiUrl: Urls['front:api:team'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic');

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:team']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        var location = window.location.hash;

        if (location) {
            location = location.slice(1)
                .slice('', -1);
            setTimeout(function () {
                app.scrollTo(location);
            }, this.TIME_ANIMATION);
        }
        window.app.views.extraMenu.initExtraMenu(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        this.setClass('TeamPage TruePage');
        this.setTitle('true • team');

        // set data to window.app

        window.app.data.teamFilterValues.team = 'true';
        window.app.data.teamFilterValues.isCustomTeam = false;

        window.app.data.membersList = this.data.membersList;
        window.app.data.teamsList = this.data.teamsList;
        window.app.data.regionsList = this.data.regionsList;
        window.app.data.positionsList = this.data.positionsList;

        Section.prototype.render.call(this);
        this.activateLogic();
    }
});

