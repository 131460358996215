var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var Section = require('front/pages/Section/Section');

var CommonBlock = require('front/components/CommonBlock/CommonBlock');

require('./SearchOfferSection.less');


module.exports = Section.extend({
    template: require('./SearchOfferSection.jinja'),

    el: '.SearchOfferSection',

    events: {
        'click .SearchOfferSection-headerTitlePrint': 'onPrintClick',
        'click .SearchOfferPassword-infoBtn': 'onClickSubmit'
    },

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
    //     'CommonBlock': {selector: '.CommonBlock', viewConstructor: CommonBlock}
    },

    // apiUrl: Urls['front:api:search-offer'](),

    initialize: function (options) {
        this.options = options || {};

        _.bindAll(this, 'onPrintClick');

        Section.prototype.initialize.call(this, options);

        this.slug = options.viewData.slug;

        var $form = this.$('form');
        var self = this;

        if ($form.length > 0) {
            $form
                .closest('form')
                .submit(function (e) {
                    e.preventDefault();

                    self.$('.SearchOfferPassword-wrapper')
                        .removeClass('invalid');
                    self.$('.SearchOfferPassword-infoBtn')
                        .addClass('disabled');

                    var password = self.$('input[name=password]')
                        .val();
                    var data = {
                        password: password
                    };
                    $.ajax({
                        type: 'POST',
                        url: Urls['front:api:search-offer'](self.slug),
                        dataType: 'json',
                        contentType: 'application/json',
                        data: JSON.stringify(data),
                        success: function (data) {
                            self.$('.SearchOfferPassword-infoBtn')
                                .removeClass('disabled');
                            window.location.reload();
                        },
                        error: function (data) {
                            self.$('.SearchOfferPassword-infoBtn')
                                .removeClass('disabled');
                            if (data && data.status === 403) {
                                self.$('.SearchOfferPassword-wrapper')
                                    .addClass('invalid');
                            }
                        }
                    });
                });
        }

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:search-offer']();
        }
    },

    activateLogic: function () {
        _.each(this.$('.SearchOfferSection-blocksBlock'), function (item, id) {
            var nameView = 'CommonBlock-' + id;

            this.VIEWS_TO_REGISTER[nameView] = {
                selector: '.SearchOfferSection-blocksBlock--' + id, viewConstructor: CommonBlock
            };
        }.bind(this));

        window.app.views.footer.setMarginBottomContent(this.$el);
        this.position = window.app.data.position || (this.data && this.data.position) || {};
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        console.trace();
        Section.prototype.render.call(this);

        this.activateLogic();
        this.position = this.data && this.data.position || {};

        this.setTitle('true • search: position — ' + this.position.title);
        this.setClass('SearchPage OfferPage SearchExtraPage');
    },

    onPrintClick: function () {
        if (this.disablePrint) return;

        var printContent = this.$('.Content-wrapper')[0];
        var headContent = $('head')[0];
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print ' + this.$('.SearchOfferSection-headerTitle')
            .text();

        var printWindow = window.open(windowUrl, windowName, 'left=0,top=0,width=1024,height=600');

        printWindow.document.write(headContent.innerHTML);
        printWindow.document.write(printContent.innerHTML);
        printWindow.document.documentElement.style.fontSize = '300px';
        printWindow.document.close();
        printWindow.focus();

        this.disablePrint = true;

        setTimeout(function () {
            printWindow.print();
            printWindow.close();
            delete this.disablePrint;
        }.bind(this), 1500);
    },

    onClickSubmit: function (e) {

    }
});
