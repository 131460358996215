var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

require('./CommonSlider.less');
require('jquery-touchswipe/jquery.touchSwipe.js');

module.exports = Base.extend({
    template: require('./CommonSlider.jinja'),

    el: '.CommonSlider',

    events: {
        'click .CommonSlider-navNext': 'nextSlide',
        'click .CommonSlider-navPrev': 'prevSlide',
        'click .CommonSlider-slide': 'clickSLider'
    },

    initialize: function () {
        _.bindAll(this, 'onResize', 'initSlider', 'nextSlide', 'prevSlide', 'moveSlide', 'calcVisArrows');

        this.onResize.__throttled = _.throttle(this.onResize, 100, {trailing: true, leading: true});

        $(window).on('resize', this.onResize.__throttled);

        this.onResize.__throttled();
    },

    onResize: function () {
        this.initSlider();
    },

    initSlider: function () {
        var self = this;
        this.countSlide = this.$el.find('.CommonSlider-slide').length;
        this.curSlide = 0;

        this.$el.swipe({
            //Generic swipe handler for all directions
            swipe: function (event, direction) {
                if (direction === 'right') {
                    self.prevSlide();
                } else {
                    self.nextSlide();
                }
            }
        });

        // this.calcVisArrows();
    },

    calcVisArrows: function () {
        this.$el.find('.CommonSlider-panelNavNext').toggleClass('isDisable', this.curSlide == this.countSlide - 1);
        this.$el.find('.CommonSlider-panelNavPrev').toggleClass('isDisable', this.curSlide == 0);
    },

    clickSLider: function (e) {
        if (window.app.settings.isMobile) return;

        e.preventDefault();
        e.stopPropagation();

        this.curSlide = (this.curSlide + 1 < this.countSlide) ? this.curSlide + 1 : 0;
        this.moveSlide(this.curSlide);
        // this.calcVisArrows();
    },

    nextSlide: function (e) {

        this.curSlide = (this.curSlide + 1 < this.countSlide) ? this.curSlide + 1 : 0;
        this.moveSlide(this.curSlide);
        // this.calcVisArrows();
    },

    prevSlide: function (e) {

        this.curSlide = (this.curSlide - 1 > -1) ? this.curSlide - 1 : this.countSlide - 1;
        this.moveSlide(this.curSlide);
        // this.calcVisArrows();
    },

    moveSlide: function (id) {
        this.$el.find('.CommonSlider-slide.isActive').removeClass('isActive');
        this.$el.find('.CommonSlider-slide[data-slide="' + id + '"]').addClass('isActive');

        this.$el.find('.CommonSlider-panelSlide.isActive').removeClass('isActive');
        this.$el.find('.CommonSlider-panelSlide[data-slide="' + id + '"]').addClass('isActive');
    },

    deactivate: function () {
        $(window).off('resize', this.onResize.__throttled);
    }
});
