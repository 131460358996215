var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var LogosRows = require('front/components/LogosRows/LogosRows');

require('./Nutshell.less');


module.exports = Base.extend({
    template: require('./Nutshell.jinja'),

    el: '.Nutshell',

    initialize: function () {
        new LogosRows();
    }
});