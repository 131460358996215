var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

var FooterClockItem = require('front/components/FooterClockItem/FooterClockItem');
var FooterClockItemTemplate = require('front/components/FooterClockItem/FooterClockItem.jinja');

require('./SideNavMobile.less');

module.exports = Base.extend({
    template: require('./SideNavMobile.jinja'),

    el: '.SideNavMobile',

    events: {
        'click .SideNavMobile-wrapperClose': 'hide',
        'click .SideNavMobile-linksItemLinkExpandable': 'toggleExtraMenu',
        'click .SideNavMobile-linksItemMenuLink--team': 'setTeamToShow'
    },

    isOpen: 0,
    isNewPage: false,

    initialize: function (options) {
        _.bindAll(this, 'toggleVisibility', 'hide', 'triggerMenuShown', 'triggerMenuHidded', 'remapOfficesListByTimezone', 'renderFooterClockItems');

        Base.prototype.initialize.call(this, options);

        this.footer = window.app.data.footer;
        this.offsets = [];

        this.remapOfficesListByTimezone();

        this.renderFooterClockItems();

        for (var i = 0; i < this.offsets.length; i++) {
            new FooterClockItem({el: '.SideNavMobile-footerBottomClocksItem--' + i, clock: this.offsets[i]});
        }
    },

    toggleVisibility: function () {
        var locPath = window.location.pathname.split('/');
        var idMenu = (!!locPath[2]) ? (locPath[1] + '-' + locPath[2]) : ((!!locPath[1]) ? locPath[1] : 'index');

        var $menuItem =
            $('.SideNavMobile-linksItemMenuLink[data-id="' + idMenu + '"]');
        $menuItem.addClass('isActive');

        $('.SideNavMobile-linksItem')
            .removeClass('isActive');
        var $item = $menuItem
            .closest('.SideNavMobile-linksItem');

        $item.toggleClass('isActive');

        this.isOpen = 1 - this.isOpen;
        this.$el.toggleClass('SideNavMobile--visible');

        if (this.isOpen) {
            this.triggerMenuShown();
        } else {
            this.triggerMenuHidded();
        }
    },

    hide: function () {
        this.$el.removeClass('SideNavMobile--visible');
        this.isOpen = 0;
        // this.triggerMenuHidded();
    },

    triggerMenuShown: function () {
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger('PopupShown');
        $(window)
            .scrollTop(0);
        // app.els.$content.css('z-index', 7);
        app.els.$contentContent.addClass('overflowLock');
    },

    triggerMenuHidded: function () {
        app.vent.trigger('PopupHidded');
        $(window)
            .scrollTop(this.savedScroll);
        app.els.$contentContent.removeClass('overflowLock');

        // setTimeout(function () {
        // app.els.$content.css('z-index', 'inherit');
        // }, 300);
    },

    toggleExtraMenu: function (e) {
        var $item = $(e.currentTarget)
            .closest('.SideNavMobile-linksItem');
        var isActive = $item.hasClass('isActive');

        $('.SideNavMobile-linksItem')
            .removeClass('isActive');

        var id = $item.data('id');

        if (isActive) {
            $item.removeClass('isActive');
        } else {
            $item.addClass('isActive');
        }
    },

    renderFooterClockItems: function () {
        var $footerClocks = this.$('.SideNavMobile-footerBottomClocks');

        for (var i = 0; i < this.offsets.length; i++) {
            var divStart = '<div class="SideNavMobile-footerBottomClocksItem isSideNav SideNavMobile-footerBottomClocksItem--' + i + '">';
            var divEnd = '</div>';
            var clockItem = FooterClockItemTemplate.render({
                officeList: this.offsets[i].officeList
            });

            var html = divStart + clockItem + divEnd;

            $footerClocks.append(html);
        }
    },

    remapOfficesListByTimezone: function () {
        if (this.footer.locations) {
            var objectWithTimeZones = _.groupBy(this.footer.locations, 'offset');

            var offsets = [];
            for (var property in objectWithTimeZones) {
                if (objectWithTimeZones.hasOwnProperty(property)) {
                    // do stuff
                    var singleTimeZoneList = objectWithTimeZones[property];
                    var offset = {
                        offset: singleTimeZoneList[0].offset,
                        officeList: []
                    };

                    for (var i in singleTimeZoneList) {
                        var singleTimeZone = singleTimeZoneList[i];
                        offset.officeList.push({
                            'title': singleTimeZone.title,
                            'link': singleTimeZone.link,
                            'address': singleTimeZone.address
                        });
                    }

                    offsets.push(offset);
                }
            }

            this.offsets = _.sortBy(offsets, function (obj) {
                return obj.offset;
            });
        }

        // if (this.footer.locations) {
        //     var objectWithTimeZones = _.groupBy(this.footer.locations, 'offset');
        //
        //     var offsets = [];
        //     for (var property in objectWithTimeZones) {
        //         if (objectWithTimeZones.hasOwnProperty(property)) {
        //             // do stuff
        //             var singleTimeZoneList = objectWithTimeZones[property];
        //             var offset = {
        //                 offset: singleTimeZoneList[0].offset,
        //                 officeList: []
        //             };
        //
        //             for (var i in singleTimeZoneList) {
        //                 var singleTimeZone = singleTimeZoneList[i];
        //                 offset.officeList.push(singleTimeZone.title);
        //             }
        //
        //             offsets.push(offset);
        //         }
        //     }
        //
        //     this.offsets = _.sortBy(offsets, function (obj) {
        //         return obj.offset;
        //     });
        // }
    },

    setTeamToShow: function (e) {
        window.app.data.teamFilterValues.team = 'search';
        window.app.data.teamFilterValues.isCustomTeam = true;
    }
});
