var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var logosRowTemplate = require('./LogosSingleRow.jinja');

require('./LogosRows.less');


module.exports = Base.extend({
    template: require('./LogosRows.jinja'),

    el: '.LogosRows',

    initialize: function () {
        this.$('img')
            .on('load', function () {
                app.vent.trigger('nutshellLogosLoaded');
            });
    }
});
