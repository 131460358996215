var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var PrivacyPolicyBlock = require('front/components/PrivacyPolicyBlock/PrivacyPolicyBlock');

var Section = require('front/pages/Section/Section');

require('./PrivacyPolicySection.less');


module.exports = Section.extend({
    template: require('./PrivacyPolicySection.jinja'),

    el: '.PrivacyPolicySection',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'PrivacyPolicyBlock': {selector: '.PrivacyPolicyBlock', viewConstructor: PrivacyPolicyBlock}
    },

    // apiUrl: Urls['front:api:privacy-policy'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic');

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:privacy-policy']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        window.app.views.extraMenu.initExtraMenu(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        this.setClass('PrivacyPolicyPage TruePage');
        this.setTitle('true • privacy policy');

        Section.prototype.render.call(this);
        this.activateLogic();
    }
});

