var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var CurrentOpenings = require('front/components/CurrentOpenings/CurrentOpenings');
var WhoWeAre = require('front/components/WhoWeAre/WhoWeAre');
var JoinIntro = require('front/components/JoinIntro/JoinIntro');

var Section = require('front/pages/Section/Section');

require('./JoinSection.less');


module.exports = Section.extend({
    el: '.JoinSection',

    template: require('./JoinSection.jinja'),

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'CurrentOpenings': {selector: '.CurrentOpenings', viewConstructor: CurrentOpenings},
        'WhoWeAre': {selector: '.WhoWeAre', viewConstructor: WhoWeAre},
        'JoinIntro': {selector: '.JoinIntro', viewConstructor: JoinIntro}
    },

    // apiUrl: Urls['front:api:join'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic');

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:join']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        window.app.views.extraMenu.initExtraMenu(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        this.setClass('JoinPage TruePage');
        this.setTitle('true • join');

        Section.prototype.render.call(this);
        this.activateLogic();
    }
});

