var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./SearchOurExpertise.less');


module.exports = Base.extend({
    template: require('./SearchOurExpertise.jinja'),

    el: '.SearchOurExpertise',

    initialize: function () {

    }
});
