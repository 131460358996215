var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./FooterNav.less');


module.exports = Base.extend({
    template: require('./FooterNav.jinja'),

    el: '.FooterNav',

    initialize: function (options) {

        _.bindAll(this, 'onResize', 'setMarginItems');

        Base.prototype.initialize.call(this, options);

        this.onResize.__throttle = _.throttle(this.onResize, 50);
        $(window)
            .on('resize', this.onResize.__throttle);
        this.onResize.__throttle();
    },

    onResize: function () {
        this.setMarginItems();
    },

    getHeightFooterNav: function () {
        return $('.FooterNav')
            .innerHeight();
    },

    setMarginItems: function () {
        if (window.app.settings.isMobile) return;

        var maxHeight = 0;
        var maxHeightTitle = 0;

        $('.FooterNav-item')
            .each(function (id, item) {
                var h = $(item)
                    .find('.FooterNav-itemInfoDescr')
                    .innerHeight();

                var hTitle = $(item)
                    .find('.FooterNav-itemInfoTitle')
                    .innerHeight();

                if (maxHeight < h) maxHeight = h;
                if (maxHeightTitle < hTitle) maxHeightTitle = hTitle;
            });

        $('.FooterNav-item')
            .each(function (id, item) {
                var m = maxHeight - $(item)
                    .find('.FooterNav-itemInfoDescr')
                    .innerHeight() + 30;

                var mDescr = maxHeightTitle - $(item)
                    .find('.FooterNav-itemInfoTitle')
                    .innerHeight() + 30;

                $(item)
                    .find('.FooterNav-arrow')
                    .css('margin-top', m + 'px');

                $(item)
                    .find('.FooterNav-itemInfoDescr')
                    .css('margin-top', mDescr + 'px');
            });

    }
});
