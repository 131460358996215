var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/JoinPopup/JoinPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/JoinPopup/JoinPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
output += "\n\n<div class=\"JoinPopup\">\n    <div class=\"JoinPopup-wrapper overflowLock\">\n        <div class=\"JoinPopup-bg\"></div>\n        <div class=\"JoinPopup-content\">\n            <div class=\"JoinPopup-contentInner\">\n                <div class=\"JoinPopup-close\">\n                    <div class=\"JoinPopup-closeItem JoinPopup-closeItem--1\"></div>\n                    <div class=\"JoinPopup-closeItem JoinPopup-closeItem--2\"></div>\n                </div>\n                <div class=\"JoinPopup-title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"title"), env.opts.autoescape);
output += "</div>\n                <div class=\"JoinPopup-jobLinks\">\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"contact")) {
output += "<div class=\"JoinPopup-jobLink\">\n                            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"contact")),"url"), env.opts.autoescape);
output += "\"";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"contact")),"blank")) {
output += " target=\"_blank\" ";
;
}
output += ">get\n                                in touch</a>\n\n                            <div class=\"JoinPopup-arrow\">\n                                ";
output += runtime.suppressValue((lineno = 20, colno = 56, runtime.callWrap(runtime.memberLookup((t_1),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";t_5 += " ";
;
frame = frame.pop();
return new runtime.SafeString(t_5);
});
return macro_t_4;})()})])), env.opts.autoescape);
output += "\n                            </div>\n                        </div>\n                        <div class=\"JoinPopup-jobLinkSeparator\"></div>\n                    ";
;
}
output += "<div class=\"JoinPopup-jobLink\" id=\"shareLink\">\n                        <span>share</span>\n                        <div class=\"JoinPopup-arrow\">\n                            ";
output += runtime.suppressValue((lineno = 28, colno = 52, runtime.callWrap(runtime.memberLookup((t_1),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";t_7 += " ";
;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
output += "\n                        </div>\n\n                    </div>\n                    <div class=\"JoinPopup-shareDropdown\">\n                        <div class=\"JoinPopup-shareLink\" id=\"share-mail\">Mail</div>\n                        <div class=\"JoinPopup-shareLink\" id=\"share-slack\">Send in Slack</div>\n                        <div class=\"JoinPopup-shareLink\" id=\"share-facebook\">Facebook</div>\n                        <div class=\"JoinPopup-shareLink\" id=\"share-twitter\">Twitter</div>\n                        <div class=\"JoinPopup-shareLink\" id=\"share-linkedin\">LinkedIn</div>\n                    </div>\n                </div>\n                <div class=\"JoinPopup-jobDescription\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"description")), env.opts.autoescape);
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/JoinPopup/JoinPopup.jinja"] , dependencies)