var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var NewsList = require('front/components/NewsList/NewsList');

var Section = require('front/pages/Section/Section');

require('./NewsSection.less');


module.exports = Section.extend({
    template: require('./NewsSection.jinja'),

    el: '.NewsSection',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'NewsList': {selector: '.NewsList', viewConstructor: NewsList}
    },

    // apiUrl: Urls['front:api:news'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic');

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:news']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        window.app.views.extraMenu.initExtraMenu(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        Section.prototype.render.call(this);

        this.setClass('NewsPage TruePage');
        this.setTitle('true • news');
        this.activateLogic();
    }
});

