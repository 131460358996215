var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

var DropdownTemplate = require('./ContactBlockDropdown.jinja');
var InputTemplate = require('./ContactBlockInput.jinja');
var TextareaTemplate = require('./ContactBlockTextarea.jinja');
var UploadButtonTemplate = require('./ContactBlockUploadButton.jinja');
var SuccessTemplate = require('./ContactBlockSuccess.jinja');
var LocationTemplate = require('./ContactBlockLocation.jinja');

var ChannelFormTemplate = require('./ContactBlockChannel.jinja');

require('./ContactBlock.less');

module.exports = Base.extend({
    template: require('./ContactBlock.jinja'),

    el: '.ContactBlock',

    events: {
        'click .ContactBlock-channelLink': 'selectChannel',
        'click .ContactBlock-back': 'returnToChannels',
        'click .ContactBlock-formDropdownModelWrapper': 'openDropdown',
        'click .ContactBlock-formDropdownOption': 'selectOption',

        'blur input': 'enableInputValidation',
        'blur textarea': 'enableInputValidation',
        'keyup input': 'disableInputValidation',
        'keyup textarea': 'enableInputValidation',
        'focus input': 'closeDropdowns',
        'focus textarea': 'closeDropdowns',

        'click .ContactBlock-submitButton': 'submit'
    },

    tempFilesApi: function () {
        return (Urls['front:api:files:control-temp-file-upload']());
    },

    initialize: function () {
        _.bindAll(this, 'initValues', 'renderForm', 'outsideFormClick', 'selectChannel', 'renderFieldsForm',
            'returnToChannels', 'openDropdown', 'selectOption', 'validateFields', 'enableInputValidation',
            'getChannelName', 'disableInputValidation', 'validateInputValue', 'closeDropdowns', 'changeFile',
            'resetFileLoading', 'submit', 'renderSuccessForm', 'onFileUploadSuccess', 'onFileUploadError',
            'renderLocations');

        this.initValues();
        this.renderForm();
        $(document)
            .on('click', this.outsideFormClick);
    },

    initValues: function () {
        this.channel = -1;

        this.true = window.app.data.contact.true;
        this.search = window.app.data.contact.search;
        this.thrive = window.app.data.contact.thrive;
        this.locations = window.app.data.contactLocations;

        this.$description = this.$('.ContactBlock-description');
        this.$backButton = this.$('.ContactBlock-back');
        this.$submitButton = this.$('.ContactBlock-submitButton');
        this.$updatesCheckBox = this.$('.ContactBlock-updatesCheckbox');
        this.$formTitle = this.$('.ContactBlock-formTitle');
        this.$formWrapper = this.$('.ContactBlock-formWrapper');
    },

    selectChannel: function (e) {
        this.channel = +$(e.target)
            .data('id');
        this.$formTitle.html($(e.target)
            .find('span')
            .text());
        this.renderForm();
    },

    renderForm: function (withAnimation) {
        switch (this.channel) {
            case -1:
                this.renderChannelForm(withAnimation);
                break;
            case 1:
                this.renderFieldsForm('true');
                break;
            case 2:
                this.renderFieldsForm('search');
                break;
            case 3:
                this.renderFieldsForm('thrive');
                break;
            case 4:
                this.renderLocations();
                break;
            case 5:
                this.renderSuccessForm();
        }
    },

    renderLocations: function () {
        var self = this;

        // this.$('.ContactBlock-form').html('');
        this.$description.addClass('hide');
        this.$backButton.show();
        this.$backButton.removeClass('hide');
        // this.$submitButton.show();
        // this.$updatesCheckBox.show();
        this.$formTitle.show();
        this.$formWrapper.addClass('isLocations');
        this.$formWrapper.addClass('fadeIn');
        this.$('.ContactBlock-form').html('');

        _.each(this.locations, function (location) {
            var renderItem = LocationTemplate.render({location: location});

            self.$('.ContactBlock-form').append(renderItem);
        });
        this.$('.ContactBlock-channel').addClass('hide');
        // this.$formWrapper.removeClass('fadeIn');
    },

    renderSuccessForm: function () {
        var html = SuccessTemplate.render();
        this.$('.ContactBlock-form')
            .html(html);

        this.$formWrapper.removeClass('fadeIn');
    },

    renderFieldsForm: function (channel) {
        var uploadFileInputId;
        if (this[channel].fields && this[channel].fields.length > 0) {

            this.$description.addClass('hide');
            this.$backButton.show();
            this.$backButton.removeClass('hide');
            this.$submitButton.show();
            this.$updatesCheckBox.show();
            this.$formTitle.show();
            this.$formWrapper.addClass('isFields');
            this.$formWrapper.addClass('fadeIn');

            var textAreaVal;

            var combinedHtml = '';
            for (var i = 0; i < this[channel].fields.length; i++) {
                var field = this[channel].fields[i];
                var template = '';

                switch (field.type) {
                    case 'input': {
                        template = InputTemplate.render({
                            data: {
                                model: field.model,
                                placeholder: field.placeholder,
                                required: field.required,
                                id: field.id,
                                channel: channel
                            }
                        });
                    }
                        break;
                    case 'email': {
                        template = InputTemplate.render({
                            data: {
                                model: field.model,
                                placeholder: field.placeholder,
                                required: field.required,
                                id: field.id,
                                channel: channel,
                                type: 'email'
                            }
                        });
                    }
                        break;
                    case 'dropdown': {
                        template = DropdownTemplate.render({
                            data: {
                                id: field.id,
                                model: field.model,
                                placeholder: field.placeholder,
                                options: app.data.contactDropdowns[channel] && app.data.contactDropdowns[channel][field.id] || field.options || [],
                                required: field.required,
                                channel: channel
                            }
                        });
                    }
                        break;
                    case 'textarea': {
                        template = TextareaTemplate.render({
                            data: {
                                id: field.id,
                                placeholder: field.placeholder,
                                required: field.required,
                                channel: channel
                            }
                        });
                        textAreaVal = field.model;
                    }
                        break;
                    case 'upload': {
                        uploadFileInputId = field.id;
                        template = UploadButtonTemplate.render({
                            data: {
                                id: field.id,
                                required: field.required,
                                placeholder: field.placeholder,
                                channel: channel,
                                file: this.attachedFile
                            }
                        });

                    }
                        break;
                    case 'label': {
                        template = '<div class="ContactBlock-formLabel">' + field.text + '</div>';
                    }
                        break;
                }

                combinedHtml += template;
            }

            this.$('.ContactBlock-form')
                .html(combinedHtml);


            if (textAreaVal) {
                this.$('textarea')
                    .val(textAreaVal);
            }

            if (uploadFileInputId) {
                var $fileUpload = $('#' + uploadFileInputId);
                var $uploadWrapper = $('.ContactBlock-uploadButtonWrapper');
                var $fileUploadLabel = $('#' + uploadFileInputId + '-label');
                var self = this;
                if (this.attachedFileName) {
                    $fileUploadLabel.html(this.attachedFileName);
                }

                if (this.attachedFile) {
                    $uploadWrapper.addClass('isFileAttached');
                }
                $fileUpload
                    .change(function (e) {
                        self.changeFile(e, uploadFileInputId);
                        self.validateFields();
                    });
            }


            this.$('.ContactBlock-channel')
                .addClass('hide');
        }
    },

    renderChannelForm: function (withAnimation) {
        var html = ChannelFormTemplate.render();
        this.$('.ContactBlock-form')
            .html(html);
        if (withAnimation) {
            this.$('.ContactBlock-channel')
                .addClass('withAnimation');
        }
        this.$formWrapper.removeClass('fadeIn');
        var self = this;

        setTimeout(function () {
            self.$('.ContactBlock-channel')
                .addClass('active');
        });

    },

    returnToChannels: function () {
        this.channel = -1;
        this.$description.removeClass('hide');
        this.$formTitle.hide();
        this.$backButton.addClass('hide');
        this.$submitButton.hide();
        this.$updatesCheckBox.hide();
        this.$formWrapper.removeClass('isFields');
        this.renderForm(true);
    },

    openDropdown: function (e) {
        var $target = $(e.target);
        var isExpanded = $target.hasClass('expanded');
        $('.ContactBlock-formDropdownModelWrapper')
            .removeClass('expanded');
        if (!isExpanded) {
            $target.addClass('expanded');
        } else {
            $target.removeClass('expanded');
        }
    },

    selectOption: function (e) {
        var $target = $(e.target);
        var optionId = $target
            .data('option-id');
        var fieldId = $target.data('field-id');
        var formName = $target.data('channel');
        var newOption;
        var fields = this[formName].fields;

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            if (field.id === fieldId) {
                var options = app.data.contactDropdowns[formName] && app.data.contactDropdowns[formName][field.id]
                    || field.options || [];
                for (var j = 0; j < options.length; j++) {
                    var option = options[j];
                    if (option.id === optionId) {
                        newOption = option;
                    }
                }
                field.model = newOption;
            }
        }

        this.validateFields();
        this.renderForm();
    },

    getChannelName: function (channelId) {
        var channel = '';
        switch (channelId) {
            case 1:
                channel = 'true';
                break;
            case 2:
                channel = 'search';
                break;
            case 3:
                channel = 'thrive';
                break;
            default:
                return;
        }

        return channel;
    },

    enableInputValidation: function (e) {
        var $field = $(e.target);
        var id = $field.data('id');
        var channel = this.getChannelName(this.channel);
        var fields = this[channel].fields;


        if (fields) {
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];

                if (field && field.id && field.id === id) {
                    field.isValidation = true;
                    field.model = $field.val();
                }

            }
        }

        this.validateFields();
    },

    disableInputValidation: function (e) {
        var channel = this.getChannelName(this.channel);
        var fields = this[channel].fields;


        if (fields) {
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                field.isValidation = false;
            }
        }

        this.validateFields();
    },

    validateInputValue: function (type, value) {
        switch (type) {
            case 'input':
                return (value && value !== '');
            case 'textarea':
                return (value && value !== '');
            case 'upload':
                return !!value;
            case 'email': {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                return re.test(value);
            }
        }
    },

    validateFields: function () {
        var channel = this.getChannelName(this.channel);


        var fields = this[channel].fields;

        var formValid = true;

        if (fields) {
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (!field.id) {
                    continue;
                }
                var $field = this.$('#' + field.id);
                if (field.type === 'dropdown') {
                    if (field.model) {
                        field.isValid = true;
                    }
                } else {
                    var value = field.type === 'upload' ? this.attachedFile : $field.val();
                    var isValid = this.validateInputValue(field.type, value);
                    if (!isValid) {
                        if (field.isValidation) {
                            $field.addClass('invalid');
                        }

                        field.isValid = false;
                    } else {
                        field.isValid = true;
                        $field.removeClass('invalid');
                    }
                }

                if (!field.isValid) {
                    formValid = false;
                }
            }
        }

        if (formValid) {
            this.$submitButton.removeClass('disabled');
        } else {
            this.$submitButton.addClass('disabled');
        }
    },

    closeDropdowns: function () {
        $('.ContactBlock-formDropdownModelWrapper')
            .removeClass('expanded');

    },

    outsideFormClick: function (e) {
        var self = this;
        setTimeout(function () {
            var $form = this.$('.ContactBlock-form');
            if ($(e.target)
                .closest($form).length === 0) {
                self.closeDropdowns(e);
            }

        });
    },

    resetFileLoading: function () {
        this.fileLoadXhr && this.fileLoadXhr.abort();
    },

    changeFile: function (e, uploadFileId) {
        if (!e.target.files || !e.target.files.length) return;

        this.resetFileLoading();

        var fil = e.target.files[0];
        var self = this;

        this.$('.ContactBlock-uploadButtonWrapper')
            .addClass('isFileAttached');

        this.$('.ContactBlock-uploadButtonWrapper')
            .attr('data-state', 'loading');

        this.attachedFileName = fil.name;

        this.$('.ContactBlock-uploadButtonUploaded span')
            .html('File is uploading...&nbsp;');

        this.fileLoadXhr = new XMLHttpRequest();
        this.fileLoadXhr.open('POST', this.tempFilesApi());

        this.fileLoadXhr.onload = function (res, data) {
            if (res) {
                var state = res.currentTarget && res.currentTarget.readyState;
                var status = res.currentTarget && res.currentTarget.status;

                if (state && state === 4 && status === 200) {
                    self.onFileUploadSuccess(this.responseText, uploadFileId, fil.name);
                } else {
                    self.onFileUploadError(uploadFileId);
                }
            }

        };

        this.fileLoadXhr.onerror = function () {
            self.$('.ContactBlock-uploadButtonWrapper')
                .attr('data-file', '');
            self.$('.ContactBlock-uploadButtonWrapper')
                .attr('data-state', 'idle');
            self.$('.ContactBlock-uploadButtonWrapper #' + uploadFileId + '-label')
                .html('');
            self.$('.ContactBlock-uploadButtonUploaded span')
                .html('File upload error!');
            self.validateFields();
        };


        var formData = new FormData();
        formData.append('file', fil);
        this.fileLoadXhr.send(formData);

        $(e.currentTarget)
            .val('');
    },

    onFileUploadSuccess: function (responseText, uploadFileId, fileName) {
        var responseObject = app.AdoptTempFileObject(responseText, 'file');

        this.$('.ContactBlock-uploadButtonWrapper')
            .attr('data-file', JSON.stringify(responseObject));
        this.$('.ContactBlock-uploadButtonWrapper')
            .attr('data-new', 1);
        this.$('.ContactBlock-uploadButtonWrapper')
            .attr('data-state', 'idle');
        this.$('.ContactBlock-uploadButtonUploaded span')
            .html('Uploaded file: ');
        this.$('.ContactBlock-uploadButtonWrapper #' + uploadFileId + '-label')
            .attr('href', responseObject.tempFile);
        this.$('.ContactBlock-uploadButtonWrapper #' + uploadFileId + '-label')
            .text(fileName);

        this.attachedFile = responseObject;
        this.validateFields();
    },

    onFileUploadError: function (uploadFileId) {
        this.$('.ContactBlock-uploadButtonWrapper')
            .attr('data-file', '');
        this.$('.ContactBlock-uploadButtonWrapper')
            .attr('data-state', 'idle');
        this.$('.ContactBlock-uploadButtonWrapper #' + uploadFileId + '-label')
            .html('');
        this.$('.ContactBlock-uploadButtonUploaded span')
            .html('File upload error!');
        this.validateFields();
    },

    submit: function () {
        var channelName = this.getChannelName(this.channel);
        this.$submitButton.addClass('disabled');
        this.$('#uploadResult')
            .removeClass('succeed');
        this.$('#uploadResult')
            .removeClass('failed');

        var fields = this[channelName].fields;

        var attachments = [];

        var data = {};

        for (var i = 0; i < fields.length; i++) {
            var key = fields[i].id;
            var value;
            var isAttachment = false;

            if (fields[i].type === 'dropdown') {
                value = fields[i].model && fields[i].model.id;
            } else {

                if (fields[i].type === 'upload') {
                    if (this.attachedFile) {
                        value = this.attachedFile;
                        isAttachment = true;
                        attachments.push({
                            field: key,
                            attachment: {
                                'temp_file_id': value && value.tempFileId
                            }
                        });
                    }
                } else {
                    value = fields[i].model;
                }
            }

            if (!isAttachment) {
                data[key] = value;
            }
        }

        data.Receive_Updates__c = this.$('#contactInput-updates')
            .prop('checked');


        var obj = {
            type: this.channel,
            data: data,
            attachments: attachments
        };

        var self = this;
        $.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: Urls['front:api:contact-send'](),
            data: JSON.stringify(obj),
            success: function (e) {
                self.$submitButton.removeClass('disabled');
                self.$submitButton.hide();
                self.$updatesCheckBox.hide();
                self.channel = 5;
                self.renderForm();
                setTimeout(function () {
                    $(window)
                        .scrollTop(0);
                });

            },
            error: function (e) {
                self.$('#uploadResult')
                    .addClass('failed');
                self.$('#uploadResult')
                    .text('Upload Failed. Try again');

                self.$submitButton.removeClass('disabled');
            }
        });
    }


});
