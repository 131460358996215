var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/ContactBlock/ContactBlock.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/ContactBlock/ContactBlock.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <div class=\"ContactBlock Block leftCirclePoint\">\n        <div class=\"ContactBlock-back\" style=\"display: none\">\n            <div class=\"ContactBlock-backArrow\">\n                ";
t_5 += runtime.suppressValue((lineno = 6, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-left",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
t_5 += "\n            </div>\n            <span>back</span>\n        </div>\n        <div class=\"ContactBlock-head\">\n            <div class=\"ContactBlock-title\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"overview_title"), env.opts.autoescape);
t_5 += "</div>\n            <div class=\"ContactBlock-description\">\n                ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"overview_text")), env.opts.autoescape);
t_5 += "\n            </div>\n        </div>\n        <div class=\"ContactBlock-content\">\n\n            <div class=\"ContactBlock-formWrapper\">\n                <div class=\"ContactBlock-formTitle\"></div>\n                <div class=\"ContactBlock-form\"></div>\n                <div class=\"ContactBlock-updatesCheckbox\" style=\"display: none;\">\n                    <div class=\"ContactBlock-updatesCheckboxWrapper\">\n                        <input id=\"contactInput-updates\" type=\"checkbox\">\n                        <div class=\"ContactBlock-updatesCheckboxStylized\"></div>\n                    </div>\n                    <span>Yes, I’d like to receive updates and stay in touch.</span>\n                </div>\n                <span id=\"uploadResult\" class=\"ContactBlock-uploadResult\"></span>\n                <button class=\"ContactBlock-submitButton disabled\" style=\"display: none;\">submit</button>\n            </div>\n\n\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("ContactBlock");
context.setVariable("ContactBlock", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/ContactBlock/ContactBlock.jinja"] , dependencies)