var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/pages/Section/Section.jinja"] = require( "front/pages/Section/Section.jinja" );
dependencies["front/components/TeamMembers/TeamMembers.jinja"] = require( "front/components/TeamMembers/TeamMembers.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/SearchTeam/SearchTeamSection.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/SearchTeam/SearchTeamSection.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
env.getTemplate("front/pages/Section/Section.jinja", true, "assets/app/front/pages/SearchTeam/SearchTeamSection.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
parentTemplate = t_5
for(var t_4 in parentTemplate.blocks) {
context.addBlock(t_4, parentTemplate.blocks[t_4]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/SearchTeam/SearchTeamSection.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("macros", t_7);
output += "\n\n";
env.getTemplate("front/components/TeamMembers/TeamMembers.jinja", false, "assets/app/front/pages/SearchTeam/SearchTeamSection.jinja", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
if(Object.prototype.hasOwnProperty.call(t_10, "TeamMembers")) {
var t_13 = t_10.TeamMembers;
} else {
cb(new Error("cannot import 'TeamMembers'")); return;
}
context.setVariable("TeamMembers", t_13);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
output += t_14;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
output += t_16;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content_class"))(env, context, frame, runtime, function(t_19,t_18) {
if(t_19) { cb(t_19); return; }
output += t_18;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
output += t_22;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "true • search: team";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <meta name=\"description\" content=\"\"/>\n\n    <meta property=\"og:type\" content=\"website\"/>\n    <meta property=\"og:image\" content=\"";
output += runtime.suppressValue((lineno = 12, colno = 68, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [(lineno = 12, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/headers/bg-search-team.jpg"]))])), env.opts.autoescape);
output += "\"/>\n    <meta property=\"og:image:width\" content=\"1440\"/>\n    <meta property=\"og:image:height\" content=\"640\"/>\n    <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 15, colno = 66, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n    <meta property=\"og:title\" content=\"true • search: team\"/>\n    <meta property=\"og:description\" content=\"\"/>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content_class(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "TeamPage SearchPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 22;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"SearchTeamSection PageSection\">\n    <div class=\"SearchTeamSection-wrapper NeedFixOnPopup\">\n        <div class=\"Content-outter isInitialState\">\n            <div class=\"Content-wrapper\">\n                ";
output += runtime.suppressValue((lineno = 27, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TeamMembers"), "TeamMembers", context, [{"page": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "team")),"teamMembers"),"membersList": runtime.contextOrFrameLookup(context, frame, "membersList")},true])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n    </div>\n    <div class=\"Section-content\">\n        ";
context.getBlock("section_content")(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n    </div>\n</div>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_section_content(env, context, frame, runtime, cb) {
var lineno = 32;
var colno = 11;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 37;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_26,hole_45) {
if(t_26) { cb(t_26); return; }
hole_45 = runtime.markSafe(hole_45);
output += "\n    ";
output += runtime.suppressValue(hole_45, env.opts.autoescape);
output += "\n\n    window.app.data.teamFilterValues.team = \"search\";\n    window.app.data.teamFilterValues.isCustomTeam = true;\n\n    window.app.data.membersList = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "membersList"))), env.opts.autoescape);
output += ";\n    window.app.data.teamsList = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "teamsList"))), env.opts.autoescape);
output += ";\n    window.app.data.regionsList = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "regionsList"))), env.opts.autoescape);
output += ";\n    window.app.data.positionsList = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "positionsList"))), env.opts.autoescape);
output += ";\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_social: b_social,
b_content_class: b_content_class,
b_content: b_content,
b_section_content: b_section_content,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/SearchTeam/SearchTeamSection.jinja"] , dependencies)