var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');
var CommonBlockVideo = require('front/components/CommonBlockVideo/CommonBlockVideo');
var CommonSlider = require('front/components/CommonSlider/CommonSlider');

require('front/components/CommonQuote/CommonQuote');

require('./CommonBlock.less');

module.exports = Base.extend({
    template: require('./CommonBlock.jinja'),

    el: '.CommonBlock',

    events: {
    },

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        // 'CommonBlockVideo': {selector: this.$el.find('.CommonBlock'), viewConstructor: CommonBlockVideo},
        // 'CommonSlider': {selector: this.$el.find('.CommonBlock'), viewConstructor: CommonSlider}
    },

    initialize: function (options) {
        Base.prototype.initialize.call(this, options);
        this.block = options.block;
        this.id = options.id;

        Base.prototype.initialize.call(this, options);
    },

    activate: function () {
        var $commonBlock = this.$('.CommonBlock');
        var id = $commonBlock.data('id');
        var selector = '.CommonBlock--' + id;

        if ($commonBlock.hasClass('CommonBlock--video')) {
            selector += ' .CommonBlockVideo';

            this.VIEWS_TO_REGISTER['CommonBlockVideo-' + id] = {
                selector: selector, viewConstructor: CommonBlockVideo
            };
        } else if ($commonBlock.hasClass('CommonBlock--gallery')) {
            selector += ' .CommonSlider';

            this.VIEWS_TO_REGISTER['CommonBlockSlider-' + id] = {
                selector: selector, viewConstructor: CommonSlider
            };
        }

        Base.prototype.activate.call(this);
    }
});
