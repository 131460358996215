var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./NotFound.less');

module.exports = Base.extend({
    template: require('./NotFound.jinja'),

    el: '.NotFound',

    initialize: function () {

    },

    render: function () {
        this.setTitle('true • 404');
    }
});
