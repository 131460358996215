var _ = require('underscore/underscore');
var SubSection = require('front/pages/Section/SubSection');
var Backbone = require('backbone');

var Urls = require('django_js_front/reverse.js');

require('./SearchClientsPopup.less');


module.exports = SubSection.extend({
    el: '.SearchClientsPopup',

    template: require('./SearchClientsPopup.jinja'),

    events: {
        'click .SearchClientsPopup-close': 'close',
        'click .SearchClientsPopup-bg': 'close',
        'mouseenter .SearchClientsPopup-headerImagesImg': 'hoverCardStat',
        'click #shareLink': 'activateShareDropdown'
    },

    initialize: function (options) {
        this.options = options || {};

        SubSection.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic', 'triggerPopupShown', 'triggerPopupHidded', 'activateShareDropdown', 'deactivateShareDropdown');

        this.slug = this.options.slug;

        if (this.options.server) {
            this.activateLogic(true);

            this.data = {}; //prevent api call for data when opens from direct url
        } else {
            this.apiUrl = this.options.apiUrl;
        }

        // this.backUrl = options.backUrl || this.rootUrl;

        // Backbone.history.navigate('join/' + options.slug);

        $(document)
            .on('click', this.deactivateShareDropdown);
    },

    renderData: function () {
        this.setTitle(' — ' + this.data.title, true);

        return {
            popup: this.data || {}
        };
    },

    activateLogic: function (isServer) {
        this.$el.hide();
        this.$el.addClass('SearchClientsPopup--visible');
        this.$el.show();
        this.$el.outerHeight();
        this.$('.SearchClientsPopup-content')
            .scrollTop(0);
        this.$('.SearchClientsPopup-content')
            .focus(); //for pgdn pgup shortcuts
        var self = this;
        if (isServer) {
            setTimeout(function () {
                self.$el.find('.SearchClientsPopup-wrapper')
                    .removeClass('overflowLock');
                self.triggerPopupShown();
            }.bind(this), this.TIME_ANIMATION);
        } else {
            this.$el.find('.SearchClientsPopup-wrapper')
                .removeClass('overflowLock');
            this.triggerPopupShown();
        }
        window.app.views.extraMenu.initExtraMenu(this.$el);
    },

    hoverCardStat: function (e) {
        var $item = $(e.currentTarget);
        this.$el.find('.SearchClientsPopup-headerImagesImg.isActive')
            .removeClass('isActive');
        $item.addClass('isActive');
    },

    triggerPopupShown: function () {
        window.app.views.searchExtraMenu.fixMenu();
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger('PopupShown');
        // window.app.views.nav.toggleClose(this.rootUrl);
        $(window)
            .scrollTop(0);
    },

    triggerPopupHidded: function () {
        window.app.views.searchExtraMenu.unFixMenu();

        app.vent.trigger('PopupHidded');
        $(window)
            .scrollTop(this.savedScroll);
    },

    close: function () {
        // var backUrl = this.backUrl || this.rootUrl;
        // Backbone.history.navigate(backUrl, {trigger: 'true'});
        this.setTitle(this.titleBefore);

        app.router.navigateBack();
    },

    // render: function () {
    //     SubSection.prototype.render.call(this);
    //
    //     if (this.options.server) this.activateLogic();
    // },

    activate: function (params) {

        return SubSection.prototype.activate.call(this, params)
            .then(function () {
                this.$content = this.$('.SearchClientsPopup-content');
                this.$content.off('scroll', this.onScroll);
                this.$content.on('scroll', this.onScroll);
            }.bind(this));
    },

    playIn: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            self.$el.hide();
            self.$el.show();
            self.$el.addClass('SearchClientsPopup--visible');
            self.$el.outerHeight();
            // setTimeout(function () {
            // self.$el.find('.SearchClientsPopup-wrapper').removeClass('overflowLock');
            self.triggerPopupShown();
            // }, self.TIME_ANIMATION);
            resolve();
        });
    },

    playOut: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            // self.$el.find('.SearchClientsPopup-wrapper').addClass('overflowLock');
            self.$el.removeClass('SearchClientsPopup--visible');
            // window.app.views.nav.toggleClose(this.rootUrl);
            setTimeout(function () {
                self.triggerPopupHidded();
                resolve();
            }, self.TIME_ANIMATION);
        });
    },

    destroy: function () {
        return SubSection.prototype.destroy.call(this);
    },

    deactivate: function (params) {

        this.triggerPopupHidded();

        return SubSection.prototype.deactivate.call(this, params);
    },

    activateShareDropdown: function (e) {
        e.stopPropagation();
        this.$('.SearchClientsPopup-shareDropdown')
            .addClass('active');
    },

    deactivateShareDropdown: function (e) {
        setTimeout(function () {
            var $dropdown = this.$('.SearchClientsPopup-shareDropdown');
            if ($dropdown.hasClass('active') && $(e.target)
                .closest($dropdown)) {
                this.$('.SearchClientsPopup-shareDropdown')
                    .removeClass('active');
            }

        });
    }

});
