var Page = require('front/pages/Page/Page');
var Utils = require('front/utils/Utils');

//require('material-design-lite/material.min.css');
//require('material-design-lite/material.min.js');

require('front/components/Radio/Radio');
require('./SlackSelectChannel.less');

module.exports = Page.extend({

    initialize: function (options) {
        //componentHandler.upgradeDom();
    },

    activate: function (params) {
        var renderedPromise = Utils.createEmptyPromise();

        return renderedPromise
            .then(function () {
            });
    },

    render: function () {
        Section.prototype.render.call(this);

        this.setClass('TrueSlackPage TruePage');
        this.setTitle('true • slack share');
        this.activateLogic();
    }
});