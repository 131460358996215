var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var Section = require('front/pages/Section/Section');
var SubSectionDummy = require('front/pages/Section/SubSectionDummy');

var IndexSection = require('front/pages/Index/IndexSection');
var NewsSection = require('front/pages/News/NewsSection');
var JoinSection = require('front/pages/Join/JoinSection');
var JoinPopup = require('front/pages/JoinPopup/JoinPopup');
var TeamSection = require('front/pages/Team/TeamSection');
var TeamMemberPopup = require('front/pages/TeamMemberPopup/TeamMemberPopup');
var SearchSection = require('front/pages/Search/SearchSection');
var SearchOfferSection = require('front/pages/SearchOffer/SearchOfferSection');
var SearchIndustriesSection = require('front/pages/SearchIndustries/SearchIndustriesSection');
var SearchPositionsSection = require('front/pages/SearchPositions/SearchPositionsSection');
var SearchAssetClassesSection = require('front/pages/SearchAssetClasses/SearchAssetClassesSection');
var SearchGlobalReachSection = require('front/pages/SearchGlobalReach/SearchGlobalReachSection');
var SearchClientsPopup = require('front/pages/SearchClientsPopup/SearchClientsPopup');
var SearchRecentPlacementSection = require('front/pages/RecentPlacement/RecentPlacementSection');
var SearchTeamSection = require('front/pages/SearchTeam/SearchTeamSection');
var DevelopmentSection = require('front/pages/Development/DevelopmentSection');
var TechnologiesSection = require('front/pages/Technologies/TechnologiesSection');
var ContactSection = require('front/pages/Contact/ContactSection');
var PrivacyPolicySection = require('front/pages/PrivacyPolicy/PrivacyPolicySection');
var OptOutGuideSection = require('front/pages/OptOutGuide/OptOutGuideSection');
var NotFound = require('front/pages/NotFound/NotFound');
var NewsPopup = require('front/pages/NewsPopup/NewsPopup');
var OurStory = require('front/pages/OurStory/OurStorySection');
var SlackSelectChannel = require('front/pages/SlackSelectChannel/SlackSelectChannel');

module.exports = Backbone.Router.extend({
    routes: {
        '': 'index',
        'join(/)': 'join',
        'news/': 'news',
        'news/article-:slug(/)': 'newsPopup',
        'article-:slug(/)': 'newsPopup',
        'search/article-:slug(/)': 'newsPopup',
        'join/:slug(/)': 'joinPopup',
        'team/': 'team',
        'our-story/': 'ourStory',
        'team/:slug(/)': 'teamMemberPopup',
        'contact/': 'contact',
        'search/': 'search',
        'search/sectors/': 'searchIndustries',
        'search/sectors/:slug(/)': 'searchIndustriesPopup',
        'search/positions/': 'searchPositions',
        'search/positions/:slug(/)': 'searchPositionsPopup',
        'search/asset-classes/': 'searchAssetsClasses',
        'search/asset-classes/:slug(/)': 'searchAssetClassesPopup',
        'search/global-reach/': 'searchGlobalReach',
        'search/global-reach/:category/:slug(/)': 'searchGlobalReachRegionPopup',
        'search/global-reach/:slug(/)': 'searchGlobalReachPopup',
        'search/team/': 'searchTeam',
        'positions/:slug(/)': 'searchOffer',
        'search/recent-placements/': 'searchRecentPlacements',
        'slack/select_channel(/)': 'slackSelectChannel',
        'development/': 'development',
        'technologies/': 'technologies',
        'opt-out-guide/': 'opt-out-guide',
        ':slug(/)': 'joinPopup',
        'search/:slug(/)': 'teamMemberPopup',
        'development/:slug(/)': 'teamMemberPopup',
        'technologies/:slug(/)': 'teamMemberPopup'
    },

    index: function () {
        this.activate(IndexSection, {view: SubSectionDummy});
    },

    join: function () {
        this.activate(JoinSection, {view: SubSectionDummy});
    },

    news: function () {
        this.activate(NewsSection, {view: SubSectionDummy});
    },

    ourStory: function () {
        this.activate(OurStory, {view: SubSectionDummy});
    },

    newsPopup: function (slug) {
        if (!app.state.viewConstructor) {
            this.history.push(Urls['front:news']()); //back url for direct popup open
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(app.state.viewConstructor || NewsSection, {
            view: NewsPopup,
            viewData: {slug: slug}
        });
    },

    joinPopup: function (slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:join']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(app.state.viewConstructor || JoinSection, {
            view: JoinPopup,
            viewData: {slug: slug}
        });
    },

    team: function () {
        this.activate(TeamSection, {view: SubSectionDummy});
    },

    teamMemberPopup: function (slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:team']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(app.state.viewConstructor || TeamSection, {
            view: TeamMemberPopup,
            viewData: {slug: slug}
        });
    },

    search: function () {
        this.activate(SearchSection, {view: SubSectionDummy});
    },

    searchOffer: function (slug) {
        this.activate(SearchOfferSection, {
            view: SubSectionDummy,
            viewData: {slug: slug}
        });
    },

    searchIndustries: function () {
        this.activate(SearchIndustriesSection, {view: SubSectionDummy});
    },

    searchPositions: function () {
        this.activate(SearchPositionsSection, {view: SubSectionDummy});
    },

    searchAssetsClasses: function () {
        this.activate(SearchAssetClassesSection, {view: SubSectionDummy});
    },

    searchGlobalReach: function () {
        this.activate(SearchGlobalReachSection, {view: SubSectionDummy});
    },

    searchIndustriesPopup: function (slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:search-industries']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(SearchIndustriesSection, {
            view: SearchClientsPopup,
            viewData: {slug: slug, apiUrl: Urls['front:api:search-industries-popup'](slug)}
        });
    },

    searchPositionsPopup: function (slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:search-positions']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(SearchPositionsSection, {
            view: SearchClientsPopup,
            viewData: {slug: slug, apiUrl: Urls['front:api:search-positions-popup'](slug)}
        });
    },

    searchAssetClassesPopup: function (slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:search-asset-classes']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(SearchAssetClassesSection, {
            view: SearchClientsPopup,
            viewData: {slug: slug, apiUrl: Urls['front:api:search-asset-classes-popup'](slug)}
        });
    },

    searchGlobalReachPopup: function (slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:search-global-reach']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(SearchGlobalReachSection, {
            view: SearchClientsPopup,
            viewData: {slug: slug, apiUrl: Urls['front:api:search-global-reach-popup'](slug)}
        });
    },

    searchGlobalReachRegionPopup: function (category, slug) {
        if (!app.state.viewConstructor) {
            // this.history.push('join/' + slug); //back url for direct popup open
            this.history.push(Urls['front:search-global-reach']());
        }
        //set underlying page as current active page view, otherwise set default parent page for this popup
        this.activate(SearchGlobalReachSection, {
            view: SearchClientsPopup,
            viewData: {category: category, slug: slug, apiUrl: Urls['front:api:search-global-reach-region-popup'](category, slug)}
        });
    },

    searchRecentPlacements: function () {
        this.activate(SearchRecentPlacementSection, {view: SubSectionDummy});
    },

    searchTeam: function () {
        this.activate(SearchTeamSection, {view: SubSectionDummy});
    },

    slackSelectChannel: function () {
        this.activate(Section, {view: SlackSelectChannel});
    },

    development: function () {
        this.activate(DevelopmentSection, {view: SubSectionDummy});
    },

    technologies: function () {
        this.activate(TechnologiesSection, {view: SubSectionDummy});
    },

    contact: function () {
        this.activate(ContactSection, {view: SubSectionDummy});
    },

    privacy: function () {
        this.activate(PrivacyPolicySection, {view: SubSectionDummy});
    },

    'opt-out-guide': function () {
        this.activate(OptOutGuideSection, {view: SubSectionDummy});
    },

    notFound: function () {
        this.activate(NotFound);
    },

    activate: function (view, params) {
        params = params || {};

        // view is rendered on server
        if (!app.state.view) {
            params.server = true;
            params.inAnimated = false;
            app.state.viewConstructor = view;
            app.state.view = new view(params);
            app.state.view.activate();
            app.state.currentViewClass = view;

            return;
        }

        app.state.prevView = app.state.view;

        if (this.isSectionLogic(app.state.prevView, view)) {
            this.activateSectionLogic(view, params);
        } else {
            this.activateStandardLogic(view, params);
        }
    },

    // активация перехода с полной перезагрузкой и лоадером
    activateStandardLogic: function (view, params) {
        var newParams = _.defaults({server: false, inAnimated: true}, params);
        app.state.viewConstructor = view;

        var newView = new view(newParams);
        // var duration = params.fastNavigate ? 0 : ANIMATION_DURATION;

        return Promise.all([app.state.view.playOut({
            // duration: duration,
            // zoom: params.zoomNavigate,
            view: newView
        }), newView.loadData()])
            .then(function () {
                app.els.$content.css({minHeight: app.els.$content.height()});

                return app.state.view.deactivate({destroy: true});
            })
            .then(function () {
                app.state.isServer = false;
                app.state.view = newView;
                app.state.view.activate(newParams)
                    .then(function () {
                        app.els.$content.css({minHeight: ''});
                        $(window)
                            .scrollTop(0);

                        return app.state.view.playIn({
                            // duration: duration,
                            // zoom: params.zoomNavigate,
                            view: newView
                        });
                    });
            });

    },

    // navigation activation with popup
    activateSectionLogic: function (view, viewParams) {
        var view = viewParams.view;
        var params = _.omit(viewParams, 'view');
        params.server = false;
        params.inAnimated = true;
        app.state.isServer = false;

        if (viewParams.apiUrl) {
            params.viewData = {
                apiUrl: viewParams.apiUrl
            };
        }

        app.state.view.update(view, params);
    },


    isSectionLogic: function (prevView, view) {
        return app.state.prevView instanceof Section && app.state.prevView.constructor === view;
    },

    navigateBack: function () {
        Backbone.history.navigate(this.history[this.history.length - 1], {trigger: 'true'});
        this.history.pop(); //pop, after Backbone.history.navigate onroute listener add return url to history stack, remove it
    },

    start: function () {
        var is404 = app.els.$body.hasClass('Page404');
        var pushStateSupported = history && _.isFunction(history.pushState);


        this.history = [];

        this.listenTo(this, 'route', function (name, args) {
            //do not store popup urls in history, to force popup close to underlying page (even if there is a sequence of opened popups, especially years on awards)
            if (/Popup$/.test(name)) return;

            this.history.push(Backbone.history.fragment);
        }.bind(this));

        Backbone.history.start({pushState: pushStateSupported, silent: is404});

        if (is404) {
            this.notFound();
        }
    }
});
