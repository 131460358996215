var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/TeamMembers/TeamMembersFiltersMobile.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"TeamMembers-filtersMobile ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"isActiveByDefault")) {
output += " active ";
;
}
output += "\">\n    <div class=\"TeamMembers-close ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"isTeamFilterHide"), env.opts.autoescape);
output += "\">\n        <div class=\"TeamMembers-closeItem TeamMembers-closeItem--1\"></div>\n        <div class=\"TeamMembers-closeItem TeamMembers-closeItem--2\"></div>\n    </div>\n    ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"isTeamFilterHide")) {
output += "\n        <div class=\"TeamMembers-filterMobile\" id=\"teamMembersTeamFilter\">\n            <div class=\"TeamMembers-filterTitleMobile\">team</div>\n            <div class=\"TeamMembers-filterOptionsMobile\">\n                ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"teamsList");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                    <div class=\"TeamMembers-filterOptionMobile ";
if(runtime.memberLookup((t_4),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"id")) {
output += " active";
;
}
output += "\">\n                        <div class=\"TeamMembers-radioButtonWrapper\">\n                            <input data-option-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\" type=\"radio\"\n                                   id=\"teamMembersTeamRadioButton-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\" name=\"team\"\n                                   ";
if(runtime.memberLookup((t_4),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"id")) {
output += "checked";
;
}
output += "/>\n                            <div class=\"TeamMembers-radioButton\">\n                            </div>\n                            <label for=\"teamMembersTeamRadioButton-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "</label>\n                        </div>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n    ";
;
}
output += "\n    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"isPositionFilterNeeded") == 1) {
output += "\n        <div class=\"TeamMembers-filterMobile\" id=\"teamMembersPositionFilter\">\n            <div class=\"TeamMembers-filterTitleMobile\">position</div>\n            <div class=\"TeamMembers-filterOptionsMobile\">\n                ";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"positionsList");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("item", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                    <div class=\"TeamMembers-filterOptionMobile ";
if(runtime.memberLookup((t_8),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"position")),"id")) {
output += " active";
;
}
output += "\">\n                        <div class=\"TeamMembers-radioButtonWrapper\">\n                            <input data-option-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"id"), env.opts.autoescape);
output += "\" type=\"radio\" id=\"teamMembersPositionRadioButton-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\" name=\"position\"\n                                   ";
if(runtime.memberLookup((t_8),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"position")),"id")) {
output += "checked";
;
}
output += "/>\n                            <div class=\"TeamMembers-radioButton\">\n                            </div>\n                            <label for=\"teamMembersPositionRadioButton-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"value"), env.opts.autoescape);
output += "</label>\n                        </div>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n    ";
;
}
output += "\n    <div class=\"TeamMembers-filterMobile\" id=\"teamMembersRegionsFilter\">\n        <div class=\"TeamMembers-filterTitleMobile\">region</div>\n        <div class=\"TeamMembers-filterOptionsMobile\">\n            ";
frame = frame.push();
var t_11 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"regionsList");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n                <div class=\"TeamMembers-filterOptionMobile ";
if(runtime.memberLookup((t_12),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"region")),"id")) {
output += " active";
;
}
output += "\">\n                    <div class=\"TeamMembers-radioButtonWrapper\">\n                        <input data-option-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"id"), env.opts.autoescape);
output += "\" type=\"radio\"\n                               id=\"teamMembersRegionRadioButton-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\" name=\"region\"\n                               ";
if(runtime.memberLookup((t_12),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"region")),"id")) {
output += "checked";
;
}
output += "/>\n                        <div class=\"TeamMembers-radioButton\">\n                        </div>\n                        <label for=\"teamMembersRegionRadioButton-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_12),"value"), env.opts.autoescape);
output += "</label>\n                    </div>\n                </div>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n    </div>\n    ";
output += "\n    <button class=\"TeamMembers-applyFilterMobile\">apply and filter</button>\n\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/TeamMembers/TeamMembersFiltersMobile.jinja"] , dependencies)