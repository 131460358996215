var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/ContactBlock/ContactBlockLocation.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"ContactBlock-location\">\n    <div class=\"ContactBlock-locationTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"title"), env.opts.autoescape);
output += "</div>\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"address")) {
output += "\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"link")) {
output += "\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"link"), env.opts.autoescape);
output += "\" class=\"ContactBlock-locationAddress\" target=\"_blank\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"address")), env.opts.autoescape);
output += "</a>\n        ";
;
}
else {
output += "\n            <div class=\"ContactBlock-locationAddress\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"address")), env.opts.autoescape);
output += "</div>\n        ";
;
}
output += "\n    ";
;
}
output += "\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"email") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"phone")) {
output += "\n        <div class=\"ContactBlock-locationContacts\">\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"email")) {
output += "<a href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"email"), env.opts.autoescape);
output += "\" class=\"ContactBlock-locationContactsEmail\">Email</a>";
;
}
output += "\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"phone")) {
output += "<a href=\"tel:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"phone"), env.opts.autoescape);
output += "\" class=\"ContactBlock-locationContactsPhone\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "location")),"phone"), env.opts.autoescape);
output += "</a>";
;
}
output += "\n        </div>\n    ";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/ContactBlock/ContactBlockLocation.jinja"] , dependencies)