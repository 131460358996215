var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var Section = require('front/pages/Section/Section');

var GrowingTalant = require('front/components/GrowingTalant/GrowingTalant');
var SearchOurExpertise = require('front/components/SearchOurExpertise/SearchOurExpertise');
var SearchClientDashboard = require('front/components/SearchClientDashboard/SearchClientDashboard');
var Nutshell = require('front/components/Nutshell/Nutshell');
var TeamMembers = require('front/components/TeamMembers/TeamMembers');
var IndexNews = require('front/components/IndexNews/IndexNews');
// var FooterNav = require('front/components/FooterNav/FooterNav');

require('./SearchSection.less');


module.exports = Section.extend({
    template: require('./SearchSection.jinja'),

    el: '.SearchSection',

    // apiUrl: Urls['front:api:search'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:search']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        window.app.views.searchExtraMenu.initSearchExtraMenu(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        Section.prototype.render.call(this);

        // set data to window.app.data if navigate was without page reload
        window.app.data.logos = this.data.searchNutshell && this.data.searchNutshell.logos;
        window.app.data.team = 'development';

        this.setClass('SearchPage');
        this.setTitle('true • search: overview');
        this.activateLogic();

        if (window.app.data.isFromSubmenu) {
            var offsetTopWrapper = this.$('.Content-wrapper')
                .offset().top;

            setTimeout(function () {
                $(window)
                    .scrollTop(offsetTopWrapper);
                window.app.data.isFromSubmenu = false;
            });
        }

        window.app.views.footerNav.setMarginItems();
    },

    viewsRegistration: function () {
        this.registerView('growingTalant', new GrowingTalant());
        this.registerView('searchOurExpertise', new SearchOurExpertise());
        this.registerView('searchClientDashboard', new SearchClientDashboard());
        this.registerView('searchNutshell', new Nutshell());
        // this.registerView('searchTeamMembers', new TeamMembers({team: 'search'}));
        this.registerView('searchNews', new IndexNews());
        // this.registerView('footerNav', new FooterNav());
    }
});
