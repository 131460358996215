var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

require('./TopNav.less');

module.exports = Base.extend({
    template: require('./TopNav.jinja'),

    el: '.TopNav',

    events: {
        'click .TopNav-sideButton': 'clickSideButton'
    },

    SideTopNavModifier: 'TopNav--sideTopNav',

    initialize: function () {
        _.bindAll(this, 'closeSideNav');

        this.isVisible = false;

        if (window.app.settings.isMobile) {
            this.scrollTop = 0;
            var self = this;
            if ($(window)
                .scrollTop() - 50 > 0) {
                this.$el.addClass('isOnBackground');
                this.isOnBackground = true;
            }
            document.addEventListener('scroll', function () {
                if (!window.app.settings.scrollingProgrammaticaly) {
                    var scrollTop = $(window)
                        .scrollTop();
                    var scrollDirection = scrollTop > self.scrollTop ? 1 : -1;
                    if (Math.abs(self.scrollTop - scrollTop) > 50) {
                        self.scrollTop = scrollTop;

                        if (scrollTop - 50 > 0) {
                            if (scrollDirection === 1) {
                                self.$el.addClass('isHidden');
                                self.isHidden = true;
                            } else {
                                self.$el.removeClass('isHidden');
                                self.isHidden = false;
                                self.$el.addClass('isOnBackground');
                                self.isOnBackground = true;
                            }
                        } else {
                            self.$el.removeClass('isHidden');
                            self.$el.removeClass('isOnBackground');
                            self.isHidden = false;
                            self.isOnBackground = false;
                        }
                    }
                }
            });
        }
    },

    clickSideButton: function () {
        this.$el.toggleClass(this.SideTopNavModifier);

        if (!window.app.settings.isMobile) {
            app.views.sideNav.toggleVisibility();
        } else {
            this.isVisible = !this.isVisible;
            app.views.sideNavMobile.toggleVisibility();
            if (this.isVisible) {
                this.$el.removeClass('isHidden');
                this.$el.removeClass('isOnBackground');
            } else {
                if (this.isOnBackground) {
                    this.$el.addClass('isOnBackground');
                }
            }
        }
    },

    closeSideNav: function (isFromAnotherPage) {
        this.$el.removeClass(this.SideTopNavModifier);

        if (!window.app.settings.isMobile) {
            app.views.sideNav.hide(isFromAnotherPage);
        } else {
            app.views.sideNavMobile.hide();
        }
    }
});
