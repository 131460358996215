var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var NewsItem = require('front/components/NewsItem/NewsItem');
var NewsItemTemplate = require('front/components/NewsItem/NewsItem.jinja');

require('./NewsList.less');


module.exports = Base.extend({
    template: require('./NewsList.jinja'),

    el: '.NewsList',

    events: {
        'click .NewsList-showMore': 'onClickMoreButton'
    },

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'NewsItem': {selector: '.NewsItem', viewConstructor: NewsItem}
    },

    initialize: function (options) {
        _.bindAll(this, 'onClickMoreButton', 'moreApi');

        this.state = {
            next: Urls['front:api:news-list']() + '?offset=' + this.$('.NewsList-list .NewsItem').length,
            hasMore: false
        };

        Base.prototype.initialize.call(this, options);
    },

    moreApi: function () {
        return this.state.next;
    },

    onClickMoreButton: function () {
        var self = this;
        this.$('.NewsList-showMore')
            .addClass('loading');

        this.forceLoadData(this.moreApi)
            .then(function () {
                self.state.next = self.data.next;
                var newsList = [];
                if (self.data.results) {
                    for (var i = 0; i < self.data.results.length; i++) {
                        var newsItem = NewsItemTemplate.render({item: self.data.results[i]});
                        newsList.push(newsItem);
                    }
                }
                self.state.hasMore = !!self.data.next;

                self.$('.NewsList-showMore')
                    .toggle(!!self.state.hasMore)
                    .removeClass('loading');

                for (var i = 0; i < newsList.length; i++) {
                    self.$('.NewsList-list')
                        .append(newsList[i]);
                }

                // app.animateShowMoreSlideDown({$items: $archives});

                $(window)
                    .trigger('resize');

                new NewsItem();

                // window.app.utils.updateLinksAttributes(self.$('.PerspectiveArchivesList-archives'));

                // objectFitImages();
            });
    }
});
