var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./PrivacyPolicyBlock.less');


module.exports = Base.extend({
    template: require('./PrivacyPolicyBlock.jinja'),

    el: '.PrivacyPolicyBlock',

    initialize: function () {
    }
});