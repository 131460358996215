var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./SearchUniversalBlock.less');


module.exports = Base.extend({
    template: require('./SearchUniversalBlock.jinja'),

    el: '.SearchUniversalBlock',

    events: {
        'click .SearchUniversalBlock-viewTeam': 'setTeamToShow'
    },

    initialize: function (options) {
        Base.prototype.initialize.call(this, options);
    },

    setTeamToShow: function (e) {
        var id = +$(e.currentTarget).data('id');

        window.app.data.teamFilterValues.team = 'search';
        window.app.data.teamFilterValues.position = id;

        window.app.data.teamFilterValues.isCustomTeam = true;
        window.app.data.teamFilterValues.isCustomPosition = true;
    }
});