var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/TeamMembers/TeamMembersFilters.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"TeamMembers-filters\">\n    ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"isTeamFilterHide")) {
output += "\n        <div class=\"TeamMembers-filter\" id=\"teamMembersTeamFilter\">\n            <div class=\"TeamMembers-filterOptions\">\n                ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"teamsList");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                    <div class=\"TeamMembers-filterOption ";
if(runtime.memberLookup((t_4),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"id")) {
output += " active";
;
}
output += "\"\n                         data-option-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\">\n                        <span>";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "</span>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n            <div class=\"TeamMembers-filterValue\">\n                <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"value"), env.opts.autoescape);
output += "</span>\n                <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 17\">\n                    <path fill-rule=\"evenodd\"\n                          d=\"M29.985 2.845l-14.141 14.14-.844-.843-.844.843L.015 2.845 2.842.014 15 12.171 27.158.014l2.827 2.831z\"/>\n                </svg>\n            </div>\n        </div>\n    ";
;
}
output += "\n    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"team")),"isPositionFilterNeeded") == 1) {
output += "\n        <div class=\"TeamMembers-filter\" id=\"teamMembersPositionFilter\">\n            <div class=\"TeamMembers-filterOptions\">\n                ";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"positionsList");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("item", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                    <div class=\"TeamMembers-filterOption ";
if(runtime.memberLookup((t_8),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"position")),"id")) {
output += " active";
;
}
output += "\"\n                         data-option-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"id"), env.opts.autoescape);
output += "\">\n                        <span>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"value"), env.opts.autoescape);
output += "</span>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n            <div class=\"TeamMembers-filterValue\">\n                <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"position")),"value"), env.opts.autoescape);
output += "</span>\n                <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 17\">\n                    <path fill-rule=\"evenodd\"\n                          d=\"M29.985 2.845l-14.141 14.14-.844-.843-.844.843L.015 2.845 2.842.014 15 12.171 27.158.014l2.827 2.831z\"/>\n                </svg>\n            </div>\n        </div>\n    ";
;
}
output += "\n    <div class=\"TeamMembers-filter\" id=\"teamMembersRegionsFilter\">\n        <div class=\"TeamMembers-filterOptions\">\n            ";
frame = frame.push();
var t_11 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"regionsList");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n                <div class=\"TeamMembers-filterOption ";
if(runtime.memberLookup((t_12),"id") == runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"region")),"id")) {
output += " active";
;
}
output += "\"\n                     data-option-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"id"), env.opts.autoescape);
output += "\">\n                    <span>";
output += runtime.suppressValue(runtime.memberLookup((t_12),"value"), env.opts.autoescape);
output += "</span>\n                </div>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n        <div class=\"TeamMembers-filterValue\">\n            <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")),"region")),"value"), env.opts.autoescape);
output += "</span>\n            <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 17\">\n                <path fill-rule=\"evenodd\"\n                      d=\"M29.985 2.845l-14.141 14.14-.844-.843-.844.843L.015 2.845 2.842.014 15 12.171 27.158.014l2.827 2.831z\"/>\n            </svg>\n        </div>\n    </div>\n\n    <div class=\"TeamMembers-searchWrapper\">\n        <div class=\"TeamMembers-search\">\n            <label for=\"search-input\" class=\"TeamMembers-searchIcon\">\n                <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 49.06 49.062\">\n                    <path d=\"M8100,400a20,20,0,1,1-20,20A20,20,0,0,1,8100,400Zm0.08,4a16.013,16.013,0,1,1-15.77,16.011A15.892,15.892,0,0,1,8100.08,404Zm14.85,33.754,2.83-2.829,11.31,11.314-2.83,2.829Z\"\n                          fill-rule=\"evenodd\" transform=\"translate(-8080 -400)\"/>\n                </svg>\n            </label>\n            <input id=\"search-input\" type=\"text\" class=\"TeamMembers-searchInput\" placeholder=\"search by name\"/>\n        </div>\n    </div>\n\n    <div class=\"TeamMembers-clearFilters\" ";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"showClearFilters")) {
output += "style=\"display: none\"";
;
}
output += ">clear filters</div>\n\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/TeamMembers/TeamMembersFilters.jinja"] , dependencies)