var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var Section = require('front/pages/Section/Section');

var GrowingTalant = require('front/components/GrowingTalant/GrowingTalant');
var HistoryOfInnovation = require('front/components/HistoryOfInnovation/HistoryOfInnovation');
var IndexNews = require('front/components/IndexNews/IndexNews');
// var FooterNav = require('front/components/FooterNav/FooterNav');

require('./TechnologiesSection.less');


module.exports = Section.extend({
    template: require('./TechnologiesSection.jinja'),

    el: '.TechSection',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'GrowingTalant': {selector: '.GrowingTalant', viewConstructor: GrowingTalant},
        'HistoryOfInnovation': {selector: '.HistoryOfInnovation', viewConstructor: HistoryOfInnovation},
        'IndexNews': {selector: '.IndexNews', viewConstructor: IndexNews}
        // 'FooterNav': {selector: '.FooterNav', viewConstructor: FooterNav}
    },

    // apiUrl: Urls['front:api:technologies'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:technologies']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);

        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        this.setClass('TechPage');
        this.setTitle('true • technologies');
        Section.prototype.render.call(this);

        window.app.views.footerNav.setMarginItems();
    }
});