var _ = require('underscore/underscore');
var SubSection = require('front/pages/Section/SubSection');
var Backbone = require('backbone');

var Urls = require('django_js_front/reverse.js');

var Clock = require('front/components/FooterClockItem/FooterClockItem');
var ClockTemplate = require('front/components/FooterClockItem/FooterClockItem.jinja');

require('./TeamMemberPopup.less');


module.exports = SubSection.extend({
    el: '.TeamMemberPopup',

    template: require('./TeamMemberPopup.jinja'),

    events: {
        'click .TeamMemberPopup-close': 'close',
        'click .TeamMemberPopup-bg': 'close',
        'mouseenter .TeamMemberPopup-headerImagesImg': 'hoverCardStat',
        'click #shareLink': 'activateShareDropdown'
    },

    apiUrl: function () {
        return Urls['front:api:team-detail'](this.slug);
    },

    initialize: function (options) {
        this.options = options || {};

        SubSection.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic', 'triggerPopupShown', 'triggerPopupHidded', 'initClock', 'renderClock');

        this.slug = this.options.slug;

        if (this.options.server) {
            this.activateLogic(true);

            this.data = {}; //prevent api call for data when opens from direct url
        }

        $(document)
            .on('click', this.deactivateShareDropdown);
    },

    renderData: function () {
        this.setTitle(' — ' + this.data.name, true);

        return {
            member: this.data || {}
        };
    },

    initClock: function (slug) {
        var memberLocation = window.app.data.memberLocation;

        if (memberLocation) {
            this.renderClock(memberLocation);
            new Clock({el: '.TeamMemberPopup-clockItem', clock: memberLocation});
        }
    },

    renderClock: function (location) {
        var html = ClockTemplate.render({
            officeList: [location],
            ignoreTooltip: true
        });

        this.$('.TeamMemberPopup-clockItem')
            .html(html);
    },

    activateLogic: function (isServer) {
        this.$el.hide();
        this.$el.addClass('TeamMemberPopup--visible');
        this.$el.show();
        this.$el.outerHeight();
        this.$('.TeamMemberPopup-content')
            .scrollTop(0);
        this.$('.TeamMemberPopup-content')
            .focus(); //for pgdn pgup shortcuts

        var self = this;
        if (isServer) {
            setTimeout(function () {
                self.$el.find('.TeamMemberPopup-wrapper')
                    .removeClass('overflowLock');
                self.triggerPopupShown();
            }.bind(this), this.TIME_ANIMATION);
        } else {
            this.$el.find('.TeamMemberPopup-wrapper')
                .removeClass('overflowLock');
            this.triggerPopupShown();
        }
        window.app.views.extraMenu.initExtraMenu(this.$el);
    },

    hoverCardStat: function (e) {
        var $item = $(e.currentTarget);
        this.$el.find('.TeamMemberPopup-headerImagesImg.isActive')
            .removeClass('isActive');
        $item.addClass('isActive');
    },

    triggerPopupShown: function () {
        window.app.views.extraMenu.fixMenu();

        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger('PopupShown');
        // window.app.views.nav.toggleClose(this.rootUrl);
        $(window)
            .scrollTop(0);

        this.initClock(this.slug);
    },

    triggerPopupHidded: function () {
        window.app.views.extraMenu.unFixMenu();

        app.vent.trigger('PopupHidded');
        $(window)
            .scrollTop(this.savedScroll);
    },

    close: function () {
        this.setTitle(this.titleBefore);
        app.router.navigateBack();
    },

    // render: function () {
    //     SubSection.prototype.render.call(this);
    //
    //     if (this.options.server) this.activateLogic();
    // },

    activate: function (params) {

        return SubSection.prototype.activate.call(this, params)
            .then(function () {
                this.$content = this.$('.TeamMemberPopup-content');
                this.$content.off('scroll', this.onScroll);
                this.$content.on('scroll', this.onScroll);
            }.bind(this));
    },

    playIn: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            self.$el.hide();
            self.$el.show();
            self.$el.addClass('TeamMemberPopup--visible');
            self.$el.outerHeight();
            // self.$el.find('.TeamMemberPopup-wrapper').removeClass('overflowLock');
            self.triggerPopupShown();
            resolve();
        });
    },

    playOut: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            // self.$el.find('.TeamMemberPopup-wrapper').addClass('overflowLock');
            self.$el.removeClass('TeamMemberPopup--visible');
            // window.app.views.nav.toggleClose(this.rootUrl);
            setTimeout(function () {
                self.triggerPopupHidded();
                resolve();
            }, self.TIME_ANIMATION);
        });
    },

    destroy: function () {
        return SubSection.prototype.destroy.call(this);
    },

    deactivate: function (params) {

        this.triggerPopupHidded();

        return SubSection.prototype.deactivate.call(this, params);
    },

    render: function () {
        // set data to window.app
        window.app.data.memberLocation = this.data && this.data.location;

        SubSection.prototype.render.call(this);
        // this.activateLogic();
    }

});
