var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./OptOutGuideBlock.less');


module.exports = Base.extend({
    template: require('./OptOutGuideBlock.jinja'),

    el: '.OptOutGuideBlock',

    initialize: function () {
    }
});
