var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/CommonItemShape/CommonItemShape.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/CommonItemShape/CommonItemShape.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "isTeam", "isSearch", "isGray"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("isTeam", Object.prototype.hasOwnProperty.call(kwargs, "isTeam") ? kwargs["isTeam"] : false);frame.set("isSearch", Object.prototype.hasOwnProperty.call(kwargs, "isSearch") ? kwargs["isSearch"] : false);frame.set("isGray", Object.prototype.hasOwnProperty.call(kwargs, "isGray") ? kwargs["isGray"] : false);var t_5 = "";t_5 += "\n    ";
var t_6;
t_6 = "";
frame.set("suff", t_6, true);
if(frame.topLevel) {
context.setVariable("suff", t_6);
}
if(frame.topLevel) {
context.addExport("suff", t_6);
}
t_5 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "isGray")) {
t_5 += "\n        ";
var t_7;
t_7 = "-gray";
frame.set("suff", t_7, true);
if(frame.topLevel) {
context.setVariable("suff", t_7);
}
if(frame.topLevel) {
context.addExport("suff", t_7);
}
t_5 += "\n    ";
;
}
t_5 += "\n    <div class=\"CommonItemShape";
if(runtime.contextOrFrameLookup(context, frame, "isTeam")) {
t_5 += " isTeam";
;
}
if(runtime.contextOrFrameLookup(context, frame, "isSearch")) {
t_5 += " isSearch";
;
}
t_5 += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "isGray")) {
t_5 += " isGray";
;
}
t_5 += "\">\n        <div class=\"CommonItemShape-header\">\n            <div class=\"CommonItemShape-headerSidebar mobVis\"></div>\n            <div class=\"CommonItemShape-headerImg\">\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"shape")) {
t_5 += "<img src=\"";
t_5 += runtime.suppressValue((lineno = 13, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/shapes/item-" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"shape") + runtime.contextOrFrameLookup(context, frame, "suff") + ".png"])), env.opts.autoescape);
t_5 += "\">\n                ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"img")) {
t_5 += "<img src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"img"), env.opts.autoescape);
t_5 += "\">";
;
}
;
}
t_5 += "\n            </div>\n            <div class=\"CommonItemShape-headerSidebar\"></div>\n        </div>\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")) {
t_5 += "\n            <div class=\"CommonItemShape-body\">\n                <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"url"), env.opts.autoescape);
t_5 += "\"";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"tab")) {
t_5 += " target=\"_blank\" ";
;
}
t_5 += " class=\"CommonItemShape-link\">\n                    <div class=\"CommonItemShape-title\">\n                        ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title")), env.opts.autoescape);
t_5 += "\n                    </div>\n                    <div class=\"CommonItemShape-descr\">\n                        ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"text")), env.opts.autoescape);
t_5 += "\n                    </div>\n                    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"cta")) {
t_5 += "<a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"url"), env.opts.autoescape);
t_5 += "\"";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"tab")) {
t_5 += " target=\"_blank\" ";
;
}
t_5 += " class=\"CommonItemShape-cta\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"cta"), env.opts.autoescape);
t_5 += "</a>\n                    ";
;
}
else {
t_5 += "<div class=\"CommonItemShape-arrow\">\n                            ";
t_5 += runtime.suppressValue((lineno = 31, colno = 52, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_8 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_9 = "";;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
return macro_t_8;})()})])), env.opts.autoescape);
t_5 += "\n                        </div>\n                    ";
;
}
t_5 += "</a>\n            </div>\n        ";
;
}
else {
t_5 += "\n            <div class=\"CommonItemShape-body\">\n                <div class=\"CommonItemShape-title\">\n                    ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title")), env.opts.autoescape);
t_5 += "\n                </div>\n                <div class=\"CommonItemShape-descr\">\n                    ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"text")), env.opts.autoescape);
t_5 += "\n                </div>\n            </div>\n        ";
;
}
t_5 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("CommonItemShape");
context.setVariable("CommonItemShape", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/CommonItemShape/CommonItemShape.jinja"] , dependencies)