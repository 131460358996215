var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./CommonItemShape.less');


module.exports = Base.extend({
    template: require('./CommonItemShape.jinja'),

    el: '.CommonItemShape',

    initialize: function (options) {
        _.bindAll(this, 'onResize', 'setMarginItems');

        Base.prototype.initialize.call(this, options);

        this.onResize.__throttle = _.throttle(this.onResize, 50);
        $(window).on('resize', this.onResize.__throttle);
        this.onResize.__throttle();
    },

    onResize: function () {
        this.setMarginItems();
    },

    setMarginItems: function () {
        if (window.app.settings.isMobile) return;

        var maxHeight = 0;
        var maxHeightTitle = 0;

        $('.CommonItemShape').each(function (id, item) {
            var h = $(item)
                .find('.CommonItemShape-descr')
                .innerHeight();

            var hTitle = $(item)
                .find('.CommonItemShape-title')
                .innerHeight();

            if (maxHeight < h) maxHeight = h;
            if (maxHeightTitle < hTitle) maxHeightTitle = hTitle;
        });

        $('.CommonItemShape').each(function (id, item) {
            var m = maxHeight - $(item).find('.CommonItemShape-descr')
                .innerHeight() + 15;

            var mDescr = maxHeightTitle - $(item).find('.CommonItemShape-title')
                .innerHeight() + 46;

            $(item)
                .find('.CommonItemShape-arrow')
                .css('margin-top', m + 'px');

            $(item)
                .find('.CommonItemShape-cta')
                .css('margin-top', m + 'px');

            $(item)
                .find('.CommonItemShape-descr')
                .css('margin-top', mDescr + 'px');
        });
    }
});
