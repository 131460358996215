var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./IndexOurStory.less');


module.exports = Base.extend({
    template: require('./IndexOurStory.jinja'),

    el: '.IndexOurStory',

    initialize: function () {

    }
});
