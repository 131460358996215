var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/SearchClientDashboard/SearchClientDashboard.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/SearchClientDashboard/SearchClientDashboard.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <div class=\"SearchClientDashboard\">\n        <div class=\"SearchClientDashboard-sidebar leftCirclePoint\"></div>\n        <div class=\"SearchClientDashboard-body\">\n            <div class=\"SearchClientDashboard-header\"></div>\n            <div class=\"SearchClientDashboard-title SearchClientDashboard-titleDesktop\">\n                ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_5 += "\n            </div>\n            <div class=\"SearchClientDashboard-bodyWrapper\">\n                <div class=\"SearchClientDashboard-section\">\n                    <div class=\"SearchClientDashboard-sectionHeader\"></div>\n                    <div class=\"SearchClientDashboard-flexFillerMobile\"></div>\n                    <div class=\"SearchClientDashboard-sectionBlock\">\n                        <img src=\"";
t_5 += runtime.suppressValue((lineno = 15, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/shapes/item-lock-2.png"])), env.opts.autoescape);
t_5 += "\">\n                    </div>\n                    <div class=\"SearchClientDashboard-flexFillerMobile\"></div>\n                </div>\n                <div class=\"SearchClientDashboard-flexFiller\"></div>\n                <div class=\"SearchClientDashboard-info\">\n                    <div class=\"SearchClientDashboard-descr\">\n                        ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"descr")), env.opts.autoescape);
t_5 += "\n                    </div>\n                    <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link"), env.opts.autoescape);
t_5 += "\" target=\"_blank\" class=\"SearchClientDashboard-link\">\n                        client login\n                    </a>\n                </div>\n\n            </div>\n            <div class=\"SearchClientDashboard-footer\"></div>\n        </div>\n        <div class=\"SearchClientDashboard-sidebar SearchClientDashboard-sidebar--right\"></div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("SearchClientDashboard");
context.setVariable("SearchClientDashboard", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/SearchClientDashboard/SearchClientDashboard.jinja"] , dependencies)