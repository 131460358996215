var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

require('./SideNav.less');

module.exports = Base.extend({
    template: require('./SideNav.jinja'),

    el: '.SideNav',

    events: {
        'click .SideNav-wrapperClose': 'hide',
        'click .SideNav-link': 'openNewPage'
    },

    isOpen: 0,
    isNewPage: false,

    initialize: function (options) {
        _.bindAll(this, 'toggleVisibility', 'hide', 'triggerMenuShown', 'triggerMenuHidded', 'openNewPage');

        $('.SideNav-link')
            .hover(
                function (e) {
                    var $item = $(e.currentTarget);
                    var id = $item.data('id')
                        .toString();
                    $('.Content-backgroundItemImg[data-id="' + id + '"]')
                        .addClass('isHover');
                    $item.addClass('isHover');

                    $('.SideNav-wrapper')
                        .addClass(id)
                        .addClass('isHover');

                    $('.Content-body')
                        .addClass(id)
                        .addClass('isHover');

                    // $('.Content-backgroundOverlayMenu')
                    //     .addClass(id)
                    //     .addClass('isHover');

                    $('.Content-background')
                        .addClass(id)
                        .addClass('isHoverBackground');

                    $('.Content-backgroundOverlayMenu[data-id="' + id + '"]')
                        .addClass('isHover');

                    $('.Footer')
                        .addClass(id)
                        .addClass('isHover');

                }, function (e) {
                    var $item = $(e.currentTarget);
                    var id = $item.data('id')
                        .toString();
                    $('.Content-backgroundItemImg[data-id="' + id + '"]')
                        .removeClass('isHover');
                    $item.removeClass('isHover');

                    $('.SideNav-wrapper')
                        .removeClass(id)
                        .removeClass('isHover');

                    // $('.Content-backgroundOverlayMenu')
                    //     .removeClass(id)
                    //     .removeClass('isHover');

                    $('.Content-body')
                        .removeClass(id)
                        .removeClass('isHover');

                    $('.Content-background')
                        .removeClass(id)
                        .removeClass('isHoverBackground');

                    $('.Content-backgroundOverlayMenu[data-id="' + id + '"]')
                        .removeClass('isHover');

                    $('.Footer')
                        .removeClass(id)
                        .removeClass('isHover');
                }
            );

        Base.prototype.initialize.call(this, options);
    },

    openNewPage: function (e) {
        var $item = $(e.currentTarget);
        var id = $item.data('id')
            .toString();
        $('.SideNav-link.isActive')
            .removeClass('isActive');

        if (id == 'true') {
            this.setClass('TruePage IndexPage');
        } else if (id == 'search') {
            this.setClass('SearchPage');
        } else if (id == 'dev') {
            this.setClass('DevPage');
        } else if (id == 'tech') {
            this.setClass('TechPage');
        }

        $item.addClass('isActive');
        $('.SideNav-overlay')
            .addClass(id + ' isActive');
        setTimeout(function () {
            $('.SideNav-overlay')
                .removeClass(id + ' isActive');
        }, 400);
        // $('.Content-backgroundItem img.isActive').removeClass('isActive');
        // $('.Content-backgroundItem img[data-id="' + id + '"]').addClass('isActive');
    },

    toggleVisibility: function () {
        this.isOpen = 1 - this.isOpen;
        this.$el.toggleClass('SideNav--visible');
        app.els.$contentBackground.toggleClass('isOpenMenu');
        app.els.$contentBackgroundOverlayMenu.toggleClass('isOpenMenu');
        app.els.$content.toggleClass('isOpenMenu');
        app.els.$footer.toggleClass('isOpenMenu');
        app.els.$extraMenu.toggleClass('isOpenMenu');
        app.els.$searchExtraMenu.toggleClass('isOpenMenu');

        var self = this;


        if (this.isOpen) {
            this.triggerMenuShown();
        } else {
            this.triggerMenuHidded();
        }

        $('.Content')
            .click(function (e) {
                if (!($(e.target)
                    .closest('.SideNav-link').length > 0) && (!($(e.target)
                    .closest('.TopNav-sideButton').length > 0))) {
                    self.hide();
                }
            });
    },

    hide: function (isFromAnotherPage) {
        this.$el.removeClass('SideNav--visible');
        app.els.$contentBackground.removeClass('isOpenMenu');
        app.els.$contentBackgroundOverlayMenu.removeClass('isOpenMenu');
        app.els.$content.removeClass('isOpenMenu');
        app.els.$footer.removeClass('isOpenMenu');
        app.els.$extraMenu.removeClass('isOpenMenu');
        app.els.$searchExtraMenu.removeClass('isOpenMenu');
        $('.TopNav--sideTopNav')
            .removeClass('TopNav--sideTopNav');
        $('.Content')
            .off('click');
        this.isOpen = 0;
        this.triggerMenuHidded(isFromAnotherPage);
    },

    triggerMenuShown: function () {
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger('PopupShown');
        $(window)
            .scrollTop(0);
        app.els.$content.css('z-index', 7);

        if (app.els.$contentContent.hasClass('SearchExtraPage')) {
            app.els.$content.css('opacity', 0);
        }

        app.els.$contentContent.addClass('overflowLock');
    },

    triggerMenuHidded: function (isFromAnotherPage) {
        app.vent.trigger('PopupHidded');
        if (!isFromAnotherPage) {
            $(window)
                .scrollTop(this.savedScroll);
        }
        app.els.$contentContent.removeClass('overflowLock');
        app.els.$content.css('opacity', '');

        setTimeout(function () {
            app.els.$content.css('z-index', 'inherit');
        }, 300);
    }
});
