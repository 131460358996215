var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/pages/Section/Section.jinja"] = require( "front/pages/Section/Section.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/ContactBlock/ContactBlock.jinja"] = require( "front/components/ContactBlock/ContactBlock.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Contact/ContactSection.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("front/pages/Section/Section.jinja", true, "assets/app/front/pages/Contact/ContactSection.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Contact/ContactSection.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n\n";
env.getTemplate("front/components/ContactBlock/ContactBlock.jinja", false, "assets/app/front/pages/Contact/ContactSection.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(context.getVariables(), frame, function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "ContactBlock")) {
var t_10 = t_7.ContactBlock;
} else {
cb(new Error("cannot import 'ContactBlock'")); return;
}
context.setVariable("ContactBlock", t_10);
output += "\n\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
output += t_11;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
output += t_13;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content_class"))(env, context, frame, runtime, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
output += t_15;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
output += t_17;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "true • contact";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <meta name=\"description\" content=\"\"/>\n\n    <meta property=\"og:type\" content=\"website\"/>\n    <meta property=\"og:image\" content=\"";
output += runtime.suppressValue((lineno = 13, colno = 68, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [(lineno = 13, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/headers/bg-true-team.jpg"]))])), env.opts.autoescape);
output += "\"/>\n    <meta property=\"og:image:width\" content=\"1440\"/>\n    <meta property=\"og:image:height\" content=\"640\"/>\n    <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 16, colno = 66, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n    <meta property=\"og:title\" content=\"true • contact\"/>\n    <meta property=\"og:description\" content=\"\"/>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content_class(env, context, frame, runtime, cb) {
var lineno = 21;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "ContactPage TruePage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 23;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"ContactSection PageSection\">\n        <div class=\"ContactSection-wrapper NeedFixOnPopup\">\n            <div class=\"Content-outter isInitialState\">\n                <div class=\"Content-wrapper\">\n                    ";
output += runtime.suppressValue((lineno = 28, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ContactBlock"), "ContactBlock", context, [runtime.contextOrFrameLookup(context, frame, "contact")])), env.opts.autoescape);
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 35;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_21,hole_35) {
if(t_21) { cb(t_21); return; }
hole_35 = runtime.markSafe(hole_35);
output += "\n    ";
output += runtime.suppressValue(hole_35, env.opts.autoescape);
output += "\n\n    window.app.data.contact = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "contact"))), env.opts.autoescape);
output += "\n    window.app.data.contactLocations = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "locations"))), env.opts.autoescape);
output += "\n    window.app.data.contactDropdowns = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "dropdowns"))), env.opts.autoescape);
output += ";\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_social: b_social,
b_content_class: b_content_class,
b_content: b_content,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Contact/ContactSection.jinja"] , dependencies)