var _ = require('underscore/underscore');

var Base = require('front/components/Base/Base');
var Utils = require('front/utils/Utils');

module.exports = Base.extend({
    // Тип страницы, используется для поиска активного элемента меню
    type: '',

    // Заголовок страницы
    title: undefined,

    // Title of page before current
    titleBefore: undefined,

    TIME_ANIMATION: 400,

    initialize: function (options) {

        Base.prototype.initialize.call(this, options);

        _.bindAll(this, 'onScroll', 'onResize', 'onTriggerShowFooter', 'triggerStartAnimation', 'initTrueLetters',
            'initSearchLetters', 'initDevLetters', 'initTechLetters', 'setCookie', 'readCookie');

        window.app.vent.on('PageStartAnimation', this.triggerStartAnimation);
    },

    setCookie: function (name, value) {
        document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    },

    readCookie: function (name) {
        var nameEQ = encodeURIComponent(name) + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
        }
        return null;
    },

    setTitle: function (title, isInherit) {
        title = title || this.title;
        this.titleBefore = document.title;
        if (isInherit) {
            title = this.titleBefore + title;
        }
        if (title !== undefined) {
            document.title = (title ? $('<div>' + title + '</div>')
                .text() : 'true');
        }
    },

    setClass: function (className) {
        if (className !== undefined) {
            app.els.$contentContent.attr('class', 'Content ' + className);
        }
    },

    $parent: function () {
        return app.els.$content;
    },

    viewsRegistration: function () {
        Base.prototype.viewsRegistration.call(this);
    },

    activate: function (params) {
        this.setTitle();

        $(window)
            .on('resize', this.onResize)
            .on('scroll', this.onScroll);

        return Base.prototype.activate.call(this, params);
    },


    deactivate: function (params) {
        this.animatedBlocks = [];

        $(window)
            .off('resize', this.onResize)
            .off('scroll', this.onScroll);

        window.app.vent.off('PageStartAnimation', this.triggerStartAnimation);

        return Base.prototype.deactivate.call(this, params);
    },

    initTrueLetters: function () {
        if (window.app.settings.isMobile) return;

        var $letterT = this.$img.find('.Content-backgroundItemImgLetter--t');
        var $letterR = this.$img.find('.Content-backgroundItemImgLetter--r');
        var $letterU = this.$img.find('.Content-backgroundItemImgLetter--u');
        var $letterE = this.$img.find('.Content-backgroundItemImgLetter--e');

        var letters = ['T', 'R', 'U', 'E'];
        var $elsLetters = [$letterT, $letterR, $letterU, $letterE];

        // get percent of w and h at letters
        var percentOfLetters = {
            'T': {
                'w': 503 * 100 / 4320, // letter T
                'h': 556 * 100 / 2760
            },
            'R': {
                'w': 507 * 100 / 4320, // letter R
                'h': 633 * 100 / 2760
            },
            'U': {
                'w': 454 * 100 / 4320, // letter U
                'h': 703 * 100 / 2760
            },
            'E': {
                'w': 566 * 100 / 4320, // letter E
                'h': 507 * 100 / 2760
            }
        };

        // get aspect of w and h at letters
        var aspectOfLetters = {
            'T': {
                'w': 503 / 556, // letter T
                'h': 556 / 503
            },
            'R': {
                'w': 507 / 633, // letter R
                'h': 633 / 507
            },
            'U': {
                'w': 454 / 703, // letter U
                'h': 703 / 454
            },
            'E': {
                'w': 566 / 507, // letter E
                'h': 507 / 566
            }
        };

        //get percent of position at letters
        var percentPositionOfLetters = {
            'T': {
                'top': 1342 * 100 / 2760, // letter T
                'left': 1306 * 100 / 4320
            },
            'R': {
                'top': 1457 * 100 / 2760, // letter R
                'left': 1707 * 100 / 4320
            },
            'U': {
                'top': 1222 * 100 / 2760, // letter U
                'left': 2147 * 100 / 4320
            },
            'E': {
                'top': 1453 * 100 / 2760, // letter E
                'left': 2512 * 100 / 4320
            }
        };

        var sizeOfLetters = {
            'T': {
                'w': 0, // letter T
                'h': 0
            },
            'R': {
                'w': 0, // letter R
                'h': 0
            },
            'U': {
                'w': 0, // letter U
                'h': 0
            },
            'E': {
                'w': 0, // letter E
                'h': 0
            }
        };

        var positionOfLetters = {
            'T': {
                'top': 0,
                'left': 0
            },
            'R': {
                'top': 0,
                'left': 0
            },
            'U': {
                'top': 0,
                'left': 0
            },
            'E': {
                'top': 0,
                'left': 0
            }
        };

        if (this.aspectWindow >= this.aspectRatioImgW) {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].w = this.imgWidth * percentOfLetters[letters[i]].w / 100;
                sizeOfLetters[letters[i]].h = sizeOfLetters[letters[i]].w * aspectOfLetters[letters[i]].h;
            }

            this.sizeImgShouldH = this.imgWidth * this.aspectRatioImgH; // get size of original img which should be
        } else {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].h = this.imgHeight * percentOfLetters[letters[i]].h / 100;
                sizeOfLetters[letters[i]].w = sizeOfLetters[letters[i]].h * aspectOfLetters[letters[i]].w;
            }

            this.sizeImgShouldW = this.imgHeight * this.aspectRatioImgW; // get size of original img which should be
        }

        for (var i = 0; i < letters.length; i++) {
            positionOfLetters[letters[i]].top = (this.sizeImgShouldH * percentPositionOfLetters[letters[i]].top / 100) - ((this.sizeImgShouldH - this.imgHeight) / 2);
            positionOfLetters[letters[i]].left = (this.sizeImgShouldW * percentPositionOfLetters[letters[i]].left / 100) - ((this.sizeImgShouldW - this.imgWidth) / 2);
        }

        for (var i = 0; i < letters.length; i++) {
            $elsLetters[i].css({
                'width': sizeOfLetters[letters[i]].w + 'px',
                'height': sizeOfLetters[letters[i]].h + 'px',
                'top': positionOfLetters[letters[i]].top + 'px',
                'left': positionOfLetters[letters[i]].left + 'px'
            });
        }
    },

    initTechLetters: function () {
        if (window.app.settings.isMobile) return;

        var $letterT = this.$imgTech.find('.Content-backgroundItemImgLetter--technologies');

        var letters = ['T'];
        var $elsLetters = [$letterT];

        // get percent of w and h at letters
        var percentOfLetters = {
            'T': {
                'w': 1692 * 100 / 4320, // letter T
                'h': 634 * 100 / 2760
            }
        };

        // get aspect of w and h at letters
        var aspectOfLetters = {
            'T': {
                'w': 1692 / 634, // letter T
                'h': 634 / 1692
            }
        };

        //get percent of position at letters
        var percentPositionOfLetters = {
            'T': {
                'top': 1129 * 100 / 2760, // letter T
                'left': 1282 * 100 / 4320
            }
        };

        var sizeOfLetters = {
            'T': {
                'w': 0, // letter T
                'h': 0
            }
        };

        var positionOfLetters = {
            'T': {
                'top': 0,
                'left': 0
            }
        };

        if (this.aspectWindow >= this.aspectRatioImgW) {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].w = this.imgWidth * percentOfLetters[letters[i]].w / 100;
                sizeOfLetters[letters[i]].h = sizeOfLetters[letters[i]].w * aspectOfLetters[letters[i]].h;
            }

            this.sizeImgShouldH = this.imgWidth * this.aspectRatioImgH; // get size of original img which should be
        } else {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].h = this.imgHeight * percentOfLetters[letters[i]].h / 100;
                sizeOfLetters[letters[i]].w = sizeOfLetters[letters[i]].h * aspectOfLetters[letters[i]].w;
            }

            this.sizeImgShouldW = this.imgHeight * this.aspectRatioImgW; // get size of original img which should be
        }

        for (var i = 0; i < letters.length; i++) {
            positionOfLetters[letters[i]].top = (this.sizeImgShouldH * percentPositionOfLetters[letters[i]].top / 100) - ((this.sizeImgShouldH - this.imgHeight) / 2);
            positionOfLetters[letters[i]].left = (this.sizeImgShouldW * percentPositionOfLetters[letters[i]].left / 100) - ((this.sizeImgShouldW - this.imgWidth) / 2);
        }

        for (var i = 0; i < letters.length; i++) {
            $elsLetters[i].css({
                'width': sizeOfLetters[letters[i]].w + 'px',
                'height': sizeOfLetters[letters[i]].h + 'px',
                'top': positionOfLetters[letters[i]].top + 'px',
                'left': positionOfLetters[letters[i]].left + 'px'
            });
        }
    },

    initSearchLetters: function () {
        if (window.app.settings.isMobile) return;

        var $letterS = this.$imgSearch.find('.Content-backgroundItemImgLetter--s');
        var $letterE = this.$imgSearch.find('.Content-backgroundItemImgLetter--e');
        var $letterA = this.$imgSearch.find('.Content-backgroundItemImgLetter--a');
        var $letterR = this.$imgSearch.find('.Content-backgroundItemImgLetter--r');
        var $letterC = this.$imgSearch.find('.Content-backgroundItemImgLetter--c');
        var $letterH = this.$imgSearch.find('.Content-backgroundItemImgLetter--h');

        var letters = ['S', 'E', 'A', 'R', 'C', 'H'];
        var $elsLetters = [$letterS, $letterE, $letterA, $letterR, $letterC, $letterH];

        // get percent of w and h at letters
        var percentOfLetters = {
            'w': 575 * 100 / 4320,
            'h': 582 * 100 / 2760
        };

        // get aspect of w and h at letters
        var aspectOfLetters = {
            'w': 575 / 582,
            'h': 582 / 575
        };

        //get percent of position at letters
        var percentPositionOfLetters = {
            'S': {
                'top': 1225 * 100 / 2760,
                'left': 1364 * 100 / 4320
            },
            'E': {
                'top': 1225 * 100 / 2760,
                'left': 1554 * 100 / 4320
            },
            'A': {
                'top': 1225 * 100 / 2760,
                'left': 1764 * 100 / 4320
            },
            'R': {
                'top': 1225 * 100 / 2760,
                'left': 1961 * 100 / 4320
            },
            'C': {
                'top': 1225 * 100 / 2760,
                'left': 2185 * 100 / 4320
            },
            'H': {
                'top': 1225 * 100 / 2760,
                'left': 2345 * 100 / 4320
            }
        };

        var sizeOfLetters = {
            'S': {
                'w': 0,
                'h': 0
            },
            'E': {
                'w': 0,
                'h': 0
            },
            'A': {
                'w': 0,
                'h': 0
            },
            'R': {
                'w': 0,
                'h': 0
            },
            'C': {
                'w': 0,
                'h': 0
            },
            'H': {
                'w': 0,
                'h': 0
            }
        };

        var positionOfLetters = {
            'S': {
                'top': 0,
                'left': 0
            },
            'E': {
                'top': 0,
                'left': 0
            },
            'A': {
                'top': 0,
                'left': 0
            },
            'R': {
                'top': 0,
                'left': 0
            },
            'C': {
                'top': 0,
                'left': 0
            },
            'H': {
                'top': 0,
                'left': 0
            }
        };

        if (this.aspectWindow >= this.aspectRatioImgW) {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].w = this.imgWidth * percentOfLetters.w / 100;
                sizeOfLetters[letters[i]].h = sizeOfLetters[letters[i]].w * aspectOfLetters.h;
            }

            this.sizeImgShouldH = this.imgWidth * this.aspectRatioImgH; // get size of original img which should be
        } else {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].h = this.imgHeight * percentOfLetters.h / 100;
                sizeOfLetters[letters[i]].w = sizeOfLetters[letters[i]].h * aspectOfLetters.w;
            }

            this.sizeImgShouldW = this.imgHeight * this.aspectRatioImgW; // get size of original img which should be
        }

        for (var i = 0; i < letters.length; i++) {
            positionOfLetters[letters[i]].top = (this.sizeImgShouldH * percentPositionOfLetters[letters[i]].top / 100) - ((this.sizeImgShouldH - this.imgHeight) / 2);
            positionOfLetters[letters[i]].left = (this.sizeImgShouldW * percentPositionOfLetters[letters[i]].left / 100) - ((this.sizeImgShouldW - this.imgWidth) / 2);
        }

        for (var i = 0; i < letters.length; i++) {
            $elsLetters[i].css({
                'width': sizeOfLetters[letters[i]].w + 'px',
                'height': sizeOfLetters[letters[i]].h + 'px',
                'top': positionOfLetters[letters[i]].top + 'px',
                'left': positionOfLetters[letters[i]].left + 'px'
            });
        }
    },

    initDevLetters: function () {
        if (window.app.settings.isMobile) return;

        var $letterDEV = this.$imgDev.find('.Content-backgroundItemImgLetter--dev');
        var $letterELO = this.$imgDev.find('.Content-backgroundItemImgLetter--elo');
        var $letterOPM = this.$imgDev.find('.Content-backgroundItemImgLetter--opm');
        var $letterENT = this.$imgDev.find('.Content-backgroundItemImgLetter--ent');

        var letters = ['DEV', 'ELO', 'OPM', 'ENT'];
        var $elsLetters = [$letterDEV, $letterELO, $letterOPM, $letterENT];

        // get percent of w and h at letters
        var percentOfLetters = {
            'w': 1475 * 100 / 4320,
            'h': 753 * 100 / 2760
        };

        // get aspect of w and h at letters
        var aspectOfLetters = {
            'w': 1475 / 753,
            'h': 753 / 1475
        };

        //get percent of position at letters
        var percentPositionOfLetters = {
            'DEV': {
                'top': 1368 * 100 / 2760,
                'left': 1070 * 100 / 4320
            },
            'ELO': {
                'top': 1368 * 100 / 2760,
                'left': 1420 * 100 / 4320
            },
            'OPM': {
                'top': 1368 * 100 / 2760,
                'left': 1810 * 100 / 4320
            },
            'ENT': {
                'top': 1368 * 100 / 2760,
                'left': 2062 * 100 / 4320
            }
        };

        var sizeOfLetters = {
            'DEV': {
                'w': 0,
                'h': 0
            },
            'ELO': {
                'w': 0,
                'h': 0
            },
            'OPM': {
                'w': 0,
                'h': 0
            },
            'ENT': {
                'w': 0,
                'h': 0
            }
        };

        var positionOfLetters = {
            'DEV': {
                'top': 0,
                'left': 0
            },
            'ELO': {
                'top': 0,
                'left': 0
            },
            'OPM': {
                'top': 0,
                'left': 0
            },
            'ENT': {
                'top': 0,
                'left': 0
            }
        };

        if (this.aspectWindow >= this.aspectRatioImgW) {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].w = this.imgWidth * percentOfLetters.w / 100;
                sizeOfLetters[letters[i]].h = sizeOfLetters[letters[i]].w * aspectOfLetters.h;
            }

            this.sizeImgShouldH = this.imgWidth * this.aspectRatioImgH; // get size of original img which should be
        } else {
            for (var i = 0; i < letters.length; i++) {
                sizeOfLetters[letters[i]].h = this.imgHeight * percentOfLetters.h / 100;
                sizeOfLetters[letters[i]].w = sizeOfLetters[letters[i]].h * aspectOfLetters.w;
            }

            this.sizeImgShouldW = this.imgHeight * this.aspectRatioImgW; // get size of original img which should be
        }

        for (var i = 0; i < letters.length; i++) {
            positionOfLetters[letters[i]].top = (this.sizeImgShouldH * percentPositionOfLetters[letters[i]].top / 100) - ((this.sizeImgShouldH - this.imgHeight) / 2);
            positionOfLetters[letters[i]].left = (this.sizeImgShouldW * percentPositionOfLetters[letters[i]].left / 100) - ((this.sizeImgShouldW - this.imgWidth) / 2);
        }

        for (var i = 0; i < letters.length; i++) {
            $elsLetters[i].css({
                'width': sizeOfLetters[letters[i]].w + 'px',
                'height': sizeOfLetters[letters[i]].h + 'px',
                'top': positionOfLetters[letters[i]].top + 'px',
                'left': positionOfLetters[letters[i]].left + 'px'
            });
        }
    },

    onResize: function () {
        window.app.views.footer.setMarginBottomContent();

        //get w, h for window
        this.windowHeight = document.documentElement.clientHeight;
        this.windowWidth = document.documentElement.clientWidth;

        var heightFooter = window.app.views.footer.getHeightFooter();
        this.heightPage = document.documentElement.scrollHeight;
        this.startPosFooter = this.heightPage - heightFooter;

        var scrollTop = $(window)
            .scrollTop();

        this.animatedBlocks = _.map(this.$('.isInitialState'), function (block) {
            return {
                $el: $(block),
                offsetTop: block.getBoundingClientRect().top + scrollTop,
                isAnimated: false
            };
        });

        // init for letters
        this.$img = $('.Content-backgroundItemImg--true');
        this.$imgSearch = $('.Content-backgroundItemImg--search');
        this.$imgDev = $('.Content-backgroundItemImg--dev');
        this.$imgTech = $('.Content-backgroundItemImg--tech');

        // get size of bg true
        this.windowWidthForLetters = (this.windowWidth < 1120) ? 1120 : this.windowWidth;
        this.aspectWindow = this.windowWidthForLetters / this.windowHeight;
        this.imgWidth = this.$img.innerWidth();
        this.imgHeight = this.$img.innerHeight();
        this.aspectRatioImgW = 2160 / 1380; // w / h
        this.aspectRatioImgH = 1380 / 2160; // h / w

        // calc size original img which should be for clientScreen
        this.sizeImgShouldH = this.imgHeight;
        this.sizeImgShouldW = this.imgWidth;

        this.initTrueLetters();
        this.initSearchLetters();
        this.initTechLetters();
        this.initDevLetters();

        this.onScroll();
    },

    onScroll: function () {
        var scrollTop = $(window)
            .scrollTop();
        var wh = $(window)
            .height();
        var needAnimate = true;

        this.onTriggerShowFooter();

        _.each(this.animatedBlocks, function (block) {
            var topPosition = (!window.app.settings.isMobile) ? scrollTop + wh - 150 : scrollTop + wh - 70;

            if (!block.isAnimated && needAnimate) {
                if (topPosition >= block.offsetTop) {
                    block.$el.removeClass('isInitialState');
                    block.isAnimated = true;
                } else {
                    needAnimate = false;
                }
            }
        }.bind(this));

        if (!window.app.settings.isMobile) {
            var k = $(window)
                .scrollLeft();
            window.app.els.$footer.css('left', -k);
            window.app.els.$sideNav.css('left', -k);
            window.app.els.$topNav.css('left', -k);
            window.app.els.$contentBackground.css('left', -k);
            window.app.els.$extraMenu.css('transform', 'translate(' + (-k) + 'px, -50%)');
            window.app.els.$searchExtraMenu.css('transform', 'translate(' + (-k) + 'px, -50%)');
        }
    },

    //react animations on scroll (trigger or continuous)
    onTriggerShowFooter: function () {
        if (this.isSubSectionDummy || window.app.settings.isMobile) return;

        //update page or popup only if it is visible|active right now
        if ((this.isSection && app.state.popupShown) || (this.isSubSection && !app.state.popupShown)) return;

        var scrollTop = $(window)
            .scrollTop();

        var heightFooter = window.app.views.footer.getHeightFooter();
        this.heightPage = document.documentElement.scrollHeight;
        this.windowHeight = document.documentElement.clientHeight;
        this.startPosFooter = this.heightPage - heightFooter;

        //if page is section instance, not subsection (popup)
        if (this.isSection) {
            var isBigFooter = false;
            this.heightFooterNav = 0;

            if (!window.app.els.$contentContent.hasClass('TruePage') && !window.app.els.$contentContent.hasClass('SearchExtraPage')) {
                this.heightFooterNav = window.app.views.footerNav.getHeightFooterNav() || 0;
            }

            var footerOpacity = 0;
            var footerTop = 0;

            if (this.windowHeight < (heightFooter - 70)) {
                isBigFooter = true;
                footerTop = heightFooter - Math.max(Math.min((scrollTop + this.windowHeight) - this.startPosFooter + this.heightFooterNav, heightFooter), 0);
                footerOpacity = Math.max(Math.min(((scrollTop + this.windowHeight) - this.startPosFooter + this.heightFooterNav) / this.windowHeight, 1), 0);
            } else {
                footerOpacity = Math.max(Math.min(((scrollTop + this.windowHeight) - this.startPosFooter + this.heightFooterNav) / ((this.heightPage - this.startPosFooter)), 1), 0);
            }

            if ((scrollTop + this.windowHeight) > (this.startPosFooter - this.windowHeight - this.heightFooterNav)) {

                window.app.els.$footer.css({
                    'opacity': footerOpacity
                });

                if (footerOpacity) {
                    window.app.els.$footer.css({
                        'visibility': 'visible'
                    });
                }

                if (isBigFooter) {
                    window.app.els.$footer.find('.Footer-outter').css({
                        'transform': 'translate3d(0,' + footerTop + 'px,0)'
                    });
                } else {
                    window.app.els.$footer.find('.Footer-outter').css({
                        'transform': 'translate3d(0,0,0)'
                    });
                }

                // window.app.els.$contentBackground.css('opacity', 1 - footerOpacity);
            } else {
                window.app.els.$footer.css({
                    'opacity': 0,
                    'visibility': 'hidden'
                });

                if (isBigFooter) {
                    window.app.els.$footer.find('.Footer-outter').css({
                        'transform': 'translate3d(0,' + (heightFooter) + 'px,0)'
                    });
                }
                // window.app.els.$contentBackground.css('opacity', 1);
            }
        }
    },

    triggerStartAnimation: function () {
        setTimeout(this.onResize, this.TIME_ANIMATION * 1.5); // delay for animation from left + part of delay for opacity content
    }
});
