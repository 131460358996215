var Backbone = require('backbone');
var _ = require('underscore');
var svg4everybody = require('svg4everybody');
var objectFitImages = require('object-fit-images/dist/ofi.common-js.js');
var Promise = require('promise-polyfill');

var Settings = require('./Settings');
var Utils = require('front/utils/Utils.js');
var CheckDevice = require('front/utils/CheckDevice/CheckDevice.js');
var Router = require('./Router');

var Urls = require('django_js_front/reverse.js');
window.Urls = Urls;

// Here we declare root components that are rendered on server
// and exist all the time
var TopNav = require('front/components/TopNav/TopNav');
var FooterNav = require('front/components/FooterNav/FooterNav');
var SideNav = require('front/components/SideNav/SideNav');
var SideNavMobile = require('front/components/SideNavMobile/SideNavMobile');
var ClientLoginButton = require('front/components/CommonClientLoginButton/CommonClientLoginButton');
var Footer = require('front/components/Footer/Footer');
var ExtraMenu = require('front/components/ExtraMenu/ExtraMenu');
var SearchExtraMenu = require('front/components/SearchExtraMenu/SearchExtraMenu');
// require('front/components/Content/Content');

require('reset.css');
require('front/fonts.less');
require('front/utils.less');
require('front/style.less');

app.AdoptTempFileObject = function (responseText, dataType) {
    var parsedData = JSON.parse(responseText);
    var responseObject = {};

    if (dataType == 'image') {
        responseObject = {
            aspect: parsedData.aspect,
            width: parsedData.width,
            height: parsedData.height,
            tempImg: parsedData.url,
            tempImgId: parsedData.id
        };
    }

    if (dataType == 'file') {
        responseObject = {
            originalName: parsedData.originalName,
            mime: parsedData.mime && parsedData.mime[0],
            typ: parsedData.mime && parsedData.mime[0].split('/')[1],
            tempFile: parsedData.url,
            tempFileId: parsedData.id
        };
    }

    return responseObject;
};

app.shareFacebook = function (data) {
    window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(data.link),
        'facebooksharedialog',
        'width=626,height=436');
};


app.shareTwitter = function (data) {
    var tweetUrl = 'https://twitter.com/share' +
        '?url=' + encodeURIComponent(data.link) +
        '&text=' + encodeURIComponent(data.title);

    window.open(tweetUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
};

app.shareMail = function (data) {
    window.location.href = 'mailto:?body=' + encodeURIComponent(data.title + ' — ' + data.link);
};


app.shareLinked = function (data) {
    window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(data.link) + '&title=' + encodeURIComponent(data.title) + '&summary=' + encodeURIComponent(data.title)/* + '&source=site.com'*/, 'sharer', 'toolbar=0, status=0, width=626, height=436');
};

// To add to Promise to window for IE
if (!window.Promise) {
    window.Promise = Promise;
}

/* Инициализация полифиллов */
// Поддержка css свойства object-fit для IE и старых браузеров.
objectFitImages();

// Поддержка svg sprites в IE.
svg4everybody();

app.configure = Settings.configure;
app.configure();

window.app.vent = _.extend({}, Backbone.Events);

// window.app.state = {};
window.app.cache = {};
window.app.utils = Utils;

CheckDevice.initialize();

var appState = window.app.state || {};
window.app.state = Object.assign({}, appState);

app.scrollTo = function (dataid) {
    var top = $('[data-id="' + dataid + '"]')
        .offset().top - $('.TopNav')
        .innerHeight();
    $('body,html')
        .animate({scrollTop: top}, 300);
};

// Global storage for frequently used DOM elements
// Example usage in views: app.els.$body
window.app.els = {
    $window: $(window),
    $body: $('body'),
    $htmlBody: $('html,body'),
    $content: $('.Content-content'),
    $contentContent: $('.Content'),
    $contentBackground: $('.Content-background'),
    $contentBackgroundOverlayMenu: $('.Content-backgroundOverlayMenu'),
    $pageSectionBody: $('.Content-body'),
    $topNav: $('.TopNav'),
    $sideNav: $('.SideNav'),
    $sideNavMobile: $('.SideNavMobile'),
    $footer: $('.Footer'),
    $extraMenu: $('.ExtraMenu'),
    $searchExtraMenu: $('.SearchExtraMenu')
};

window.app.views = {
    nav: new TopNav(),
    footerNav: new FooterNav(),
    sideNav: new SideNav(),
    sideNavMobile: new SideNavMobile(),
    clientLoginButton: new ClientLoginButton(),
    footer: new Footer(),
    extraMenu: new ExtraMenu(),
    searchExtraMenu: new SearchExtraMenu()
};

//if youtube api is ready before all we should trigger "youtube-ready"
//cause onYouTubePlayerAPIReady on base.jinja can't invoke it (no app.vent on that moment)
if (window.app.state.youtubePlayerAPIReady) {
    window.app.vent.trigger('youtube-ready');
}

//if youtube api is ready before all we should trigger "youtube-ready"
//cause onYouTubePlayerAPIReady on base.jinja can't invoke it (no app.vent on that moment)
if (window.app.state.googleMapsAPIReady) {
    window.app.vent.trigger('google-maps-ready');
}


// Enabling frontend routing
app.els.$body.on('click', '.u-Route', function (e) {
    if (app.els.$body.hasClass('Page404')) {
        return;
    }

    var currentRoute = Backbone.history.getFragment();

    var parentUrl = $(e.target)
        .closest('a')
        .attr('href');

    var url = $(e.currentTarget)
        .attr('href') || parentUrl;
    window.app.views.nav.closeSideNav(true);

    e.preventDefault();
    Backbone.history.navigate(url, {trigger: 'true'});
});

app.router = new Router();

app.router.start();

//proceed any popup show/hide
//set all underlying content fixed position
app.vent.on('PopupShown', function () {
    window.app.settings.scrollingProgrammaticaly = true;
    var top = 0;
    var scrollTop = $(window)
        .scrollTop();

    // //first of all get top position of all elements that need to be fixed on popup shown
    // $('.NeedFixOnPopup').each(function (id, item) {
    //     var $item = $(item);
    //     $items.push($item);
    //     tops.push($item.offset().top);
    // });
    //
    // //after that set all items fixed position and position them at the very point there where they was
    // _.each($items, function ($item, ind) {
    //     $item
    //         .css('top', tops[ind] - scrollTop)
    //         .addClass('FixedOnPopup');
    // });

    top = $('.NeedFixOnPopup')
        .offset().top;

    if (app.settings.isMobile) {
        $('.Section-content')
            .addClass('overlay');
    }

    $('.NeedFixOnPopup')
        .css('top', top - scrollTop)
        .addClass('FixedOnPopup');

    app.state.popupShown = true;

    $(window)
        .trigger('resize');
});

app.vent.on('PopupHidded', function () {
    // $('.NeedFixOnPopup').each(function (id, item) {
    //     $(item)
    //         .css('top', '')
    //         .removeClass('FixedOnPopup');
    // });
    $('.NeedFixOnPopup')
        .css('top', '')
        .removeClass('FixedOnPopup');

    if (app.settings.isMobile) {
        $('.Section-content')
            .removeClass('overlay');
    }

    app.state.popupShown = false;
    $(window)
        .trigger('resize');
    setTimeout(function () {
        window.app.settings.scrollingProgrammaticaly = false;
    });
});
