var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./CommonClientLoginButton.less');


module.exports = Base.extend({
    template: require('./CommonClientLoginButton.jinja'),

    el: '.ClientLoginButton',

    initialize: function (options) {
        _.bindAll(this, 'onScroll', 'onResize');

        this.onResize.__throttled = _.throttle(this.onResize, 50);

        $(window)
            .on('resize', this.onResize.__throttled)
            .on('scroll', this.onScroll);

        this.onScroll();

    },

    onScroll: function () {
        var $menu = $('.SearchExtraMenu');

        var scrollTop = $(window).scrollTop();
        var menuPosition = (parseFloat($menu.css('top')) - ($menu.innerHeight() / 2)) + parseFloat($menu.css('margin-top'));
        var menuHeight = $menu.innerHeight();
        var elPosition = $(window).innerHeight() - (parseFloat(this.$el.css('bottom')) + this.$el.innerHeight());

        if (elPosition - menuPosition > -50 && elPosition - menuPosition < menuHeight) {
            this.$el.addClass('hide');
        } else {
            this.$el.removeClass('hide');
        }

    },

    onResize: function () {
        this.onScroll();
    }
});