var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var CommonItemShape = require('front/components/CommonItemShape/CommonItemShape');

require('./GrowingTalant.less');

module.exports = Base.extend({
    template: require('./GrowingTalant.jinja'),

    el: '.GrowingTalant',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'CommonItemShape': {selector: '.CommonItemShape', viewConstructor: CommonItemShape}
    },

    initialize: function (options) {

        Base.prototype.initialize.call(this, options);
    }
});
