var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

require('./SearchExtraMenu.less');

module.exports = Base.extend({
    template: require('./SearchExtraMenu.jinja'),

    el: '.SearchExtraMenu',

    events: {
        'click .SearchExtraMenu-item': 'onPageChange',
        'click .SearchExtraMenu-item--team': 'setTeamToShow'
    },

    initialize: function (options) {
        Base.prototype.initialize.call(this, options);

        _.bindAll(this, 'onResize', 'onScroll', 'onPageChange');

        this.onResize.__throttled = _.throttle(this.onResize, 50);

        this.pathMenu = 0;
        this.isFix = false;

        $(window)
            .on('resize', this.onResize.__throttled)
            .on('scroll', this.onScroll);

        // this.onResize.__throttled();
    },

    initSearchExtraMenu: function (el) {
        var locPath = window.location.pathname.split('/');

        this.$content = el.find('.Content-wrapper');
        this.topContent = (!!this.$content.css('margin-top')) ? Number(this.$content.css('margin-top').slice(0,-2)) : 0;

        if (this.topContent != 0) {
            this.pathMenu = this.topContent;
            this.$el.css({
                // 'top': 'calc(50% + ' + this.pathMenu + 'px)',
                'margin-top': this.pathMenu + 'px'
            });
        } else {
            this.pathMenu = 0;
            this.$el.css({
                // 'top': 'calc(50% + ' + this.pathMenu + 'px)',
                'margin-top': this.pathMenu + 'px'
            });
        }

        $('.SearchExtraMenu-item.isActive').removeClass('isActive');

        var idMenu = (!!locPath[2]) ? locPath[2] : locPath[1];
        $('.SearchExtraMenu-item[data-id="' + idMenu + '"]').addClass('isActive');

        this.onScroll();

        this.$el.removeClass('isHide');
    },

    onResize: function () {
        if (this.isFix) return;

        this.$content = $('.Content-wrapper');
        this.topContent = (!!this.$content.css('margin-top')) ? Number(this.$content.css('margin-top').slice(0,-2)) : 0;

        if (this.topContent != 0) {
            this.pathMenu = this.topContent;
            this.$el.css({
                // 'top': 'calc(50% + ' + this.pathMenu + 'px)',
                'margin-top': this.pathMenu + 'px'
            });
        } else {
            this.pathMenu = 0;
            this.$el.css({
                // 'top': 'calc(50% + ' + this.pathMenu + 'px)',
                'margin-top': this.pathMenu + 'px'
            });
        }

        this.onScroll();
    },

    onScroll: function () {
        if (this.isFix) return;

        var scrollTop = $(window).scrollTop();

        if (scrollTop - this.pathMenu < 0) {
            // this.$el.css('top', 'calc(50% + ' + -(scrollTop - this.pathMenu) + 'px)');
            this.$el.css({
                'margin-top': -(scrollTop - this.pathMenu) + 'px'
            });
        } else {
            this.$el.css({
                'margin-top': '0'
            });
        }
    },

    fixMenu: function () {
        this.isFix = true;
    },

    unFixMenu: function () {
        this.isFix = false;
    },

    destroy: function () {
        return Base.prototype.destroy.call(this);
    },

    deactivate: function (params) {

        $(window)
            .off('resize', this.onResize)
            .off('scroll', this.onScroll);

        return Base.prototype.deactivate.call(this, params);
    },

    onPageChange: function () {
        window.app.data.isFromSubmenu = true;
    },

    setTeamToShow: function (e) {
        window.app.data.teamFilterValues.team = 'search';
        window.app.data.teamFilterValues.isCustomTeam = true;
    }

});
