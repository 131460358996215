var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/TeamMemberPopup/TeamMemberPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/TeamMemberPopup/TeamMemberPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n<div class=\"TeamMemberPopup\">\n    <div class=\"TeamMemberPopup-wrapper overflowLock\">\n        <div class=\"TeamMemberPopup-bg\"></div>\n        <div class=\"TeamMemberPopup-content\">\n            <div class=\"TeamMemberPopup-contentInner\">\n                <div class=\"TeamMemberPopup-close\">\n                    <div class=\"TeamMemberPopup-closeItem TeamMemberPopup-closeItem--1\"></div>\n                    <div class=\"TeamMemberPopup-closeItem TeamMemberPopup-closeItem--2\"></div>\n                </div>\n                ";
var t_4;
t_4 = ((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")) && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image"));
frame.set("isSquarePictureShow", t_4, true);
if(frame.topLevel) {
context.setVariable("isSquarePictureShow", t_4);
}
if(frame.topLevel) {
context.addExport("isSquarePictureShow", t_4);
}
output += "\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")) {
output += "\n                    <div class=\"TeamMemberPopup-image\">\n                        <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")),"w1260_url"), env.opts.autoescape);
output += "\"\n                             srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")),"w1440_url"), env.opts.autoescape);
output += " 1920w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")),"w1260_url"), env.opts.autoescape);
output += " 1680w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")),"w840_url"), env.opts.autoescape);
output += " 1120w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image2")),"w720_url"), env.opts.autoescape);
output += " 720w\"\n                             sizes=\"(max-width: 1119px) 1, ";
output += "\n                                         75%\" ";
output += "\n                             alt=\"\">\n                    </div>\n                ";
;
}
else {
output += "\n                    <div class=\"TeamMemberPopup-imagePlaceholder\"></div>\n                ";
;
}
output += "\n                <div class=\"TeamMemberPopup-memberInfo\">\n                    <div class=\"TeamMemberPopup-memberInfoHead\">\n                        <div class=\"TeamMemberPopup-memberTitleWrapper ";
if(runtime.contextOrFrameLookup(context, frame, "isSquarePictureShow")) {
output += " memberSquareImage ";
;
}
output += "\">\n                            <div class=\"TeamMemberPopup-title\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"name"), env.opts.autoescape);
output += "\n                            </div>\n                            <div class=\"TeamMemberPopup-subtitle\">\n                            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"team")),"value")) {
output += "<div class=\"TeamMemberPopup-memberTeam\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"team")),"value"), env.opts.autoescape);
output += "&nbsp;&nbsp;&nbsp;</div>\n                            ";
;
}
output += "<div class=\"TeamMemberPopup-memberPosition\">\n                                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"title")) {
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"team")),"value")) {
output += "<span class=\"TeamMemberPopup-memberPositionSlash\">/&nbsp;&nbsp;&nbsp;</span>\n                                        ";
;
}
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"title"), env.opts.autoescape);
output += "\n                                    ";
;
}
output += "</div>\n                            </div>\n                            ";
if(runtime.contextOrFrameLookup(context, frame, "isSquarePictureShow")) {
output += "\n                                <div class=\"TeamMemberPopup-social\">\n                                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email")) {
output += "\n                                        <a href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email"), env.opts.autoescape);
output += "\"\n                                           class=\"TeamMemberPopup-socialEmail\">Email</a>\n                                    ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"linkedin")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"linkedin"), env.opts.autoescape);
output += "\" target=\"_blank\"  class=\"TeamMemberPopup-socialLinkedIn\">LinkedIn</a>\n                                    ";
;
}
output += "</div>\n                                <div class=\"TeamMemberPopup-flexSpacer\"></div>\n                                <div class=\"TeamMemberPopup-clock absolute\">\n                                    <div class=\"TeamMemberPopup-clockItem\">\n                                    </div>\n                                </div>\n                            ";
;
}
output += "\n                            <div class=\"TeamMemberPopup-social TeamMemberPopup-socialMobile\">\n                                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email")) {
output += "\n                                    <a href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email"), env.opts.autoescape);
output += "\"\n                                       class=\"TeamMemberPopup-socialMobileEmail\">Email</a>\n                                ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"linkedin")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"linkedin"), env.opts.autoescape);
output += "\" target=\"_blank\" class=\"TeamMemberPopup-socialMobileLinkedIn\">LinkedIn</a>\n                                ";
;
}
output += "</div>\n                        </div>\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "isSquarePictureShow")) {
output += "\n                            <div class=\"TeamMemberPopup-imageSquare\">\n                                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w332_url"), env.opts.autoescape);
output += "\"\n                                     srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w390_url"), env.opts.autoescape);
output += " 1920w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w332_url"), env.opts.autoescape);
output += " 1680w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w202_url"), env.opts.autoescape);
output += " 1120w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w308_url"), env.opts.autoescape);
output += " 720w\"\n                                     sizes=\"(max-width: 1119px) 1, ";
output += "\n                                         75%\" ";
output += "\n                                     alt=\"\">\n                            </div>\n                        ";
;
}
output += "\n                        ";
if(!runtime.contextOrFrameLookup(context, frame, "isSquarePictureShow")) {
output += "\n                            <div class=\"TeamMemberPopup-clock\">\n                                <div class=\"TeamMemberPopup-clockItem\">\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                        <div class=\"TeamMemberPopup-clock TeamMemberPopup-clockMobile\">\n                            <div class=\"TeamMemberPopup-clockItem\">\n                            </div>\n                        </div>\n                    </div>\n\n                    ";
if(!runtime.contextOrFrameLookup(context, frame, "isSquarePictureShow")) {
output += "\n                        <div class=\"TeamMemberPopup-social\">\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email")) {
output += "\n                                <a href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email"), env.opts.autoescape);
output += "\"\n                                   class=\"TeamMemberPopup-socialEmail\">Email</a>\n                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"linkedin")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"linkedin"), env.opts.autoescape);
output += "\" target=\"_blank\" class=\"TeamMemberPopup-socialLinkedIn\">LinkedIn</a>\n                            ";
;
}
output += "</div>\n                    ";
;
}
output += "\n\n                    <div class=\"TeamMemberPopup-imageSquareMobile\">\n                        <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w332_url"), env.opts.autoescape);
output += "\"\n                             srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w390_url"), env.opts.autoescape);
output += " 1920w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w332_url"), env.opts.autoescape);
output += " 1680w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w202_url"), env.opts.autoescape);
output += " 1120w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")),"w308_url"), env.opts.autoescape);
output += " 720w\"\n                             sizes=\"(max-width: 1119px) 1, ";
output += "\n                                         75%\" ";
output += "\n                             alt=\"\">\n                    </div>\n\n                    <div class=\"TeamMemberPopup-description\">\n                        <div class=\"TeamMemberPopup-quickBio\">\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"bio")) {
output += "<div class=\"TeamMemberPopup-quickBioTitle\">Bio:</div>\n                                <div class=\"TeamMemberPopup-quickBioDescription\">\n                                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"bio")), env.opts.autoescape);
output += "\n                                </div>\n                            ";
;
}
output += "</div>\n                        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"snapshots")) > 0) {
output += "\n                            <div class=\"TeamMemberPopup-snapshot\">\n                                <div class=\"TeamMemberPopup-snapshotTitle\">Snapshot:</div>\n                                <div class=\"TeamMemberPopup-snapshotDescription\">\n                                    ";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"snapshots");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("snapshotItem", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                                        <div class=\"TeamMemberPopup-snapshotDescriptionItem\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"title"), env.opts.autoescape);
output += "</div>\n                                    ";
;
}
}
frame = frame.pop();
output += "\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                    </div>\n                    ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"clients")) > 0) {
output += "\n                        <div class=\"TeamMemberPopup-clients\">\n                            <div class=\"TeamMemberPopup-clientsTitle\">Clients:</div>\n                            <div class=\"TeamMemberPopup-clientsLogos\">\n                                ";
frame = frame.push();
var t_11 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"clients");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("clientLogo", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n                                    <div class=\"TeamMemberPopup-clientsLogo\">\n                                        <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_12),"logo")),"lightUrl"), env.opts.autoescape);
output += "\"\n                                             srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_12),"logo")),"lightUrl"), env.opts.autoescape);
output += " 1920w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_12),"logo")),"lightUrl"), env.opts.autoescape);
output += " 1680w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_12),"logo")),"lightUrl"), env.opts.autoescape);
output += " 1120w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_12),"logo")),"lightUrl"), env.opts.autoescape);
output += " 720w\"\n                                             sizes=\"(max-width: 1119px) 1, ";
output += "\n                                         75%\" ";
output += "\n                                             alt=\"\">\n                                    </div>\n                                ";
;
}
}
frame = frame.pop();
output += "\n                            </div>\n                        </div>\n                    ";
;
}
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/TeamMemberPopup/TeamMemberPopup.jinja"] , dependencies)