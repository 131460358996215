var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./HistoryOfInnovation.less');

module.exports = Base.extend({
    template: require('./HistoryOfInnovation.jinja'),

    el: '.HistoryOfInnovation',

    events: {
        'click .HistoryOfInnovation-link': 'linkClick'
    },

    initialize: function (options) {

        _.bindAll(this, 'linkClick');

        Base.prototype.initialize.call(this, options);
    },

    linkClick: function (e) {
        if (!$(e.target).is('a')){
            e.preventDefault();
            var link = $(e.target).closest('.HistoryOfInnovation-link').data('link');
            window.open(link, '_blank');
        }
    }
});
