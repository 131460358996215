var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var OurStory = require('front/components/OurStory/OurStory');

var Section = require('front/pages/Section/Section');

require('./OurStorySection.less');


module.exports = Section.extend({
    template: require('./OurStorySection.jinja'),

    el: '.OurStorySection',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'OurStory': {selector: '.OurStory', viewConstructor: OurStory}
    },

    // apiUrl: Urls['front:api:our-story'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        _.bindAll(this, 'activateLogic');

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:our-story']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        window.app.views.extraMenu.initExtraMenu(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        Section.prototype.render.call(this);

        this.setClass('OurStoryPage TruePage');
        this.setTitle('true • our story');
        this.activateLogic();
    }
});

