var _ = require('underscore/underscore');
var SubSection = require('front/pages/Section/SubSection');
// var Backbone = require('backbone');

var Urls = require('django_js_front/reverse.js');

var CommonBlock = require('front/components/CommonBlock/CommonBlock');

require('./NewsPopup.less');


module.exports = SubSection.extend({
    el: '.NewsPopup',

    template: require('./NewsPopup.jinja'),

    events: {
        'click .NewsPopup-close': 'close',
        'click .NewsPopup-bg': 'close',
        'click .NewsPopup-headerClose': 'close'
    },

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        // 'CommonBlock': {selector: '.CommonBlock', viewConstructor: CommonBlock}
    },

    apiUrl: function () {
        var id = parseInt(this.slug.split('-')[0], 10);

        return Urls['front:api:news-detail'](id);
    },

    renderData: function () {
        this.setTitle(': ' + this.data.title, true);

        return {
            popup: this.data || {}
        };
    },

    initialize: function (options) {
        SubSection.prototype.initialize.call(this, options);

        this.options = options || {};

        _.bindAll(this, 'activateLogic', 'triggerPopupShown', 'triggerPopupHidded');

        this.slug = this.options.slug;

        if (this.options.server) {
            this.activateLogic(true);

            this.data = {}; //prevent api call for data when opens from direct url
        }

        _.each(this.$('.NewsPopup-blocksBlock'), function (item, id) {
            var nameView = 'CommonBlock-' + id;

            this.VIEWS_TO_REGISTER[nameView] = {
                selector: '.NewsPopup-blocksBlock--' + id, viewConstructor: CommonBlock
            };
        }.bind(this));
    },

    activateLogic: function (isServer) {
        this.$el.hide();
        this.$el.addClass('NewsPopup--visible');
        this.$el.show();
        this.$el.outerHeight();
        this.$('.NewsPopup-content')
            .scrollTop(0);
        this.$('.NewsPopup-content')
            .focus(); //for pgdn pgup shortcuts

        var self = this;
        if (isServer) {
            setTimeout(function () {
                self.$el.find('.NewsPopup-wrapper')
                    .removeClass('overflowLock');
                self.triggerPopupShown();
            }.bind(this), this.TIME_ANIMATION);
        } else {
            this.$el.find('.NewsPopup-wrapper')
                .removeClass('overflowLock');
            this.triggerPopupShown();
        }
        // }.bind(this), this.TIME_ANIMATION * 2);
        // window.app.views.extraMenu.initExtraMenu(this.$el);
    },

    triggerPopupShown: function () {
        window.app.views.extraMenu.fixMenu();
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger('PopupShown');
        // window.app.views.nav.toggleClose(this.rootUrl);
        $(window)
            .scrollTop(0);
    },

    triggerPopupHidded: function () {
        window.app.views.extraMenu.unFixMenu();
        app.vent.trigger('PopupHidded');
        $(window)
            .scrollTop(this.savedScroll);
    },

    close: function () {
        // Backbone.history.navigate(this.rootUrl, {trigger: 'true'});
        this.setTitle(this.titleBefore);
        app.router.navigateBack();
    },

    activate: function (params) {

        return SubSection.prototype.activate.call(this, params)
            .then(function () {
                this.$content = this.$('.NewsPopup-content');
                this.$content.off('scroll', this.onScroll);
                this.$content.on('scroll', this.onScroll);
            }.bind(this));
    },

    playIn: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            self.$el.hide();
            self.$el.show();
            self.$el.addClass('NewsPopup--visible');
            self.$el.outerHeight();
            // setTimeout(function () {
            self.$el.find('.NewsPopup-wrapper')
                .removeClass('overflowLock');
            self.triggerPopupShown();
            // }, self.TIME_ANIMATION * 2);
            resolve();
        });
    },

    playOut: function (params) {
        var self = this;

        return new Promise(function (resolve) {
            self.$el.find('.NewsPopup-wrapper')
                .addClass('overflowLock');
            self.$el.removeClass('NewsPopup--visible');
            setTimeout(function () {
                self.triggerPopupHidded();
                resolve();
            }, self.TIME_ANIMATION * 2);
        });
    },

    destroy: function () {
        return SubSection.prototype.destroy.call(this);
    },

    deactivate: function (params) {

        this.triggerPopupHidded();

        return SubSection.prototype.deactivate.call(this, params);
    }
});
