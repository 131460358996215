var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/components/TeamMembers/TeamMembersList.jinja"] = require( "front/components/TeamMembers/TeamMembersList.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/TeamMembers/TeamMembersListRuntime.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/components/TeamMembers/TeamMembersList.jinja", false, "assets/app/front/components/TeamMembers/TeamMembersListRuntime.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "TeamMembersList")) {
var t_4 = t_1.TeamMembersList;
} else {
cb(new Error("cannot import 'TeamMembersList'")); return;
}
context.setVariable("TeamMembersList", t_4);
output += "\n\n";
output += runtime.suppressValue((lineno = 2, colno = 18, runtime.callWrap(t_4, "TeamMembersList", context, [runtime.makeKeywordArgs({"membersList": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"membersList"),"values": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"values")})])), env.opts.autoescape);
output += "\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/TeamMembers/TeamMembersListRuntime.jinja"] , dependencies)