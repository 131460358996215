var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./RecentPlacements.less');

var RecentPlacementsListItemTemplate = require('./RecentPlacementsListItemFront.jinja');
var RecentPlacementsFilterItemTemplate = require('./RecentPlacementsFilterItem.jinja');


module.exports = Base.extend({
    template: require('./RecentPlacements.jinja'),

    SCROLL_OFFSET: 500,

    el: '.RecentPlacements',

    events: {
        'click .RecentPlacements-filterModel': 'toggleFilter',
        'click .RecentPlacements-filterOption': 'selectValue',
        'click .RecentPlacements-clearFilters': 'clearFilters'
    },

    initialize: function () {
        _.bindAll(this, 'addZeroItemsToFilters', 'setDefaultFilterValues', 'renderFilters', 'toggleFilter',
            'selectValue', 'clearFilters', 'renderItems', 'remapQuarters', 'clickOutsideFilters', 'closeFilters',
            'sortQuarters', 'selectNativeValue', 'getQuarterNameById', 'changeHash');

        this.filters = window.app.data.recentPlacementsFilters;
        this.values = {};
        this.items = {};
        this.$list = this.$('.RecentPlacements-list');

        $(document)
            .on('click', this.clickOutsideFilters);


        this.remapQuarters();
        this.addZeroItemsToFilters();
        this.setDefaultFilterValues();
        this.renderFilters();
        // this.renderItems();
        this.next = undefined;
        this.loading = false;
        this.loadItems();

        app.els.$window.on('resize', _.throttle(this.onResize.bind(this), 20));
        app.els.$window.on('scroll', _.throttle(this.onScroll.bind(this), 100));
    },

    loadItems: function () {

        this.$list.children('.RecentPlacements-listRow--hidden').removeClass('RecentPlacements-listRow--hidden');

        if (this.loading || this.next === null) {
            return;
        }

        var url = Urls['front:api:search-items-list']('json');

        var filters = _.compact(_.map(_.pairs(this.values), function (pair) {
            if (typeof pair[1].id === 'string' && pair[1].id.indexOf('all') === 0) {
                return null;
            }

            if (pair[0] === 'quarter' && typeof pair[1].id === 'string') {
                return [pair[0], _.compact(_.map(this.filters.quarters, function (quarter) {
                    if (typeof quarter.id === 'string') {
                        return null;
                    }

                    return quarter.name.indexOf(pair[1].id) === 0 ? quarter.id : null;
                }))];
            }

            return [pair[0], pair[1].id];
        }.bind(this)));

        if (filters.length) {
            url += '?' + _.map(filters, function (value) {
                if (_.isArray(value[1])) {
                    return _.map(value[1], function (val) {
                        return value[0] + '=' + val;
                    }).join('&');
                } else {
                    return value[0] + '=' + value[1];
                }

            }).join('&');
        }

        if (!this.next) {
            this.$list.addClass('RecentPlacements-list--loading');
        }

        this.loading = true;

        Promise.all([
            new Promise(function (resolve, reject) {
                if (!!this.next) {
                    resolve();
                } else {
                    setTimeout(resolve, 500);
                }
            }.bind(this)),
            $.ajax(this.next || url)
        ]).then(function (data) {
            if (!!this.next) {
                this.items.concat(data[1].results);
                this.renderItems(data[1].results);
            } else {
                this.items = data[1].results;
                this.renderItems();
            }
            this.next = data[1].next;
            this.onResize();
            this.$list.removeClass('RecentPlacements-list--loading');
            this.loading = false;
        }.bind(this));

        // console.log(this);
    },

    addZeroItemsToFilters: function () {
        var practiceDefault = {
            id: 'allPractices',
            name: 'All practices'
        };
        var functionDefault = {
            id: 'allFunctions',
            name: 'All functions'
        };
        var assetsClassesDefault = {
            id: 'allAssetsClasses',
            name: 'All assets classes'
        };
        var locationsDefault = {
            id: 'allLocations',
            name: 'All locations'
        };
        var quartersDefault = {
            id: 'allQuarters',
            name: 'All quarters'
        };

        this.defaultFiltersNames = [
            practiceDefault.name.replace(/\s/g, ''),
            functionDefault.name.replace(/\s/g, ''),
            assetsClassesDefault.name.replace(/\s/g, ''),
            locationsDefault.name.replace(/\s/g, ''),
            quartersDefault.name.replace(/\s/g, '')
        ];

        if (this.filters.practices) {
            this.filters.practices.unshift(practiceDefault);
        }
        if (this.filters.functions) {
            this.filters.functions.unshift(functionDefault);
        }
        if (this.filters.assetsClasses) {
            this.filters.assetsClasses.unshift(assetsClassesDefault);
        }
        if (this.filters.locations) {
            this.filters.locations.unshift(locationsDefault);
        }
        if (this.filters.quarters) {
            this.filters.quarters.unshift(quartersDefault);
        }
    },

    remapQuarters: function () {
        var lastYear = '';
        var newQuarters = [];
        this.sortQuarters();
        for (var i = 0; i < this.filters.quarters.length; i++) {
            var name = this.filters.quarters[i].name;
            var year = name && name.substring(0, 4);
            if (year !== lastYear) {
                lastYear = year;
                var allQuarters = {
                    id: year,
                    name: year + ' All Quarters'
                };
                newQuarters.push(allQuarters);
            }

            this.filters.quarters[i].needPadding = true;
            newQuarters.push(this.filters.quarters[i]);
        }
        this.filters.quarters = newQuarters;
    },

    sortQuarters: function () {
        this.filters.quarters = this.filters.quarters.sort(function (a, b) {
            return (+b.name.substring(0, 4)) - (+a.name.substring(0, 4));
        });
    },

    setDefaultFilterValues: function (forceReset) {

        this.values.practice = this.filters.practices[0];
        this.values.function = this.filters.functions[0];
        this.values.assetsClass = this.filters.assetsClasses[0];
        this.values.location = this.filters.locations[0];
        this.values.quarter = this.filters.quarters[0];

        var filterUrlParameters = window.location.hash;

        if (filterUrlParameters && !forceReset) {
            filterUrlParameters = filterUrlParameters.replace('#', '');
            var filterHashValues = filterUrlParameters.split('&');

            for (var i = 0; i < filterHashValues.length; i++) {
                if (filterHashValues[i]) {
                    var filterData = filterHashValues[i].split('=');

                    if (filterData.length > 1) {
                        var filterName = filterData[0];
                        var filterValue = filterData[1];

                        var filtersName = '';

                        switch (filterName) {
                            case 'practice':
                                filtersName = 'practices';
                                break;
                            case 'function':
                                filtersName = 'functions';
                                break;
                            case 'assetsClass':
                                filtersName = 'assetsClasses';
                                break;
                            case 'location':
                                filtersName = 'locations';
                                break;
                            case 'quarter':
                                filtersName = 'quarters';
                                break;
                        }

                        if (filtersName !== '') {
                            for (var j = 0; j < this.filters[filtersName].length; j++) {
                                if (this.filters[filtersName][j] && this.filters[filtersName][j].name.replace(/\s/g, '') === filterValue) {
                                    this.values[filterName] = this.filters[filtersName][j];
                                }
                            }
                        }
                    }
                }
            }
        }

    },

    renderFilters: function () {
        var practiceFilter = RecentPlacementsFilterItemTemplate.render({
            data: {
                model: this.values.practice,
                options: this.filters.practices,
                modelName: 'practice',
                filter: 'practices'
            }
        });

        var functionFilter = RecentPlacementsFilterItemTemplate.render({
            data: {
                model: this.values.function,
                options: this.filters.functions,
                modelName: 'function',
                filter: 'functions'
            }
        });

        var assetsClassFilter = RecentPlacementsFilterItemTemplate.render({
            data: {
                model: this.values.assetsClass,
                options: this.filters.assetsClasses,
                modelName: 'assetsClass',
                filter: 'assetsClasses'
            }
        });

        var locationFilter = RecentPlacementsFilterItemTemplate.render({
            data: {
                model: this.values.location,
                options: this.filters.locations,
                modelName: 'location',
                filter: 'locations'
            }
        });

        var quarterFilter = RecentPlacementsFilterItemTemplate.render({
            data: {
                model: this.values.quarter,
                options: this.filters.quarters,
                filter: 'quarters',
                modelName: 'quarter'
            }
        });


        var combinedHtml = practiceFilter + functionFilter + assetsClassFilter + locationFilter + quarterFilter;

        $('.RecentPlacements-filterList')
            .html(combinedHtml);


        var self = this;
        if (window.app.settings.isMobile) {
            // document.getElementById('recent-placements-select')
            //     .addEventListener('change', function (e) {
            //         self.selectNativeValue(self.$('option:selected'));
            //     });

            this.$('select')
                .each(function (i, select) {
                    $(select)
                        .on('change', function () {
                            var $option = $(select)
                                .children('option:selected');
                            self.selectNativeValue($option);
                        });

                });
        }
    },

    toggleFilter: function (e) {
        var addClass = !$(e.target)
            .hasClass('active');
        this.$('.RecentPlacements-filterModel')
            .removeClass('active');
        if (addClass) {
            $(e.target)
                .addClass('active');
        } else {
            $(e.target)
                .removeClass('active');
        }
    },

    selectNativeValue: function ($activeOption) {
        if ($activeOption) {
            var newOptionId = $activeOption.data('option-id');
            var filter = $activeOption.data('filter');
            var newOption = {};
            var optionsList = this.filters[filter];
            if (optionsList) {
                optionsList.forEach(function (option) {
                    if (option.id.toString() === newOptionId.toString()) {
                        newOption = option;
                    }
                });
            }
            var modelName = $activeOption.data('model-name');
            if (this.values[modelName]) {
                this.values[modelName] = newOption;
            }

            this.renderFilters();
            this.next = undefined;
            this.loadItems();
            this.$('select')
                .val(newOption.name);
        }
    },

    changeHash: function (filterName, filterValue) {
        filterValue = filterValue && filterValue.replace(/\s/g, '');
        if (filterName && filterValue) {
            var currentHash = window.location.hash;
            var isDefaultValue = this.defaultFiltersNames.indexOf(filterValue) !== -1;
            if (currentHash) {
                currentHash = currentHash.replace('#', '');
                var isFilterInHash = false;
                var values = currentHash.split('&');
                for (var i = 0; i < values.length; i++) {
                    var filterData = values && values[i] && values[i].split('=');
                    if (values[i].length > 1) {
                        var currentFilterName = filterData[0];
                        var currentFilterId = filterData[1];
                        if (currentFilterName === filterName) {
                            isFilterInHash = true;

                            var replaceValue = '';
                            if (!isDefaultValue) {
                                replaceValue = currentFilterName + '=' + filterValue;
                            } else {
                                window.location.hash = window.location.hash.replace('&' + currentFilterName + '=' + currentFilterId, replaceValue);
                            }

                            if (replaceValue === '') {
                                if (window.history && window.history.pushState) {
                                    window.history.pushState('', '', window.location.pathname);
                                } else {
                                    window.location.href = window.location.href.replace(/#.*$/, '#');
                                }
                            } else {
                                window.location.hash = window.location.hash.replace(currentFilterName + '=' + currentFilterId, replaceValue);
                            }
                        }

                    }
                }
                if (!isFilterInHash && !isDefaultValue) {
                    window.location.hash += '&' + filterName + '=' + filterValue;
                }
            } else {
                if (!isDefaultValue) {
                    window.location.hash = filterName + '=' + filterValue;
                }
            }
        } else {
            // window.location.hash = '#';
            if (window.history && window.history.pushState) {
                window.history.pushState('', '', window.location.pathname);
            } else {
                window.location.href = window.location.href.replace(/#.*$/, '#');
            }
        }
    },

    selectValue: function (e) {
        var $option = $(e.target);
        var newOptionId = $option.data('option-id');
        var filter = $option.data('filter');
        var newOption = {};
        var optionsList = this.filters[filter];
        if (optionsList) {
            optionsList.forEach(function (option) {
                if (option.id.toString() === newOptionId.toString()) {
                    newOption = option;
                }
            });
        }
        var modelName = $option.data('model-name');
        if (this.values[modelName]) {
            this.values[modelName] = newOption;
        }
        this.changeHash(modelName, newOption.name);

        this.renderFilters();
        this.next = undefined;
        this.loadItems();
    },

    clearFilters: function () {
        this.setDefaultFilterValues(true);
        this.changeHash();
        this.renderFilters();
        this.next = undefined;
        this.loadItems();
    },

    clickOutsideFilters: function (e) {
        var $container = this.$('.RecentPlacements-filterRow');
        if (!$container.is(e.target) && $container.has(e.target).length === 0) {
            this.closeFilters();
        }
    },

    closeFilters: function () {
        this.$('.RecentPlacements-filterModel')
            .removeClass('active');
    },

    getQuarterNameById: function (id) {
        if (this.filters && this.filters.quarters) {
            for (var i = 0; i < this.filters.quarters.length; i++) {
                if (this.filters.quarters[i] && this.filters.quarters[i].id === id) {
                    return this.filters.quarters[i].name;
                }
            }
        }
    },

    renderItems: function (items) {
        var filteredList = items || this.items;

        var listCombinedHtml = '';

        var counter = 0;

        var countOfCols = window.app.settings.isMobile ? 2 : 4;

        if (filteredList.length > 0) {

            var half = Math.floor(filteredList.length / 8);

            for (var i = 0; i < filteredList.length; i++) {

                var row = Math.floor(i/4);

                if (counter === 0) {
                    listCombinedHtml += '<div class="RecentPlacements-listRow' + (items || row > half ? ' RecentPlacements-listRow--hidden' : '') + '">';
                }

                var itemTemplate = RecentPlacementsListItemTemplate.render({
                    data: filteredList[i]
                });

                listCombinedHtml += itemTemplate;

                counter++;
                if (counter === countOfCols) {
                    listCombinedHtml += '</div>';
                    counter = 0;
                }
            }

            if (items) {
                this.$list.append(listCombinedHtml);
            } else {
                this.$list.html(listCombinedHtml);
            }
        } else {
            var zeroResultHtml = '<div class="RecentPlacements-zeroResults">0 results</div>';
            this.$list
                .html(zeroResultHtml);
        }
    },

    onScroll: function () {
        if (app.els.$htmlBody.scrollTop() + this.windowHeight + this.SCROLL_OFFSET >= this.listBottom && !this.loading) {
            this.loadItems();
        }
    },

    onResize: function () {
        this.listBottom = this.$list.offset().top + this.$list.height();
        this.windowHeight = app.els.$window.height();
    }

});
