var _ = require('underscore/underscore.js');
var Base = require('front/components/Base/Base');

var template = require('./CommonBlockVideo.jinja');
require('./CommonBlockVideo.less');

var VimeoPlayer = require('@vimeo/player/dist/player.js');

module.exports = Base.extend({

    template: template,

    el: '.CommonBlockVideo',

    events: {
        'click .CommonBlockVideo-videoPreviewWrapper': 'onOverlayClick'
    },


    initialize: function (options) {
        this.options = options || {};

        _.bindAll(this, 'onYoutubePlayerAPIReady', 'initPlayer', 'pausePlayer', 'modifyYoutubeEmbed');

        Base.prototype.initialize.call(this, options);

        this.playerIsReady = false;

        this.$wrapperIframe = this.$('.CommonBlockVideo-iframe');
        this.$preview = this.$('.CommonBlockVideo-videoPreviewWrapper');

        this.initPlayer();

        this.$el.on('pauseplayer', this.pausePlayer);
    },

    modifyYoutubeEmbed: function () {
        var src = this.$wrapperIframe.find('iframe').attr('src');

        this.$wrapperIframe.find('iframe').attr('src', (src + '&enablejsapi=1'));
    },

    onOverlayClick: function (e) {
        e.stopPropagation(); //prevent our custom fullsceen mode on item click

        if (!this.playerIsReady) return;

        var self = this;

        this.$preview.hide();

        if (this.provider == 'vimeo') {
            this.player.getPaused()
                .then(function (paused) {
                    paused ? self.player.play() : self.player.pause();
                })
                .catch(function (error) {
                });
        } else {
            this.player.getPlayerState() == 1 ? this.player.pauseVideo() : this.player.playVideo();
        }
    },


    // viewsRegistration: function () {
    //     Base.prototype.viewsRegistration.call(this);
    // },


    pausePlayer: function () {
        if (!this.playerIsReady) return;

        if (this.provider == 'vimeo') {
            this.player.pause();
        } else {
            this.player.pauseVideo();
        }

        this.$preview.show();
    },


    initPlayer: function () {
        this.provider = this.$el.attr('data-provider');

        this.$iframe = this.$('iframe');

        if (this.provider === 'vimeo') {
            this.player = new VimeoPlayer(this.$iframe[0]);
            this.playerIsReady = true;
        }

        if (this.provider === 'youtube') {
            this.modifyYoutubeEmbed();

            if (window.app.state.youtubePlayerAPIReady) {
                this.onYoutubePlayerAPIReady();
            } else {
                window.app.vent.on('youtube-ready', this.onYoutubePlayerAPIReady);
            }
        }
    },


    onYoutubePlayerAPIReady: function () {
        var self = this;

        this.player = new YT.Player(this.$iframe[0], {
            events: {
                'onReady': function (event) {
                    self.youtubePlayerReady = true;
                    self.playerIsReady = true;
                }.bind(this)
            }
        });
    }
});
