var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

require('./FooterClockItem.less');

module.exports = Base.extend({
    template: require('./FooterClockItem.jinja'),

    el: '.FooterClockItem',

    events: {},

    initialize: function (options) {
        _.bindAll(this, 'moveTime', 'moveMin', 'moveHour');

        Base.prototype.initialize.call(this, options);

        this.clock = options.clock;
        this.timeZoneOffset = this.clock.offset; //get timezone offset
        var now = new Date(); // creating new date according to local timezone
        var nowUTC = new Date( now.getTime() + (now.getTimezoneOffset() * 60000)); // convert now to UTC
        var nowUTCInMillis = nowUTC.getTime(); // convert UTC date to millis
        var nowWithTimeZoneInMillis = nowUTCInMillis + this.timeZoneOffset  * 60000; // add to UTC date in millis timezone offset in millis (from DB)

        this.date = new Date(nowWithTimeZoneInMillis);  // create new date according to UTC + timeZoneOffset from DB

        this.hour = this.date.getHours();
        this.min = this.date.getMinutes();
        this.sec = this.date.getSeconds();

        this.$hourElt = this.$el.find('.FooterClockItem-spire--hour');
        this.$minElt = this.$el.find('.FooterClockItem-spire--min');

        this.moveTime();
    },

    moveTime: function () {
        this.moveMin();
        this.moveHour();
    },

    moveMin: function () {
        var turnMin = this.min * 6;
        this.$minElt.css('transform', 'rotate(' + turnMin + 'deg)');
        // after first min leftovers
        setTimeout(function () {
            turnMin += 6;
            this.$minElt.css('transform', 'rotate(' + turnMin + 'deg)');
            // for each min after first
            var eachMin = setInterval(function () {
                turnMin += 6;
                this.$minElt.css('transform', 'rotate(' + turnMin + 'deg)');
            }.bind(this), 60000);
        }.bind(this), (60 - this.sec) * 1000);
    },

    moveHour: function () {
        if (this.hour > 11) {
            this.hour -= 12;
        }
        var turnHour = this.hour * 30;
        this.$hourElt.css('transform', 'rotate(' + turnHour + 'deg)');
        // after first hour leftovers
        setTimeout(function () {
            turnHour += 30;
            this.$hourElt.css('transform', 'rotate(' + turnHour + 'deg)');
            // for each hour after first
            var eachHour = setInterval(function () {
                turnHour += 30;
                this.$hourElt.css('transform', 'rotate(' + turnHour + 'deg)');
            }.bind(this), 3600000);
        }.bind(this), (60 - this.min) * 60000);
    }
});
