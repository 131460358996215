var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/FooterClockItem/FooterClockItem.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n<div class=\"FooterClockItem";
if(runtime.contextOrFrameLookup(context, frame, "navModifier")) {
output += " FooterClockItem--";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "navModifier"), env.opts.autoescape);
;
}
output += "\">\n    <div class=\"FooterClockItem-clock\">\n        <div class=\"FooterClockItem-spire FooterClockItem-spire--hour\"></div>\n        <div class=\"FooterClockItem-spire FooterClockItem-spire--min\"></div>\n    </div>\n\n    <div class=\"FooterClockItem-clockOffice\">\n        ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "officeList");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("office", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n            <div class=\"FooterClockItem-clockOfficeItem";
if((runtime.memberLookup((t_4),"address") || runtime.memberLookup((t_4),"link")) && !runtime.contextOrFrameLookup(context, frame, "ignoreTooltip")) {
output += " FooterClockItem-clockOfficeItem--withTooltip";
;
}
output += "\">\n                <div class=\"FooterClockItem-clockOfficeItemName\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "</div>\n\n                ";
if((runtime.memberLookup((t_4),"address") || runtime.memberLookup((t_4),"link")) && !runtime.contextOrFrameLookup(context, frame, "ignoreTooltip")) {
output += "\n                    <div class=\"FooterClockItem-clockOfficeItem-tooltip\">\n                        <div class=\"FooterClockItem-clockOfficeItem-tooltipText\">\n                            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_4),"address")), env.opts.autoescape);
output += "\n                        </div>\n                        ";
if(runtime.memberLookup((t_4),"link")) {
output += "\n                            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\" target=\"_blank\" class=\"FooterClockItem-clockOfficeItem-tooltipLink\">\n                                Map\n                            </a>\n                        ";
;
}
output += "\n                    </div>\n                ";
;
}
output += "\n            </div>\n        ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n\n</div>\n";
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/FooterClockItem/FooterClockItem.jinja"] , dependencies)