var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/components/RecentPlacements/RecentPlacementsListItem.jinja"] = require( "front/components/RecentPlacements/RecentPlacementsListItem.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/RecentPlacements/RecentPlacementsListItemFront.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/components/RecentPlacements/RecentPlacementsListItem.jinja", false, "assets/app/front/components/RecentPlacements/RecentPlacementsListItemFront.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "RecentPlacementsListItem")) {
var t_4 = t_1.RecentPlacementsListItem;
} else {
cb(new Error("cannot import 'RecentPlacementsListItem'")); return;
}
context.setVariable("RecentPlacementsListItem", t_4);
output += "\n\n";
output += runtime.suppressValue((lineno = 2, colno = 27, runtime.callWrap(t_4, "RecentPlacementsListItem", context, [runtime.makeKeywordArgs({"data": runtime.contextOrFrameLookup(context, frame, "data")})])), env.opts.autoescape);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/RecentPlacements/RecentPlacementsListItemFront.jinja"] , dependencies)