var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./OurStory.less');


module.exports = Base.extend({
    template: require('./OurStory.jinja'),

    el: '.OurStory',

    initialize: function (options) {
        Base.prototype.initialize.call(this, options);

        _.bindAll(this, 'onResize');

        $(window).on('resize', this.onResize);

        setTimeout(this.onResize, 50);
    },

    onResize: function () {

        if (!window.app.settings.isMobile) {
            this.$container = $('.OurStory-container');
            this.$containerYears = $('.OurStory-containerYears');

            this.$ourStoryContent = $('.OurStory-content');
            this.$ourStoryContentMTop = this.$ourStoryContent.css('margin-top').slice(0, -2) / 2;

            // this.$titleFix = $('.OurStory-title.isFix');
            this.$title = $('.OurStory-title.isNormal');
            this.titleHeight = this.$title.innerHeight();

            this.$fixDigit = $('.OurStory-yearFix');
            this.heightDigit = this.$fixDigit.innerHeight();

            // this.topTitle = Number(this.$titleFix.css('top').slice(0, -2));

            this.posLastSection = $('.OurStory-section:last-child').offset().top;

            this.heightContent = $('.OurStory-content').innerHeight();
            this.heightLastSection = $('.OurStory-section:last-child').innerHeight();
            this.paddingTopYear = Number($('.OurStory-year')
                .css('padding-top')
                .slice(0, -2));

            // this.$fixDigit.css('top', this.titleHeight + this.topTitle);
            this.$fixDigit.css('top', $(window).innerHeight() / 2 - (this.$fixDigit.innerHeight() / 2) - this.$ourStoryContentMTop);
            this.$title.css('margin-bottom', -this.titleHeight);
            this.$container.css('height', this.posLastSection - this.titleHeight);
            this.$containerYears.css('height', this.heightContent - this.heightLastSection + this.heightDigit + this.paddingTopYear);
        }
    },

    destroy: function () {
        return Base.prototype.destroy.call(this);
    },

    deactivate: function (params) {

        $(window).off('resize', this.onResize);

        return Base.prototype.deactivate.call(this, params);
    }
});
