var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/TeamMembers/TeamMembersList.jinja"] = require( "front/components/TeamMembers/TeamMembersList.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/TeamMembers/TeamMembers.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/TeamMembers/TeamMembers.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
env.getTemplate("front/components/TeamMembers/TeamMembersList.jinja", false, "assets/app/front/components/TeamMembers/TeamMembers.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "TeamMembersList")) {
var t_7 = t_4.TeamMembersList;
} else {
cb(new Error("cannot import 'TeamMembersList'")); return;
}
context.setVariable("TeamMembersList", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["data", "isFullBlock", "team"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("isFullBlock", Object.prototype.hasOwnProperty.call(kwargs, "isFullBlock") ? kwargs["isFullBlock"] : false);frame.set("team", Object.prototype.hasOwnProperty.call(kwargs, "team") ? kwargs["team"] : "");var t_9 = "";t_9 += "\n    <div class=\"TeamMembers Block ";
if(!runtime.contextOrFrameLookup(context, frame, "isFullBlock")) {
t_9 += "leftCirclePoint isShortBlock";
;
}
t_9 += " ";
if(runtime.contextOrFrameLookup(context, frame, "isFullBlock") && runtime.contextOrFrameLookup(context, frame, "team") == "true") {
t_9 += "TeamMembers--no-filters";
;
}
t_9 += "\"\n         data-id=\"teamMembers\">\n        <div class=\"TeamMembers-title\">\n            ";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"page")),"title"), env.opts.autoescape);
t_9 += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "isFullBlock") && runtime.contextOrFrameLookup(context, frame, "team") != "true") {
t_9 += "\n                <button class=\"TeamMembers-filtersMobileButton\">filter</button>\n\n                <div class=\"TeamMembers-searchWrapper TeamMembers-search--mobile\">\n                    <div class=\"TeamMembers-search\">\n                        <label for=\"search-input-mobile\" class=\"TeamMembers-searchIcon\">\n                            <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 49.06 49.062\">\n                                <path d=\"M8100,400a20,20,0,1,1-20,20A20,20,0,0,1,8100,400Zm0.08,4a16.013,16.013,0,1,1-15.77,16.011A15.892,15.892,0,0,1,8100.08,404Zm14.85,33.754,2.83-2.829,11.31,11.314-2.83,2.829Z\"\n                                      fill-rule=\"evenodd\" transform=\"translate(-8080 -400)\"/>\n                            </svg>\n                        </label>\n                        <input id=\"search-input-mobile\" type=\"text\" class=\"TeamMembers-searchInput TeamMembers-searchInput--mobile\" placeholder=\"search by name\"/>\n                    </div>\n                </div>\n            ";
;
}
t_9 += "\n        </div>\n        <div class=\"TeamMembers-content\">\n            ";
if(runtime.contextOrFrameLookup(context, frame, "isFullBlock") && runtime.contextOrFrameLookup(context, frame, "team") != "true") {
t_9 += "\n                <div class=\"TeamMembers-filterListWrapper\">\n                    <div class=\"TeamMembers-filterListTitle\">filters:</div>\n                    <div class=\"TeamMembers-filterList\"></div>\n                </div>\n            ";
;
}
else {
t_9 += "\n                <div class=\"TeamMembers-filterPlaceholder\">\n                </div>\n            ";
;
}
t_9 += "\n            <div class=\"TeamMembers-membersList\">\n                ";
t_9 += runtime.suppressValue((lineno = 35, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TeamMembersList"), "TeamMembersList", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"membersList")])), env.opts.autoescape);
t_9 += "\n            </div>\n            ";
if(!runtime.contextOrFrameLookup(context, frame, "isFullBlock")) {
t_9 += "\n                <a href=\"";
t_9 += runtime.suppressValue((lineno = 38, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:team"])), env.opts.autoescape);
t_9 += "#teamMembers/\" class=\"TeamMembers-link u-Route\">see full ";
t_9 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "team"), env.opts.autoescape);
t_9 += "\n                    team</a>\n            ";
;
}
t_9 += "\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("TeamMembers");
context.setVariable("TeamMembers", macro_t_8);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/TeamMembers/TeamMembers.jinja"] , dependencies)