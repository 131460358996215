var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');
var FooterClockItem = require('front/components/FooterClockItem/FooterClockItem');
var FooterClockItemTemplate = require('front/components/FooterClockItem/FooterClockItem.jinja');

require('./Footer.less');

module.exports = Base.extend({
    template: require('./Footer.jinja'),

    el: '.Footer',

    events: {
        'click #optOutGuide': 'optOutGuideClick'
    },

    initialize: function () {
        _.bindAll(this, 'setMarginBottomContent',
            'getHeightFooter',
            'remapOfficesListByTimezone',
            'renderFooterClockItems',
            'optOutGuideClick',
            'renderOptOutLink');

        this.footer = window.app.data.footer;
        this.offsets = [];

        this.remapOfficesListByTimezone();

        this.renderFooterClockItems();

        this.renderOptOutLink();

        for (var i = 0; i < this.offsets.length; i++) {
            new FooterClockItem({el: '.Footer-topClocksItem--' + i, clock: this.offsets[i]});
        }

        this.$('p a').attr('target', '_blank');
    },

    renderOptOutLink: function () {
        if (!!window.airgap && !window.airgap.getRegimes().has('Unknown') && window.transcend) {
            this.$('#optOutGuide').text('your privacy choices');
            this.$('#optOutGuide').attr('href', '#');
        }
    },

    optOutGuideClick: function (event) {
        if (!!window.airgap && !window.airgap.getRegimes().has('Unknown') && window.transcend) {
            event.preventDefault();
            event.stopPropagation();
            window.transcend.showConsentManager({ viewState: 'CompleteOptions' });
        }
    },

    renderFooterClockItems: function () {
        var $footerClocks = this.$('.Footer-topClocks');

        if (this.offsets.length > 6) {
            var l = this.offsets.length;
            var countItems = [4, 5, 6]; // variables count items in row, sorted by ASC
            var tempArr = [];

            for (var i = 0; i < countItems.length; i++) tempArr.push(l % countItems[i]);

            var countItemsIndex = 2;
            var minCountItemsExeeds = Math.min.apply(null, tempArr);

            if (minCountItemsExeeds == 0) {
                countItemsIndex = tempArr.lastIndexOf(minCountItemsExeeds);
            } else {
                countItemsIndex = tempArr.indexOf(Math.max.apply(null, tempArr));
            }
            $footerClocks.addClass('Footer-topClocks--countItems-' + countItems[countItemsIndex]);
        }

        for (var i = 0; i < this.offsets.length; i++) {
            var divStart = '<div class="Footer-topClocksItem Footer-topClocksItem--' + i + '">';
            var divEnd = '</div>';
            var clockItem = FooterClockItemTemplate.render({
                officeList: this.offsets[i].officeList
            });

            var html = divStart + clockItem + divEnd;

            $footerClocks.append(html);
        }
    },

    remapOfficesListByTimezone: function () {
        if (this.footer.locations) {
            var objectWithTimeZones = _.groupBy(this.footer.locations, 'offset');

            var offsets = [];
            for (var property in objectWithTimeZones) {
                if (objectWithTimeZones.hasOwnProperty(property)) {
                    // do stuff
                    var singleTimeZoneList = objectWithTimeZones[property];
                    var offset = {
                        offset: singleTimeZoneList[0].offset,
                        officeList: []
                    };

                    for (var i in singleTimeZoneList) {
                        var singleTimeZone = singleTimeZoneList[i];
                        offset.officeList.push({
                            'title': singleTimeZone.title,
                            'link': singleTimeZone.link,
                            'address': singleTimeZone.address
                        });
                    }

                    offsets.push(offset);
                }
            }

            this.offsets = _.sortBy(offsets, function (obj) {
                return obj.offset;
            });
        }
    },

    setMarginBottomContent: function () {
        if (window.app.settings.isMobile) return;

        $('.Content-outter')
            .css('margin-bottom', $('.Footer')
                .innerHeight() + 'px');
    },

    getHeightFooter: function () {
        return $('.Footer')
            .innerHeight();
    }
});
