var Backbone = require('backbone');
var _ = require('underscore');

var Urls = require('django_js_front/reverse.js');

var Section = require('front/pages/Section/Section');

var GrowingTalant = require('front/components/GrowingTalant/GrowingTalant');
var OurServices = require('front/components/OurServices/OurServices');
var Nutshell = require('front/components/Nutshell/Nutshell');
var TeamMembers = require('front/components/TeamMembers/TeamMembers');
var IndexNews = require('front/components/IndexNews/IndexNews');
// var FooterNav = require('front/components/FooterNav/FooterNav');

require('./DevelopmentSection.less');


module.exports = Section.extend({
    template: require('./DevelopmentSection.jinja'),

    el: '.DevSection',

    // apiUrl: Urls['front:api:development'](),

    initialize: function (options) {
        this.options = options || {};

        Section.prototype.initialize.call(this, options);

        if (this.options.server) {
            this.activateLogic();
        } else {
            this.apiUrl = Urls['front:api:development']();
        }
    },

    activateLogic: function () {
        window.app.views.footer.setMarginBottomContent(this.$el);
        this.startAnimation(); //if need anim after loading
    },

    render: function () {
        this.setClass('DevPage');
        this.setTitle('true • development');

        // set data to window.app.data if navigate was without page reload
        window.app.data.logos = this.data.searchNutshell && this.data.searchNutshell.logos;
        window.app.data.team = 'development';

        Section.prototype.render.call(this);

        window.app.views.footerNav.setMarginItems();

        this.activateLogic();
    },

    viewsRegistration: function () {
        this.registerView('growingTalant', new GrowingTalant());
        this.registerView('ourServices', new OurServices());
        this.registerView('searchNutshell', new Nutshell());
        this.registerView('searchTeamMembers', new TeamMembers({team: 'development'}));
        this.registerView('searchNews', new IndexNews());
        // this.registerView('footerNav', new FooterNav());
    }
});