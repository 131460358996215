var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var TeamMembersFiltersTemplate = require('front/components/TeamMembers/TeamMembersFilters.jinja');
var TeamMembersFiltersMobileTemplate = require('front/components/TeamMembers/TeamMembersFiltersMobile.jinja');
var TeamMembersListTemplate = require('front/components/TeamMembers/TeamMembersListRuntime.jinja');

require('./TeamMembers.less');


module.exports = Base.extend({
    template: require('./TeamMembers.jinja'),

    el: '.TeamMembers',

    events: {
        'click #teamMembersTeamFilter .TeamMembers-filterValue': 'toggleTeamFilter',
        'click #teamMembersRegionsFilter .TeamMembers-filterValue': 'toggleRegionsFilter',
        'click #teamMembersPositionFilter .TeamMembers-filterValue': 'togglePositionFilter',
        'click #teamMembersTeamFilter .TeamMembers-filterOption': 'setTeamFilterValue',
        'click #teamMembersRegionsFilter .TeamMembers-filterOption': 'setRegionsFilterValue',
        'click #teamMembersPositionFilter .TeamMembers-filterOption': 'setPositionFilterValue',
        'click .TeamMembers-link': 'setTeamToShow',
        'click .TeamMembers-clearFilters': 'clearFilters',
        'click .TeamMembers-filtersMobileButton': 'openMobileFilters',
        'keyup .TeamMembers-searchInput:not(.TeamMembers-searchInput--mobile)': 'changeInputFiltred',
        'keyup .TeamMembers-searchInput--mobile': 'changeInputFiltredMobile'
    },

    initialize: function (options) {
        _.bindAll(this, 'renderFilters', 'toggleTeamFilter', 'toggleRegionsFilter',
            'togglePositionFilter', 'setTeamFilterValue', 'closeFilters', 'setRegionsFilterValue',
            'setPositionFilterValue', 'clickOutsideFilters', 'remapLists', 'setDefaultListsValues',
            'filterMembersList', 'renderMembersList', 'setTeamToShow', 'clearFilters', 'openMobileFilters',
            'closeMobileFilters', 'saveMobileFiltersData', 'applyFilters', 'searchByName');

        $(document)
            .on('click', this.clickOutsideFilters);

        this.team = options && options.team;
        this.isMobileFilterOpen = false;

        this.memberList = _.clone(window.app.data.membersList);

        // order is important. first remap filters (add 'all' values) - then render them
        this.remapLists();
        this.setDefaultListsValues();

        if (!this.$el.hasClass('TeamMembers--no-filters')) {
            this.filterMembersList();
            this.renderFilters();
        }
    },

    renderFilters: function (isActiveByDefault) {
        var Template = window.app.settings.isMobile ? TeamMembersFiltersMobileTemplate : TeamMembersFiltersTemplate;
        var $container = window.app.settings.isMobile ? $('.TeamMembers-filterMobileWrapper') : $('.TeamMembers-filterList');
        if (!this.isMobileFilterOpen) {
            this.values = _.clone(window.app.data.teamFilterValues);
        }

        var showClearFilters = true;

        if (this.values && this.values.team && this.values.region
            && this.values.position
            && this.values.team.id === 'everyone'
            && this.values.region.id === 'allRegions'
            && this.values.position.id === 'allSpecialities'
            || (window.app.data.teamFilterValues.isCustomTeam
                && this.values
                && this.values.team
                && this.values.region
                && this.values.region.id === 'allRegions'
                && this.values.position.id === 'allSpecialities')) {
            showClearFilters = false;
        }

        var filtersHtml = Template.render({
            data: {
                values: this.values,
                regionsList: window.app.data.regionsList,
                teamsList: window.app.data.teamsList,
                positionsList: window.app.data.positionsList,
                isActiveByDefault: isActiveByDefault,
                showClearFilters: showClearFilters
            }
        });

        if (window.app.data.teamFilterValues.team && window.app.data.teamFilterValues.team.isPositionFilterNeeded) {
            $('.TeamMembers-list').addClass('isPositionFilter');
        }

        $container
            .html(filtersHtml);

        this.$inputSearch = this.$('.TeamMembers-searchInput:not(.TeamMembers-searchInput--mobile)');
        this.$inputSearchMobile = this.$('.TeamMembers-searchInput--mobile');
        this.$clearBtn = this.$('.TeamMembers-clearFilters');

        if (!this.$inputSearchMobile || !this.$inputSearchMobile[0]) {
            return;
        }

        this.$inputSearchMobile[0].value = '';
    },

    remapLists: function () {
        var allRegions = {
            id: 'allRegions',
            value: 'all regions'
        };

        var allPositions = {
            id: 'allSpecialities',
            value: 'all specialities'
        };

        var everyone = {
            id: 'everyone',
            value: 'everyone'
        };

        var leadership = {
            id: 'leadership',
            value: 'leadership',
            valueFull: 'leadership team'
        };

        if ((window.app.data.regionsList && window.app.data.regionsList[0]
            && window.app.data.regionsList[0].id !== allRegions.id)
            || (window.app.data.regionsList.length === 0)) {
            window.app.data.regionsList.unshift(allRegions);
        }
        if ((window.app.data.positionsList && window.app.data.positionsList[0]
            && window.app.data.positionsList[0].id !== allPositions.id)
            || (window.app.data.positionsList.length === 0)) {
            window.app.data.positionsList.unshift(allPositions);
        }

        if (window.app.data.teamsList && window.app.data.teamsList[0]
            && window.app.data.teamsList[0].id !== everyone.id) {
            window.app.data.teamsList.unshift(everyone);
            window.app.data.teamsList.push(leadership);
        }

        if (window.app.data.teamsList && window.app.data.teamsList.length === 0) {
            window.app.data.teamsList.unshift(everyone);
            window.app.data.teamsList.push(leadership);
        }
    },

    setDefaultListsValues: function () {
        if (!window.app.data.teamFilterValues.isCustomTeam) {
            window.app.data.teamFilterValues.team = window.app.data.teamsList[0];
        }

        if (!window.app.data.teamFilterValues.isCustomPosition) {
            window.app.data.teamFilterValues.position = window.app.data.positionsList[0];
        }

        window.app.data.teamFilterValues.region = window.app.data.regionsList[0];
    },

    filterMembersList: function (isNeedRerender) {
        var url = Urls['front:api:team-list']();
        var data = {};
        var self = this;
        if (window.app.data.teamFilterValues.team) {
            window.app.data.teamsList.forEach(function (teamItem) {
                if (teamItem.value === window.app.data.teamFilterValues.team) {
                    window.app.data.teamFilterValues.team = teamItem;
                }
            });
        }

        if (window.app.data.teamFilterValues.position) {
            window.app.data.positionsList.forEach(function (teamItem) {
                if (teamItem.id === window.app.data.teamFilterValues.position) {
                    window.app.data.teamFilterValues.position = teamItem;
                }
            });
        }

        if (window.app.data.teamFilterValues.isCustomPosition) {
            window.app.data.teamFilterValues.isCustomPosition = false;
            isNeedRerender = true;
        }

        if (isNeedRerender) {

            if (window.app.data.teamFilterValues.team.id !== window.app.data.teamsList[window.app.data.teamsList.length - 1].id &&
                window.app.data.teamFilterValues.team.id !== window.app.data.teamsList[0].id) {
                data.team = window.app.data.teamFilterValues.team.id;
            }

            if (window.app.data.teamFilterValues.position && window.app.data.teamFilterValues.position.id !== window.app.data.positionsList[0].id) {
                data.positions__position = window.app.data.teamFilterValues.position.id;
            }

            if (window.app.data.teamFilterValues.region.id !== window.app.data.regionsList[0].id) {
                data.regions__region = window.app.data.teamFilterValues.region.id;
            }

            if (window.app.data.teamFilterValues.team.id === window.app.data.teamsList[window.app.data.teamsList.length - 1].id || this.teamItem) {
                data.leadership = true;
            }


            var newList = $.getJSON(url, data, function (e) {
                window.app.data.membersList = e;
                self.renderMembersList(window.app.data.membersList);
            });
        }
    },

    renderMembersList: function (members) {
        var membersListHtml = TeamMembersListTemplate.render({
            data: {
                membersList: members,
                values: window.app.data.teamFilterValues
            }
        });

        if (window.app.data.membersList.length !== 0) {
            $('.TeamMembers-membersList')
                .html(membersListHtml);
        } else {
            $('.TeamMembers-membersList')
                .html('<div class="TeamMembers-zeroResults">0 results</div></div>');
        }

        this.memberList = window.app.data.membersList;
    },

    toggleTeamFilter: function () {
        if (!this.$('#teamMembersTeamFilter .TeamMembers-filterOptions')
            .hasClass('active')) {
            this.$('#teamMembersTeamFilter .TeamMembers-filterOptions')
                .addClass('active');
            this.$('#teamMembersRegionsFilter .TeamMembers-filterOptions')
                .removeClass('active');
            this.$('#teamMembersPositionFilter .TeamMembers-filterOptions')
                .removeClass('active');
        } else {
            this.closeFilters();
        }
    },

    toggleRegionsFilter: function () {
        if (!this.$('#teamMembersRegionsFilter .TeamMembers-filterOptions')
            .hasClass('active')) {
            this.$('#teamMembersRegionsFilter .TeamMembers-filterOptions')
                .addClass('active');
            this.$('#teamMembersTeamFilter .TeamMembers-filterOptions')
                .removeClass('active');
            this.$('#teamMembersPositionFilter .TeamMembers-filterOptions')
                .removeClass('active');
        } else {
            this.closeFilters();
        }
    },


    togglePositionFilter: function () {
        if (!this.$('#teamMembersPositionFilter .TeamMembers-filterOptions')
            .hasClass('active')) {
            this.$('#teamMembersTeamFilter .TeamMembers-filterOptions')
                .removeClass('active');
            this.$('#teamMembersRegionsFilter .TeamMembers-filterOptions')
                .removeClass('active');
            this.$('#teamMembersPositionFilter .TeamMembers-filterOptions')
                .addClass('active');
        } else {
            this.closeFilters();
        }
    },

    closeFilters: function () {
        this.$('#teamMembersTeamFilter .TeamMembers-filterOptions')
            .removeClass('active');
        this.$('#teamMembersRegionsFilter .TeamMembers-filterOptions')
            .removeClass('active');
        this.$('#teamMembersPositionFilter .TeamMembers-filterOptions')
            .removeClass('active');
    },

    clickOutsideFilters: function (e) {
        var $container = this.$('.TeamMembers-filters');
        if (!$container.is(e.target) && $container.has(e.target).length === 0) {
            this.closeFilters();
        }
    },


    setTeamFilterValue: function (e) {
        var option = $(e.target)
            .data('option-value');
        window.app.data.teamFilterValues.team = window.app.data.teamsList.find(function (obj) {
            return obj.id === option;
        });

        if (!window.app.data.teamFilterValues.team.isPositionFilterNeeded) {
            window.app.data.teamFilterValues.position = window.app.data.positionsList[0];
        }

        this.filterMembersList(true);
        this.renderFilters();
        this.closeFilters();
    },


    setRegionsFilterValue: function (e) {
        var option = $(e.target)
            .data('option-value');
        window.app.data.teamFilterValues.region = window.app.data.regionsList.find(function (obj) {
            return obj.id === option;
        });
        this.filterMembersList(true);
        this.renderFilters();
        this.closeFilters();
    },


    setPositionFilterValue: function (e) {
        var option = $(e.target)
            .data('option-value');
        window.app.data.teamFilterValues.position = window.app.data.positionsList.find(function (obj) {
            return obj.id === option;
        });
        this.filterMembersList(true);
        this.renderFilters();
        this.closeFilters();
    },

    setTeamToShow: function (e) {
        if (this.team) {
            $('.SideNav-link.isActive')
                .removeClass('isActive');
            window.app.data.teamFilterValues.team = this.team;
            window.app.data.teamFilterValues.isCustomTeam = true;
        }
    },

    clearFilters: function () {
        // window.app.data.teamFilterValues.isCustomTeam = false;
        window.app.data.teamFilterValues.isCustomPosition = false;
        this.setDefaultListsValues();
        this.renderFilters();
        this.filterMembersList(true);
    },

    openMobileFilters: function () {
        this.isMobileFilterOpen = true;
        $('.TeamMembers-filtersMobile')
            .addClass('active');

        var self = this;
        $('input[type=radio]')
            .on('click', function (e) {
                self.saveMobileFiltersData(e);
            });
        $('.TeamMembers-close')
            .on('click', function (e) {
                self.closeMobileFilters();
            });

        $('.TeamMembers-applyFilterMobile')
            .on('click', function (e) {
                self.applyFilters();
            });
    },


    closeMobileFilters: function () {
        this.isMobileFilterOpen = false;
        this.values = _.clone(window.app.data.teamFilterValues);
        this.renderFilters();

    },

    saveMobileFiltersData: function (e) {
        this.savedFiltersScroll = $('.TeamMembers-filtersMobile')
            .scrollTop();
        var $option = $(e.target);
        if ($option.attr('name') === 'team') {
            var option = $option
                .data('option-value');
            this.values.team = window.app.data.teamsList.find(function (obj) {
                return obj.id === option;
            });
            // this.values.region = window.app.data.regionsList[0];

            if (!this.values.team.isPositionFilterNeeded) {
                this.values.position = window.app.data.positionsList[0];
            }
        }

        if ($option.attr('name') === 'region') {
            var option = $(e.target)
                .data('option-value');
            this.values.region = window.app.data.regionsList.find(function (obj) {
                return obj.id === option;
            });
            // this.values.team = window.app.data.teamsList[0];
        }

        if ($option.attr('name') === 'position') {
            var option = $(e.target)
                .data('option-value');
            this.values.position = window.app.data.positionsList.find(function (obj) {
                return obj.id === option;
            });
        }
        this.renderFilters(true);

        $('.TeamMembers-filtersMobile')
            .scrollTop(this.savedFiltersScroll);
        var self = this;

        $('input[type=radio]')
            .on('click', function (e) {
                self.saveMobileFiltersData(e);
            });

        $('.TeamMembers-close')
            .on('click', function (e) {
                self.closeMobileFilters();
            });

        $('.TeamMembers-applyFilterMobile')
            .on('click', function (e) {
                self.applyFilters();
            });
    },


    applyFilters: function () {
        this.isMobileFilterOpen = false;
        window.app.data.teamFilterValues = _.clone(this.values);
        // $('.TeamMembers-filtersMobile')
        //     .scrollTop(0);
        // $('.TeamMembers-filtersMobile')
        //     .removeClass('active');

        this.filterMembersList(true);
        this.renderFilters();
        this.closeMobileFilters();

    },

    changeInputFiltred: function () {
        var value = this.$inputSearch[0].value.trim().toLowerCase();

        this.searchByName(value);
    },

    changeInputFiltredMobile: function () {
        var value = this.$inputSearchMobile[0].value.trim().toLowerCase();

        this.searchByName(value);
    },

    searchByName: function (value) {
        var resFiltred = [];

        if (!!value) {
            this.$clearBtn.css('display', '');

            this.memberList.forEach(function (item) {
                var name = item.name.toLowerCase();

                if (name.indexOf(value) !== -1) {
                    resFiltred.push(item);
                } else {
                    var fullName = item.name.split(' ');

                    fullName.forEach(function (n) {
                        var partName = n.toLowerCase();

                        if (partName.indexOf(value) !== -1) {
                            resFiltred.push(item);

                            return;
                        }
                    });
                }
            });
        } else {
            resFiltred = _.clone(this.memberList);

            if (this.values && this.values.team && this.values.region
                && this.values.position
                && this.values.team.id === 'everyone'
                && this.values.region.id === 'allRegions'
                && this.values.position.id === 'allSpecialities'
                || (window.app.data.teamFilterValues.isCustomTeam
                    && this.values
                    && this.values.team
                    && this.values.region
                    && this.values.region.id === 'allRegions'
                    && this.values.position.id === 'allSpecialities')) {
                this.$clearBtn.css('display', 'none');
        }
        }

        this.renderMembersList(resFiltred);
    }
});
