var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

require('./WhoWeAre.less');


module.exports = Base.extend({
    template: require('./WhoWeAre.jinja'),

    el: '.WhoWeAre',

    initialize: function () {
    }
});