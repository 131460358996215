var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var SearchUniversalBlock = require('front/components/SearchUniversalBlock/SearchUniversalBlock');

require('./SearchExpertise.less');


module.exports = Base.extend({
    template: require('./SearchExpertise.jinja'),

    el: '.SearchIndustries',

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'SearchUniversalBlock': {selector: '.SearchUniversalBlock', viewConstructor: SearchUniversalBlock}
    },

    initialize: function (options) {
        // new SearchUniversalBlock();

        Base.prototype.initialize.call(this, options);
    }
});