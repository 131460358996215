var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/SearchUniversalBlock/SearchUniversalBlock.jinja"] = require( "front/components/SearchUniversalBlock/SearchUniversalBlock.jinja" );




var shim = require("/var/www/django/trueplatform.com/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/SearchExpertise/SearchExpertise.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/SearchExpertise/SearchExpertise.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/SearchUniversalBlock/SearchUniversalBlock.jinja", false, "assets/app/front/components/SearchExpertise/SearchExpertise.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "SearchUniversalBlock")) {
var t_7 = t_4.SearchUniversalBlock;
} else {
cb(new Error("cannot import 'SearchUniversalBlock'")); return;
}
context.setVariable("SearchUniversalBlock", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["page"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("page", Object.prototype.hasOwnProperty.call(kwargs, "page") ? kwargs["page"] : "");var t_9 = "";t_9 += "\n    <div class=\"SearchIndustries Block leftCirclePoint\">\n        <div class=\"SearchIndustries-title\">";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"overviewTitle"), env.opts.autoescape);
t_9 += "</div>\n        <div class=\"SearchIndustries-description\">";
t_9 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"overviewText")), env.opts.autoescape);
t_9 += "</div>\n        <div class=\"SearchIndustries-content\">\n            ";
frame = frame.push();
var t_12 = runtime.contextOrFrameLookup(context, frame, "categories");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("block", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_9 += "\n                ";
t_9 += runtime.suppressValue((lineno = 10, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SearchUniversalBlock"), "SearchUniversalBlock", context, [t_13,runtime.contextOrFrameLookup(context, frame, "page")])), env.opts.autoescape);
t_9 += "\n            ";
;
}
}
frame = frame.pop();
t_9 += "\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("SearchExpertise");
context.setVariable("SearchExpertise", macro_t_8);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/SearchExpertise/SearchExpertise.jinja"] , dependencies)