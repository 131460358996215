var Backbone = require('backbone');
var Base = require('front/components/Base/Base');
var _ = require('underscore');

var logosRowTemplate = require('./LogosSingleRow.jinja');
var LogosSingleRow = require('./LogosSingleRow');

require('./LogosRows.less');


module.exports = Base.extend({
    template: require('./LogosRows.jinja'),

    el: '.LogosRows',

    initialize: function () {
        _.bindAll(this, 'getLogos', 'renderLogos', 'startAnimation', 'prepareAnimation', 'makeShiftStep', 'startShiftAnimation', 'onResize', 'onLogosLoaded');

        app.vent.on('nutshellLogosReady', this.startAnimation);
        app.vent.on('nutshellLogosLoaded', this.onLogosLoaded);
        this.getLogos();
        this.renderLogos();
    },

    replicateArray: function (array, n) {
        var arr = [];

        for (var k = 0; k < n; k++) {
            for (var i = 0; i < array.length; i++) {
                arr = arr.concat(array[i]);
            }
        }

        return arr;
    },

    onResize: function () {

    },

    getLogos: function () {
        this.logos = window.app.data.logos;

        this.logosRows = [];

        this.rowsCount = window.app.settings.isMobile ? 4 : 3;

        var index = 0;
        var logosLength = this.logos.length;
        while (this.logos.length < 15) {
            if (index > (logosLength - 1)) {
                index = 0;
            }
            this.logos.push(this.logos[index]);

            index++;
        }

        this.logosRows = _.chunk(this.logos, this.logos.length / this.rowsCount);

        for (var i = 0; i < this.logosRows.length; i++) {
            this.logosRows[i] = this.replicateArray(_.uniq(this.logosRows[i]), 3);
        }
    },

    renderLogos: function () {
        this.countOfLogos = 0;
        this.countOfReadyFunctionsCalled = 0;
        if (this.logosRows) {
            for (var i = 0; i < this.logosRows.length; i++) {
                var logosRow = this.logosRows[i];
                var template = logosRowTemplate.render({
                    navModifier: i + 1,
                    logos: logosRow,
                    id: i
                });
                $('.LogosRows')
                    .append(template);

                this.countOfLogos += logosRow.length;

                new LogosSingleRow({el: '.LogosRows-row--' + (i + 1)});
            }
        }
    },

    onLogosLoaded: function () {
        this.countOfReadyFunctionsCalled++;
        if (this.countOfReadyFunctionsCalled === this.countOfLogos) {
            this.prepareAnimation();
        }
    },

    prepareAnimation: function () {
        this.rows = [];
        var self = this;
        setTimeout(function () {
            for (var i = 0; i < self.rowsCount; i++) {
                var row = {};
                row.$wrapper = self.$('.LogosRows-rowInner#logos-row-' + i);
                row.cycleLength = Math.ceil(row.$wrapper
                    .innerWidth() / 3);

                row.id = i;

                row.shiftData = {
                    val: 0,
                    delta: 0,
                    dir: i % 2 === 0 ? 1 : -1
                };

                self.rows.push(row);
            }
            app.vent.trigger('nutshellLogosReady');
        });
    },

    startAnimation: function () {
        this.startShiftAnimation(1);
    },

    startShiftAnimation: function (speed) {
        for (var i = 0; i < this.rowsCount; i++) {
            var row = this.rows[i];

            window.cancelAnimationFrame(this.frameID);

            row.shiftData.stop = false;
            row.shiftData.speed = speed;
        }

        this.makeShiftStep();
    },


    stopShiftAnimation: function () {
        for (var i = 0; i < this.rowsCount; i++) {
            var row = this.rows[i];

            row.shiftData.stop = true;

            window.cancelAnimationFrame(row.shiftData.frameID);
            row.shiftData.delta = 0;
        }

    },


    makeShiftStep: function () {
        for (var i = 0; i < this.rowsCount; i++) {
            var row = this.rows[i];
            row.shiftData.val = (row.shiftData.val + row.shiftData.dir * row.cycleLength) % row.cycleLength;
            row.shiftData.val = row.shiftData.dir === 1 ? row.shiftData.val - row.cycleLength : row.shiftData.val;

            row.shiftData.val += row.shiftData.dir * row.shiftData.speed;//3 - speed

            row.$wrapper.css('transform', 'translate3d(' + row.shiftData.val + 'px, 0, 0)');

        }

        this.frameID = window.requestAnimationFrame(this.makeShiftStep);

    }

});